import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import Path from "../../../../Constants/Routes";
import SmatPhone from "./../../../../Assets/Images/smartphone.png";
import { validate } from "./validate";

function Index(props) {
  const [state, setstate] = useState();
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="steps-form-details step-5">
      <h3 className="heading">
        <span>Photo</span>
      </h3>
      <p className="subtitle">
        The final step is to add photos of your vehicle. This only takes a few
        minutes and enables us to get a guaranteed offer for your vehicle.
      </p>

      <div className="msg-sent">
        <h3 className="head">Check Your Phone!</h3>
        <p className="title">
          We have just sent a message to <span>3012698015</span> with a link to
          take photos. Open the message to continue.
        </p>
      </div>
      <div className="smartphone">
        <img src={SmatPhone} className="smartphone-image" alt="smartphone" />
        {/* <div className="form-wrapper">
          <Field
            children="Didn't receive a message?"
            id="message"
            name="message"
            value="message"
            className=""
            component={Checkbox}
          />
          <Field
            children="Unable to use a smartphone?"
            id="message2"
            name="message2"
            value="message2"
            className=""
            component={Checkbox}
          />
          <Field
            children="Have photos already?"
            id="message3"
            name="message3"
            value="message3"
            className=""
            component={Checkbox}
          />
        </div> */}
        <div className="car-detail-photo-info">
          <div className="accordion-wrapper">
            <div className="accordion-items">
              <div
                className={`accordion-title ${isOpen ? "open" : ""}`}
                onClick={() => setOpen(!isOpen)}
              >
                <h2 className="head">Didn't receive message?</h2>
              </div>
              <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  
                  <div className="cm-dt-btn">
                    <Link className="item" to={Path}>
                      <span>Resend link to my phone</span>
                    </Link>
                  </div>
               
                </div>
              </div>
            </div>
            <div className="accordion-items">
              <div
                className={`accordion-title ${isOpen ? "open" : ""}`}
                onClick={() => setOpen(!isOpen)}
              >
                <h2 className="head">Unable to use a smartphone?</h2>
              </div>
              <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content">
                  
                  <p>
                  You may upload photos from your desktop/laptop if you have not got access to a mobile device. Please click
                    <Link className="item" to={Path}>
                      <span>hear</span> to upload photos.
                    </Link>
                  </p> 
                </div>
              </div>
            </div>
            <div className="accordion-items">
              <div
                className={`accordion-title ${isOpen ? "open" : ""}`}
                onClick={() => setOpen(!isOpen)}
              >
                <h2 className="head">Click here to upload photos if you have already taken.</h2>
              </div>
              <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content">
                
                  <p>
                  You may upload photos from your desktop/laptop if you have not got access to a mobile device. Please click
                  
                    <Link className="item" to={Path}>
                      <span>hear</span> to upload photos.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="imageUpload__component">
          <div className="imageUpload__upload_multiple_text">
            <button className="btn primary form-width ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="butt"
                stroke-linejoin="bevel"
              >
                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3" />
              </svg>
              <span>Upload multiple images</span>
            </button>
          </div>

          <div className="imageUpload__sectionHeader">
            <h4>Exterior</h4>
            <div>
              <p>Ensure the whole of the car is visible in each photo.</p>
              <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a>
              </div>
            </div>
            <ul className="imageUpload__image_section">
              <li>
                <span className="imageUpload__image_input_label">Front</span>
                <span className="imageUpload__image_input_label">
                  Driver side
                </span>
                <div
                  className="photoInput-module_component__18191 uploads photoInput-module_medium__34JbJ"
                  data-name="exterior_front_driver"
                >
                  <label>
                    <input
                      type="file"
                      accept="image/*"
                      name="exterior_front_driver"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="butt"
                      stroke-linejoin="bevel"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </label>
                </div>
              </li>
              <li>
                <span className="imageUpload__image_input_label">Back</span>
                <span className="imageUpload__image_input_label">
                  Driver side
                </span>
                <div
                  className="photoInput-module_component__18191 uploads photoInput-module_medium__34JbJ"
                  data-name="exterior_rear_driver"
                >
                  <label>
                    <input
                      type="file"
                      accept="image/*"
                      name="exterior_rear_driver"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="butt"
                      stroke-linejoin="bevel"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </label>
                </div>
              </li>
              <li>
                <span className="imageUpload__image_input_label">Front</span>
                <span className="imageUpload__image_input_label">
                  Passenger side
                </span>
                <div
                  className="photoInput-module_component__18191 uploads photoInput-module_medium__34JbJ"
                  data-name="exterior_front_passenger"
                >
                  <label>
                    <input
                      type="file"
                      accept="image/*"
                      name="exterior_front_passenger"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="butt"
                      stroke-linejoin="bevel"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </label>
                </div>
              </li>
              <li>
                <span className="imageUpload__image_input_label">Back</span>
                <span className="imageUpload__image_input_label">
                  Passenger side
                </span>
                <div
                  className="photoInput-module_component__18191 uploads photoInput-module_medium__34JbJ"
                  data-name="exterior_rear_passenger"
                >
                  <label>
                    <input
                      type="file"
                      accept="image/*"
                      name="exterior_rear_passenger"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="butt"
                      stroke-linejoin="bevel"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

Index = reduxForm({
  // a unique name for the form
  form: "step5",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(Index);

export default connect(mapStateToProps, mapDispatchToProps)(Index);
