export const validate = (values) => {
  const errors = {};
  if (!values.szMileage) {
    errors.szMileage = "Required";
  }
  if (!values.szFullName) {
    errors.szFullName = "Required";
  }
  
  if (!values.szEmail) {
    errors.szEmail = "Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.szEmail)
  ) {
    errors.szEmail = "Invalid email address";
  }

  if (!values.szPhoneNumber) {
    errors.szPhoneNumber = "Required";
  } else if (
    !/((\+44\s?\(0\)\s?\d{2,4})|(\+44\s?(01|02|03|07|08)\d{2,3})|(\+44\s?(1|2|3|7|8)\d{2,3})|(\(\+44\)\s?\d{3,4})|(\(\d{5}\))|((01|02|03|07|08)\d{2,3})|(\d{5}))(\s|-|.)(((\d{3,4})(\s|-)(\d{3,4}))|((\d{6,7})))/.test(
      values.szPhoneNumber
    )
  ) {
    errors.szPhoneNumber = "Invalid phone number";
  }

  if (!values.szPostCode) {
    errors.szPostCode = "Required";
  } else if (
    !/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i.test(
      values.szPostCode
    )
  ) {
    errors.szPostCode = "Invalid postcode";
  }

   

  return errors;
};

function isValidPostcode(p) {
  var postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
  return postcodeRegEx.test(p);
}

// const normalizePostCode = (value) => {
//   // if (value < 0) {
//   //   return 0;
//   // } else {
//   //   return value;
//   // }
// };

// const normalizePhone = (value, previousValue) => {
//   if (!value) {
//     return value;
//   }
//   // const onlyNums = value.replace(/[^\d]/g, "");
//   // if (!previousValue || value.length > previousValue.length) {
//   //   // typing forward
//   //   if (onlyNums.length === 3) {
//   //     return onlyNums + "-";
//   //   }
//   //   if (onlyNums.length === 6) {
//   //     return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3) + "-";
//   //   }
//   // }
//   // if (onlyNums.length <= 3) {
//   //   return onlyNums;
//   // }
//   // if (onlyNums.length <= 6) {
//   //   return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3);
//   // }
//   // return (
//   //   onlyNums.slice(0, 3) +
//   //   "-" +
//   //   onlyNums.slice(3, 6) +
//   //   "-" +
//   //   onlyNums.slice(6, 10)
//   // );
// };

// const required = (value) => (value ? undefined : "Required");

// export { normalizePhone, normalizePostCode, required };

const required = (value) => (value ? undefined : "Required");
const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength15 = maxLength(15);
const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
const minValue18 = minValue(18);
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
const tooOld = (value) =>
  value && value > 65 ? "You might be too old for this" : undefined;
const aol = (value) =>
  value && /.+@aol\.com/.test(value)
    ? "Really? You still use AOL for your email?"
    : undefined;

// const phone = (value) =>
//   value &&
//   /^7(?:[1-4]\d\d|5(?:0[0-8]|[13-9]\d|2[0-35-9])|624|7(?:0[1-9]|[1-7]\d|8[02-9]|9[0-689])|8(?:[014-9]\d|[23][0-8])|9(?:[04-9]\d|1[02-9]|2[0-35-9]|3[0-689]))\d{6}$/.test(
//     value
//   )
//     ? "Invalid phone number"
//     : undefined;

export {
  required,
  maxLength,
  maxLength15,
  number,
  minValue,
  minValue18,
  email,
  tooOld,
  aol,
};
