import React, { useEffect, useState } from 'react';
import S3 from 'react-aws-s3';
import 'react-dropzone-uploader/dist/styles.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getVehicleDetail } from '../../../../Services/getVehicleDetails';
import { updateVehicleDetails } from '../../../../Services/updateVehicleDetails';
import { CameraFeed } from '../../../Common/Camera/camera';
import CommonBanner from '../../../Common/CommonBanner/CommonBanner';
import DropZoneArea from './DropZoneArea';
import './UploadImagesMobile.scss';

const UploadImagesMobile = (props) => {
    const [uploadPhotos, setUploadPhotos] = useState(true);
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [mediaKey, setMediaKey] = useState(null);
    const [isMultiple, setMultiPle] = useState(false);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);

    let { szToken, szVehicleKey } = useParams();

    // specify upload params and url for your files
    const config = {
        bucketName: 'cargofront',
        region: 'ap-south-1',
        accessKeyId: 'AKIAUOI2YXDKSM7D3B6Z',
        secretAccessKey: 'MF0+bCDgeR9yektULsOsd/i1t68GjbYa9eEoua6R'
    };
    const ReactS3Client = new S3(config);

    const openCamera = (keyName, multi = false, fromTimeT, toTimeT) => {
        setFromTime(fromTimeT);
        setToTime(toTimeT);
        setUploadPhotos(false);
        setMultiPle(multi);
        setMediaKey(keyName);
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    };

    const getVehicleDetails = () => {
        // const search = location.search;
        const idVehicleStored = szVehicleKey;
        console.log('id-------------', idVehicleStored);
        // const idVehicle = new URLSearchParams(search).get("id");
        getVehicleDetail(idVehicleStored)
            .then((res) => {
                let data = res?.data?.data;
                setVehicleDetails(data || {});
            })
            .catch((err) => {
                // this.props.hideLoader();
            });
    };

    const uploadImage = async (file) => {
        // const formData = new FormData();
        // formData.append('file', file);
        // const files   = new File([file], "abc",{type: "image/png"});
        const req = {};
        const idVehicleStored = szVehicleKey;
        req.idVehicleDetails = idVehicleStored;
        setUploadPhotos(true);
        console.log('file=======MMMMMMM====?>>>>', file);
        // Connect to a seaweedfs instance

        ReactS3Client.uploadFile(file)
            .then((data) => {
                console.log('+++++S# DATA KEY', data);
                let arFile = [];
                if (isMultiple) {
                    arFile.push(data.key);
                    req[mediaKey] = JSON.stringify(arFile);
                } else {
                    req[mediaKey] = data.key;
                }
                updateFiles(req);
            })
            .catch((err) => console.error('error===========', err));
    };

    const updateFiles = (req) => {
        updateVehicleDetails(req)
            .then((res) => {
                if (res.status === 200) {
                    getVehicleDetails();
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        localStorage.setItem('token', szToken);
        getVehicleDetails();
    }, []);
    return (
        <>
            {!uploadPhotos ? (
                <>
                    <CameraFeed fromTime={fromTime} toTime={toTime} sendFile={uploadImage} />
                </>
            ) : (
                <div className="upload-images-mobile-wrapper">
                    <div className="step-5 mx-auto">
                        <h3 className="heading">
                            <span>Photo</span>
                        </h3>
                        <CommonBanner>
                            <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
                                <h1 className="car-name">Vehicle Details</h1>
                            </div>
                        </CommonBanner>

                        <div className="imageUpload__component">
                            <div className="imageUpload__sectionHeader">
                                <h4>Exterior</h4>
                                <div>
                                    <p>Ensure the whole of the car is visible in each photo.</p>
                                    <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                                        {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
                                    </div>
                                </div>
                                <ul className="imageUpload__image_section">
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szExteriorFrontDriverSide']}
                                            name={'szExteriorFrontDriverSide'}
                                            title1={'Front'}
                                            title2={'Driver Side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szExteriorFrontDriverSide', false, 0, 15);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szExteriorBackDriverSide']}
                                            name={'szExteriorBackDriverSide'}
                                            title1={'Back'}
                                            title2={'Driver side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szExteriorBackDriverSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szExteriorFrontPassengerSide']}
                                            name={'szExteriorFrontPassengerSide'}
                                            title1={'Front'}
                                            title2={'Passenger side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szExteriorFrontPassengerSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szExteriorBackPassengerSide']}
                                            name={'szExteriorBackPassengerSide'}
                                            title1={'Back'}
                                            title2={'Passenger side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szExteriorBackPassengerSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <h4>Interior</h4>
                                <div>
                                    <p>Ensure the whole of the car is visible in each photo.</p>
                                    <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                                        {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
                                    </div>
                                </div>
                                <ul className="imageUpload__image_section">
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szInteriorFrontSeats']}
                                            name={'szInteriorFrontSeats'}
                                            title1={'Front Seat'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szInteriorFrontSeats', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szInteriorRearSeats']}
                                            name={'szInteriorRearSeats'}
                                            title1={'Rear Seat'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szInteriorRearSeats', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szInteriorDashboard']}
                                            name={'szInteriorDashboard'}
                                            title1={'Dashboard'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szInteriorDashboard', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szInteriorBoot']}
                                            name={'szInteriorBoot'}
                                            title1={'Boot Interior'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szInteriorBoot', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <h4>Wheels</h4>
                                <div>
                                    <p>Ensure the whole of the car is visible in each photo.</p>
                                    <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                                        {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
                                    </div>
                                </div>
                                <ul className="imageUpload__image_section">
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szWheelsFrontDriverSide']}
                                            name={'szWheelsFrontDriverSide'}
                                            title1={'Front'}
                                            title2={'Driver Side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szWheelsFrontDriverSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szWheelsBackDriverSide']}
                                            name={'szWheelsBackDriverSide'}
                                            title1={'Back'}
                                            title2={'Driver side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szWheelsBackDriverSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szWheelsFrontPassengerSide']}
                                            name={'szWheelsFrontPassengerSide'}
                                            title1={'Front'}
                                            title2={'Passenger side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szWheelsFrontPassengerSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szWheelsBackPassengerSide']}
                                            name={'szWheelsBackPassengerSide'}
                                            title1={'Back'}
                                            title2={'Passenger side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szWheelsBackPassengerSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <h4>Tyre Treads</h4>
                                <div>
                                    <p>Ensure the whole of the car is visible in each photo.</p>
                                    <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                                        {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
                                    </div>
                                </div>
                                <ul className="imageUpload__image_section">
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szTyreFrontDriverSide']}
                                            name={'szTyreFrontDriverSide'}
                                            title1={'Front'}
                                            title2={'Driver Side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szTyreFrontDriverSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szTyreBackDriverSide']}
                                            name={'szTyreBackDriverSide'}
                                            title1={'Back'}
                                            title2={'Driver side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szTyreBackDriverSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szTyreFrontPassengerSide']}
                                            name={'szTyreFrontPassengerSide'}
                                            title1={'Front'}
                                            title2={'Passenger side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szTyreFrontPassengerSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <DropZoneArea
                                            urls={vehicleDetails['szTyreBackPassengerSide']}
                                            name={'szTyreBackPassengerSide'}
                                            title1={'Back'}
                                            title2={'Passenger side'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szTyreBackPassengerSide', false, 15, 30);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <h4>Condition Photos</h4>
                                <div>
                                    <p>Ensure the whole of the car is visible in each photo.</p>
                                    <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                                        {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
                                    </div>
                                </div>
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={
                                                vehicleDetails['szConditionScratchesAndScuffs'] && JSON.parse(vehicleDetails['szConditionScratchesAndScuffs'])
                                            }
                                            multiple={true}
                                            name={'szConditionScratchesAndScuffs'}
                                            title1={'Scratches and Scuffs'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionScratchesAndScuffs', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={vehicleDetails['szConditionDents'] && JSON.parse(vehicleDetails['szConditionDents'])}
                                            multiple={true}
                                            name={'szConditionDents'}
                                            title1={'Dents'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionDents', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={vehicleDetails['szConditionPaintworkProblems'] && JSON.parse(vehicleDetails['szConditionPaintworkProblems'])}
                                            multiple={true}
                                            name={'szConditionPaintworkProblems'}
                                            title1={'Paintwork Problems'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionPaintworkProblems', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={vehicleDetails['szConditionMissingTrims'] && JSON.parse(vehicleDetails['szConditionMissingTrims'])}
                                            multiple={true}
                                            name={'szConditionMissingTrims'}
                                            title1={'Missing Trims'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionMissingTrims', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={vehicleDetails['szConditionWarningLights'] && JSON.parse(vehicleDetails['szConditionWarningLights'])}
                                            multiple={true}
                                            name={'szConditionWarningLights'}
                                            title1={'Warning Lights'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionWarningLights', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={
                                                vehicleDetails['szConditionWindscreenProblems'] && JSON.parse(vehicleDetails['szConditionWindscreenProblems'])
                                            }
                                            multiple={true}
                                            name={'szConditionWindscreenProblems'}
                                            title1={'Windscreen Problems'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionWindscreenProblems', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={vehicleDetails['szConditionAlloyScuffs'] && JSON.parse(vehicleDetails['szConditionAlloyScuffs'])}
                                            multiple={true}
                                            name={'szConditionAlloyScuffs'}
                                            title1={'Alloy Scuffs'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionAlloyScuffs', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>

                            <div className="imageUpload__sectionHeader">
                                <ul className="imageUpload__image_section">
                                    <li className="multi-images">
                                        <DropZoneArea
                                            urls={vehicleDetails['szConditionTypeProblems'] && JSON.parse(vehicleDetails['szConditionTypeProblems'])}
                                            multiple={true}
                                            name={'szConditionTypeProblems'}
                                            title1={'Type Problems'}
                                            isMobile={true}
                                            openCamera={() => {
                                                openCamera('szConditionTypeProblems', true);
                                            }}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UploadImagesMobile;
