import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import Path from "../../../Constants/Routes";
import { setToken, store } from "../../../Helpers/Helpers";
import { sellerLogin } from "../../../Services/sellerLogin";
import { sellerLoginOTP } from "../../../Services/sellerLoginOTP";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import LoaderWrapper from "../../Common/Loader/Loader";
import "./../../Auth/Auth.scss";
import { validate } from "./validate";

function Login(props) {
  const history = useHistory();
  const [emailFlag, setEmailFlag] = useState(false);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState("");

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  const [message, setMessage] = useState({
    szEmail: "",
    szOtp: "",
    error: false,
    alreadyExist: "",
  });

  const handleChangeEmail = (value) => {
    setEmail(value);
    if (!value) {
      setMessage({ error: true });
    } else {
      setMessage({ error: false });
    }
  };

  useEffect(() => {
    alredyEmail();
  }, []);

  const alredyEmail = () => {
    let szEmail = localStorage.getItem("szEmail");
    if (szEmail) {
      props.dispatch(change("login", "szEmail", szEmail));
      setLoader(true);
      setTimeout(() => {
        handleEmail();
        localStorage.removeItem("szEmail");
      }, 1000);
    }
  };

  const handleEmail = () => {
    setLoader(true);
    if (props.szEmail !== "") {
      let payload = {
        szEmail: props.szEmail,
      };
      sellerLogin(payload)
        .then((res) => {
          if (res?.status === false) {
            if (props.szEmail) {
              setMessage({
                szEmail: "Please enter a valid email",
                error: true,
                alreadyExist: "",
              });
            } else {
              setMessage({
                szEmail: "",
                error: false,
                alreadyExist: res.message,
              });
            }
          } else {
            setLoader(false);
            setEmailFlag(true);
          }
        })
        .catch((err) => {
          if (!props.szEmail) {
            setMessage({
              szEmail: "Please enter a valid email",
              error: true,
              alreadyExist: "",
            });
            setLoader(false);
          } else {
            setMessage({
              szEmail: "",
              error: false,
              alreadyExist: err.message,
            });
            setLoader(false);
          }
        });
    } else {
      // setMessage(err?.data?.szEmail[0]);
    }
  };
  //
  const handleOTP = () => {
    setLoader(true);
    if (props.szEmail !== "" && props.szOtp !== "") {
      let payload = {
        szEmail: props.szEmail,
        szOtp: props.szOtp,
      };
      sellerLoginOTP(payload)
        .then((res) => {
          if (res.success === false) {
            if (props.szOtp) {
              setMessage({
                szOtp: "Please enter a valid OTP",
                error: true,
                alreadyExist: "",
              });
            } else {
              setMessage({
                szOtp: "",
                error: false,
                alreadyExist: res.message,
              });
            }
          } else {
            const emailExist = localStorage.getItem("already-exist");
            setToken(res?.data?.data?.token || "");
            localStorage.setItem("szFullName", res?.data?.data?.szFullName);
            localStorage.setItem("userType", res?.data?.data?.type);
            localStorage.setItem("type", res?.data?.data?.type);
            store.dispatch({
              type: "ADD_TODO",
              text: res?.data?.data?.szFullName,
            });
            setLoader(false);
            if (!emailExist) {
              history.push(Path.home);
            } else {
              history.push(Path.home);
            }
            props.dispatch(change("login", "szEmail", ""));
            props.dispatch(change("login", "szOtp", ""));
          }
        })
        .catch((err) => {
          if (!props.szOtp) {
            setMessage({
              szOtp: "Please enter a valid OTP",
              error: true,
              alreadyExist: "",
            });
          } else {
            setMessage({ szOtp: "", error: false, alreadyExist: err.message });
          }
          setLoader(false);
        });
    } else {
      console.log("enter field");
    }
  };

  return (
    <>
      {loader && <LoaderWrapper />}
      <div className="loginauth-main">
        <div className="auth-container">
          <div className="auth-form-wrapper logincls">
            {!emailFlag ? (
              <>
                <h1 class="heading">Sign in to your account</h1>
                <div className="form-field">
                  <label>Enter email</label>
                  <Field
                    name="szEmail"
                    placeholder="e.g. johndeo@gmail.com"
                    type="email"
                    component={Input}
                    className={`width-100 ${message.error && "input-error"}`}
                    onChange={handleChangeEmail}
                  />
                  {message.error ? (
                    <span className="error">{message.szEmail}</span>
                  ) : (
                    <span className="error">{message.alreadyExist}</span>
                  )}
                </div>

                <Button className="primary width-100" onClick={handleEmail}>
                  Login
                </Button>
              </>
            ) : (
              <>
                <p className="text mass-for-otp">
                  We have sent OTP to your registered email
                  <span className="font-weight-bold">
                    &nbsp;{props.szEmail}
                  </span>
                  &nbsp;Please check the email (make sure to check in
                  clutter/junk folder if you can't find email in the inbox) and
                  enter OTP received below.
                </p>
                <div className="form-field">
                  <label>Enter OTP</label>
                  <Field
                    name="szOtp"
                    placeholder="e.g. 123456"
                    type="password"
                    component={Input}
                    className={`width-100 ${message.error && "input-error"}`}
                  />
                  {message.error ? (
                    <span className="error">{message.szOtp}</span>
                  ) : (
                    <span className="error">{message.alreadyExist}</span>
                  )}
                </div>
                <Button className="primary width-100" onClick={handleOTP}>
                  Submit OTP
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const selector = formValueSelector("login");
Login = reduxForm({
  // a unique name for the form
  form: "login",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(Login);

export default connect((state) => {
  // can select values individually
  const szEmail = selector(state, "szEmail");
  const szOtp = selector(state, "szOtp");
  return {
    szEmail,
    szOtp,
  };
})(Login);
