import React, { useEffect, useState } from "react";
import { getVehicleDetail } from "../../../Services/getVehicleDetails";
import { updateVehicleDetails } from "../../../Services/updateVehicleDetails";
import PrevNext from "../PrevNext";
import "./../Shared.scss";
import StepFive from "./step-five-form";
import StepFour from "./step-four-form";
import StepOne from "./step-one-form";
import StepSix from "./step-six-form";
import StepThree from "./step-three-form";
import StepTwo from "./step-two-form";
import { formateStepData } from "../../../Helpers/Helpers";


const StepForms = (props) => {
  const [currentFormState, setFormState] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const setCurrentFormState = (inputData) => {
    setFormState((prev) => ({ ...prev, ...inputData }));
  };

   

  // @Vehicle details
  const setVehicleDetail = (id) => {
    getVehicleDetail(id)
      .then((res) => {
        let data = res?.data?.data;
        setFormData(data);
      })
      .catch((err) => {
        // this.props.hideLoader();
      });
  };
  useEffect(() => {
    // let id = localStorage.getItem("idVehicleDetails");
    // setVehicleDetail(id);
  }, []);

  return (
    <div className="steps-form-wrapper custom-scrollbar">
        <StepOne initialValues={formateStepData('objVehicleDetails')}/>
    </div>
  );
};

export default StepForms;
