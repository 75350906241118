import React, { useEffect } from "react";

import "./Pricing.scss";

const Pricing = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="pricing-wrapper">
      <div className="container-pricing">
        <div className="pricing-title">
            <h3>Pricing page</h3>
            <p>
                Fees are based on successful purchases only.<br></br> 
                You will be invoiced after the vehicle handover is complete. 
            </p>
        </div>
        <div className="pricing-main">
        <div className="columns">
            <ul className="price">
                <li className="pricehead">Prices from</li>
                <li>£0</li>
                <li className="grey">£5,000</li>
                <li>£10,000</li>
                <li className="grey">£15,000</li>
                <li>£20,000</li>
                <li className="grey">£30,000</li>
                <li>£40,000</li>
                <li className="grey">£50,000</li>
                <li>£100,000</li>
            </ul>
            </div>

            <div className="columns">
            <ul className="price">
                <li className="pricehead">Prices to</li>
                <li>£4,999</li>
                <li className="grey">£9,999</li>
                <li>£14,999</li>
                <li className="grey">£19,999</li>
                <li>£29,999</li>
                <li className="grey">£39,999</li>
                <li>£49,999</li>
                <li className="grey">£99,999</li>
                <li></li>
            </ul>
            </div>

            <div className="columns">
            <ul className="price">
                <li className="pricehead">Fee (+VAT)</li>
                <li>£200</li>
                <li className="grey">£225</li>
                <li>£245</li>
                <li className="grey">£295</li>
                <li>£345</li>
                <li className="grey">£395</li>
                <li>£445</li>
                <li className="grey">£695</li>
                <li>£945</li>
            </ul>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
