import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import Path from "../../../../Constants/Routes";
import { validate } from "./validate";

function Index(props) {
  const [state, setstate] = useState();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="steps-form-details">
      <h3
        className="heading"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <span>
          Thank you for submitting your car to the
          auction platform. We will review details and contact you to finalise
          auction details.
        </span>
      </h3>
      <h1 className="text-center">
        <Link to={Path.home}>Goto home page</Link>
      </h1>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

Index = reduxForm({
  // a unique name for the form
  form: "step6",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(Index);

export default connect(mapStateToProps, mapDispatchToProps)(Index);
