import { createStore } from 'redux'
import moment from "moment";

// get token
export function getToken() {
  if (localStorage.getItem("token")) return true;
  else return false;
}

// set token
export function setToken(token) {
  return localStorage.setItem("token", token);
}

// clear token
export function clearToken() {
  return localStorage.clear();
}

// background scroll
export function setScrollBackground() {
  document.getElementById("my-app").style.overflow = "hidden";
  console.log("set");
}

export function releaseScrollBackground() {
  document.getElementById("my-app").style.overflow = "";
  console.log("resetset");
}

// export function checkRoute(path, route) {
//   newArray = route.filter(function (item) {
//     return item === path;
//   });
// }

export function formateStepData(key) {
  let formData = JSON.parse(localStorage.getItem(key));
 if(formData){
  formData.dtCambeltChange = moment(formData.dtCambeltChange).format("YYYY-MM-DD")
  return formData;
 }
}

export function isNumberKey(evt) {
  console.log(evt)
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
}

export function scrollToTop() {
  window.scroll({
    top: 0,
    left: 100,
    behavior: "smooth",
  });
}
export function scrollToTopelement() {
document.getElementById('stepforms').scroll({
  top: 0,
  left: 100,
  behavior: "smooth",
});
}

export function updateUsername(username = null) {
  username
    ? localStorage.setItem("szFullName", username)
    : localStorage.getItem("szFullName");
  let fullName = localStorage.getItem("szFullName");
  return fullName;
}

export function todos(state = [], action) {
  switch (action.type) {
    case 'ADD_TODO':
      return action.text
    default:
      return state
  }
}
export function isNumberKeyNew(evt){
  console.log(evt)
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
  
  }

export const store = createStore(todos, localStorage.getItem("szFullName"))

export function insertSpace(string) {
    let output = [
      string.slice(0, string.length - 3),
      " ",
      string.slice(string.length - 3),
    ].join("");
    return output;
  }

