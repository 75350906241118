import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import Path from "../../../Constants/Routes";
import { addVehicleDetail } from "../../../Services/addVehicle";
import { searchCar } from "../../../Services/searchCar";
import Button from "../../Common/Button";
import CommonBanner from "../../Common/CommonBanner/CommonBanner";
import Input from "../../Common/Input";
import LoaderWrapper from "../../Common/Loader/Loader";
import Popup from "../../Common/Popup/Popup";
import SelectBox from "../../Common/SelectBox/SelectBox";
import InfoIcon from "./../../../Assets/Icons/info-icon.png";
import CarLogo from "./../../../Assets/Images/car-logo.png";
import { thinkingOptions } from "./../../../Constants/optionConstant";
import Tooltip from "react-simple-tooltip";
import {isNumberKey, isNumberKeyNew} from "../../../Helpers/Helpers";


import "./UpdateMileage.scss";
import { required } from "./validate";
import { min } from "moment";
import { s3URL } from "../../../Constants/constants";

function UpdateMilage(props) {
  let { slug } = useParams();
  const history = useHistory();
  const [carDetail, setCarDetail] = useState([]);
  const [vehicleNotExist, setVehicleNotExist] = useState(false);
  const [vehicleAlreadyExist, setVehicleAlreadyExist] = useState(false);

  const [formErrors, setFormErrors] = useState({
    mileage: "",
    ThinkingOfSelling: "",
  });
  const [state, setState] = useState({
    szThinkingOfSelling: "",
    szMileage: "",
  });
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getCarDetail(slug);
    props.dispatch(change("updateMilage", "szThinkingOfSelling", ""));
  }, []);

  // vehicle No invalid
  const handleInvalidVehicle = () => {
    setVehicleNotExist(false);
    history.push(Path.home);
  };

  // @car detail
  const getCarDetail = (slug) => {
    searchCar(slug)
      .then((res) => {
        if (res?.success !== false) {
          setCarDetail(res?.data?.data);
          let mileage = "";
          if(localStorage.getItem("mileage")){
             mileage = localStorage.getItem("mileage") || "";
          }else{
             mileage = res?.data?.data?.mileageDetails || "";
          }
          props.dispatch(change("updateMilage", "szMileage", mileage));
          setLoader(false);
          if (!res?.data?.data || res?.data?.data === null) {
            setLoader(false);
            setVehicleNotExist(true);
          }
        } else {
          setVehicleNotExist(true);
        }
      })
      .catch((err) => {
        // this.props.hideLoader();
        if (err?.success === false) {
          setLoader(false);
          setVehicleNotExist(true);
        } else {
          console.log(err);
        }
      });
  };

   // handlePopupAlreadyVehicle
   const handlePopupAlreadyVehicle = () => {
    setVehicleAlreadyExist(false);
    history.push(Path.home);
  };

  // console.log("values", props);
  // @seller signup
  const handleSubmit = () => {
    if (props.szMileage == "") {
      setFormErrors({
        mileage: "Please enter mileage",
      });
    } else if (props.szThinkingOfSelling == undefined) {
      setFormErrors({
        ThinkingOfSelling: "Please select selling time period.",
      });
    } else if (
      props.szMileage != "" &&
      props.szThinkingOfSelling != "" &&
      props.szThinkingOfSelling != undefined
    ) {
      let szVehicleDetails = JSON.stringify(carDetail);
      let payload = {
        szMileage: props.szMileage,
        szThinkingOfSelling: props.szThinkingOfSelling,
        szVehicleNumber: localStorage.getItem("carNo"),

      };
      payload["szVehicleDetails"] = szVehicleDetails;
      addVehicleDetail(payload)
        .then((res) => {
          if (res?.success === false) {
            console.log("error");
            // console.log(state);
          } else {
            console.log("text", res?.data?.data?.idVehicleDetails);
            // localStorage.setItem("token", res?.data?.data?.token || "");
            localStorage.setItem(
              "objVehicleDetails",
              JSON.stringify(res?.data?.data?.objVehicleDetails) || ""
            );
            localStorage.setItem(
              "idVehicleDetails",
              res?.data?.data?.idVehicleDetails
            );
            props.dispatch(change("updateMilage", "szThinkingOfSelling", ""));
            props.dispatch(change("updateMilage", "szMileage", ""));
            history.push(Path.carDetail);
          }
        })
        .catch((err) => {
          console.log("=========>>>>??????????",err);
          setVehicleAlreadyExist(true);
        });
    } else {
      console.log("hello");
    }
  };

  const vali = true;

  const mileage = carDetail?.mileageDetails || "";
  const carColor = carDetail?.colour;
  const manufacturer = carDetail?.make;
  // const DvlaModel = carDetail?.wheelplan;
  const fule = carDetail?.fuelType;
  const modalYear = carDetail?.yearOfManufacture;
  const bodyStyle = carDetail?.wheelplan;
  const engineDescription = carDetail?.registrationNumber;

  return (
    <>
      <div className="add-vehicle">
        {loader && <LoaderWrapper />}
        <CommonBanner>
          <div className="icon">
            <img src={s3URL + carDetail?.make?.toLowerCase()+'-logo.png'} alt="car logo" />
          </div>
          <div className="car-detail">
            <h2 className="car-model">{engineDescription}</h2>
            <div className="cg-row">
            <h1 className="car-name">
              {manufacturer}
              {/* {DvlaModel} */}
            </h1>
            <div className="carmodel">
            <span>{carDetail?.model}</span>
            </div>
            </div>
            <div className="detail">
              <span className="info">{modalYear}</span>
              <span className="info">{carColor}</span>
              <span className="info">{bodyStyle}</span>
              <span className="info">{fule}</span>
              {/* <span className="info">Change car</span> */}
            </div>
          </div>
        </CommonBanner>
        {/* {carDetail?.guideprice ?
        <div className="inner-container">
        
        <div className="valuation-price">
        <h2 className="heading">Valuation Price</h2>
        <div className="valuation-price-table">         
        <div className="columns">
          <ul className="price">
            <li className="price-title">Dealer Forecourt</li>
            <li className="grey">£{carDetail?.guideprice?.getjsondata?.DealerForecourt}</li>
          </ul>
        </div>
        <div className="columns">
          <ul className="price">
            <li className="price-title">Trade Retail</li>
            <li className="grey">£{carDetail?.guideprice?.getjsondata?.TradeRetail}</li>
          </ul>
        </div>
        <div className="columns">
          <ul className="price">
            <li className="price-title">Private Clean</li>
            <li className="grey">£{carDetail?.guideprice?.getjsondata?.PrivateClean}</li>
          </ul>
        </div>
        <div className="columns">
          <ul className="price">
            <li className="price-title">Private Average</li>
            <li className="grey">£{carDetail?.guideprice?.getjsondata?.PrivateAverage}</li>
          </ul>
		</div>
       
        <div className="columns">
          <ul className="price">
            <li className="price-title">Trade Average</li>
            <li className="grey">£{carDetail?.guideprice?.getjsondata?.TradeAverage}</li>
          </ul>
        </div>
      </div>
      </div>
      </div>
      :''} */}
     
        <div className="container-body">
        <div className="update-mileage-wrapper">
         <div className="update-mileage">
          <h2 className="heading">
            <span>What is the mileage of this vehicle?</span>
          </h2>
          <div className="form-wrapper">
            
            <div className="form-field">
              <div className="label">
                <label>Mileage</label>
                <span className="field-info ml-auto">Estimated Mileage</span>
                <Tooltip content="Estimated Mileage">
                <img src={InfoIcon} className="field-info-icon" />
                </Tooltip>
              </div>
              <div className="input-control">
                <Field
                  name="szMileage"
                  type="text"
                  component={Input}
                  placeholder={"Mileage"}
                  className="width-100"
                  value={mileage}
                  // writeOnce={true}
                  validate={[required]}
                  error={formErrors.mileage}
                  onkeypress={(e)=>{ return isNumberKeyNew(e) }}
                />
                {(props.szThinkingOfSelling == undefined) != "" && (
                  <span className="error-custom">{formErrors.mileage}</span>
                )}
              </div>
            </div>
            
            <div className="form-field">
              <div className="label">
                <label>When are you thinking of selling your car?</label>
                <span className="field-info ml-auto"></span>
                <img src={InfoIcon} className="field-info-icon" />
              </div>
              <div className="input-control">
                <Field
                  name="szThinkingOfSelling"
                  type="number"
                  component={SelectBox}
                  className={`width-100 ${
                    formErrors.ThinkingOfSelling != "" && "input-error"
                  }`}
                  options={thinkingOptions}
                  // validate={[required]}
                />
                {props.szThinkingOfSelling == undefined && (
                  <span className="error-custom">
                    {formErrors.ThinkingOfSelling}
                  </span>
                )}
              </div>
            </div>
            <Button className="primary mx-auto" onClick={handleSubmit}>
            ENTER DETAILS
            </Button>
          </div>
        </div>
        </div>
        </div>
      </div>

      {/* invalid vehicle */}
      <Popup
        onClick={handleInvalidVehicle}
        state={vehicleNotExist}
        heading="Invalid Vehicle"
        btnText="Filter"
      >
        <h3>Vehicle invalid, please enter a valid Vehicle</h3>
        <button className="btn primary" onClick={handleInvalidVehicle}>
          Ok
        </button>
      </Popup>
      {/* vehicle already in aution */}
      <Popup
        onClick={handlePopupAlreadyVehicle}
        state={vehicleAlreadyExist}
        heading="Vehicle"
        btnText="Filter"
      >
        <h3>
       This vehicle already registered.
        </h3>
        <button className="btn primary" onClick={handlePopupAlreadyVehicle}>
          ok
        </button>
      </Popup>
    </>
  );
}

const selector = formValueSelector("updateMilage");
UpdateMilage = reduxForm({
  form: "updateMilage",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(UpdateMilage);

export default connect((state) => {
  // can select values individually
  const szMileage = selector(state, "szMileage");
  const szThinkingOfSelling = selector(state, "szThinkingOfSelling");
  return {
    szMileage,
    szThinkingOfSelling,
  };
})(UpdateMilage);
