import React from "react";
import { s3URL } from "../../Constants/constants";

const ImageCard = ({ avtar }) => {
  return (
    <div className="image-card">
      <img className="avtar" src={avtar} alt="" onerror="this.style.display='none'"/>
    </div>
  );
};

export default ImageCard;
