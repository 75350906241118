import React, { Component } from 'react';
import { ReactComponent as IconSend } from "../../../Assets/Icons/Icon-send.svg";
import slideVideo from "../../../Assets/video/saloon-v3-1104x828-30fps.webm";
import "./camera.scss";
// import Camera from 'react-dom-camera';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';



import ReactPlayer from 'react-player';


export class CameraFeed extends Component {
    /**
     * Processes available devices and identifies one by the label
     * @memberof CameraFeed
     * @instance
     */
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            isCamera: true,
            image: null,
            blobImg:null
        };

    }

    processDevices(devices) {
        devices.forEach(device => {
            console.log(device.label);
            this.setDevice(device);
        });
    }

    /**
     * Sets the active device and starts playing the feed
     * @memberof CameraFeed
     * @instance
     */
    async setDevice(device) {
        const { deviceId } = device;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId: deviceId, facingMode: "environment" } });
        this.videoPlayer.srcObject = stream;
        this.videoPlayer.play();
        // stream.getTracks().forEach(function(track) {
        //     track.stop();
        //   });
    }

    /**
     * On mount, grab the users connected devices and process them
     * @memberof CameraFeed
     * @instance
     * @override
     */
    async componentDidMount() {
        // await this.timeListner(this.props?.fromTime, this.props?.toTime);
        // const cameras = await navigator.mediaDevices.enumerateDevices();
        // this.processDevices(cameras);
        document.body.style.backgroundColor = "#000";
        document.body.style.height = "100%";
        document.body.style.margin = "0";
        document.body.style.padding = "0";
        document.body.style.overflow = "hidden";
    }

    /**
     * Handles taking a still image from the video feed on the camera
     * @memberof CameraFeed
     * @instance
     */
    takePhoto = (img,blobImg) => {

        // const context = this.canvas.getContext('2d');
        // let imgById = document.getElementById("myimage");
        // context.drawImage(imgById, 0, 0, 680, 680);
        let imgObj = this.dataURLtoFile(img,"cameimg.png");
        console.log("IMAGE OBJECT++++>>",imgObj);
        this.setState({ isCamera: false, image: img,blobImg:imgObj })
    };
    
    dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    onCancel = () => {
        this.setState({ isCamera: true })
    }

    onOk = () => {
        const { sendFile } = this.props;
        sendFile(this.state.blobImg)
        // this.canvas.toBlob(sendFile);
    }

    getCurTime = () => {
        let vid = document.getElementById("video2");

    }

    timeListner(fromTime, toTime) {
        console.log("+++++++++FROM AND TO+++++++++++", fromTime, "====", toTime)
        let vid = document.getElementById("video2");
        vid.currentTime = fromTime;
        vid.addEventListener("timeupdate", function () {
            if (this.currentTime >= toTime) {
                console.log("+++++++++STOP+++++++++++", this.currentTime,)
                vid.removeEventListener("timeupdate", this.pause())
            }
        });
    }

    setCurTime = () => {


        // vid.currentTime = 20;
        // if(vid.currentTime>40){
        //     alert(vid.currentTime);
        //     vid.stop() 
        // }
        // var pausing_function = function(){
        //     if(this.currentTime >= 5 * 60) {
        //         this.pause();

        //         // remove the event listener after you paused the playback
        //         this.removeEventListener("timeupdate",pausing_function);
        //     }
        // };

        // video.addEventListener("timeupdate", pausing_function);
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = "transparent";
        document.body.style.height = "100%";
        document.body.style.margin = "0";
        document.body.style.padding = "0";
        document.body.style.overflow = "auto";
    }

    render() {

        
        

        return (
            <>
                {
                    this.state.isCamera ? (
                        <Camera
                            idealResolution = {{width: '640', height: '480'}}
                            idealFacingMode = {"environment"}
                            isFullscreen = {true}
                            isImageMirror={false}
                            onTakePhoto={(image) => {
                              this.takePhoto(image);
                            }
                            }
                        />
                       
                    ) : ""
                }

                <div id="vid_container2" className="slidevideo">
                    <video id="video2" autoplay="false" currentTime="40" controls="false" crossorigin="anonymous" width="100%" heigh="height: 100vh">
                        <source src={slideVideo} type="video/mp4" />
                        <source src={slideVideo} type="video/ogg" />
                    </video>
                </div>

                {/* <div id="container">
                <div id="vid_container">
                <video id="video" autoplay playsinline></video>
                <div id="video_overlay"></div>
                </div> 
                <div id="gui_controls">
                <button
                id="switchCameraButton"
                name="switch Camera"
                type="button"
                aria-pressed="false"
                ></button>
                <button id="takePhotoButton" name="take Photo" type="button"></button>
                <button
                id="toggleFullScreenButton"
                name="toggle FullScreen"
                type="button"
                aria-pressed="false"
                ></button>
                </div> 
                </div> */}

                <div className="c-camera-feed">
                    {/* <div id="container" className="camera-fremm1" style={{ display: `${this.state.isCamera ? 'block' : 'none'}` }} className={`${this.state.isCamera ? '' : ''}`}>
                        <div id="vid_container" className="c-camera-feed__viewer">
                            <video id="video" autoplay playsinline ref={ref => (this.videoPlayer = ref)} width="100%" heigh="height: 100vh;" timestamp={this.props.timestamp} />
                            <div id="video_overlay"></div>
                        </div>
                        <div id="gui_controls">
                            <button id="takePhotoButton" className="take-photo" onClick={this.timeListner}></button>
                        </div>
                    </div>
                    <div id="vid_container2" className="slidevideo">
                        <video id="video2" autoplay="false" currentTime="40" controls="false" crossorigin="anonymous" width="100%" heigh="height: 100vh">
                            <source src={slideVideo} type="video/mp4" />
                            <source src={slideVideo} type="video/ogg" />
                        </video>
                    </div> */}

                    <div id="container" style={{ display: `${this.state.isCamera ? 'none' : 'block'}` }} className="c-camera-feed__stage">
                        <div id="vid_container" className="c-camera-feed__viewer">
                            <img id="myimage" src={this.state.image} />
                            {/* <canvas id="video" width="680" height="680" ref={ref => (this.canvas = ref)} /> */}
                        </div>

                        <div className="cancel-ok">
                            <button className="cancelbtn" onClick={this.onCancel}>x</button>
                            <button className="okbtn" onClick={this.onOk}> <IconSend /></button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

