import axios from "axios";
import Api from "./../config/Api";
export function sendPhotoLink(payload) {
  console.log("payload", payload);

  const token = localStorage.getItem("token") || localStorage.getItem("temp_token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": `Basic ${token}`,
      "x-auth-token": token
    }
  }
  return axios.post(`${Api.sendPhotoLink}`, payload, config);
}
