import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
import Dealerlogin from "./Components/Auth/DealerLogin/Dealerlogin";
import DealerSignup from "./Components/Auth/DealerSignup/DealerSignup";
import Login from "./Components/Auth/Login/Login";
import SellerSignup from "./Components/Auth/SellerSignup/SellerSignup";
import UpdateMileage from "./Components/Auth/UpdateMileage/UpdateMileage";
import TestComponent from "./Components/Test/TestComponent";
// paths
import Path from "./Constants/Routes";
import AboutUs from "./Pages/AboutUs/AboutUs";
import CarDetail from "./Pages/CarDetail/CarDetail";
import CarListing from "./Pages/CarListing/CarListing";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CookiePolicy from "./Pages/CookiePolicy/CookiePolicy";
import CustomerReview from "./Pages/CustomerReview/CustomerReview";
import Home from "./Pages/Home/Home";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ReviewDetails from "./Pages/ReviewDetails/ReviewDetails";
import SellerProfile from "./Pages/SellerProfile/SellerProfile";
import SellingProcess from "./Pages/SellingProcess/SellingProcess";
import TermsConditions from "./Pages/TermsConditions/TermsConditions";
import UserSetting from "./Pages/UserSetting/UserSetting";
import VeshicleDetail from "./Pages/VehicleDetail/VeshicleDetail";
import ViewDetail from "./Pages/ViewDetail/ViewDetail";
import Pricing from "./Pages/Pricing/Pricing"
import CustomerMessage from "./Pages/SellerProfile/CustomerMessage/CustomerMessage";
import "./Styles/style.css";
import UploadImagesMobile from "./Components/shared/forms/UploadImages/UploadImagesMobile";
import Layout from "./Layout";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  const location = useLocation();
  return (
    <Switch location={location}>
       {/* <Route path={`${Path.xqzewxghxd}/:szToken/:szVehicleKey`} component={UploadImagesMobile} /> */}
      <Route
        exact
        path={`${Path.ViewVehicleDetails}:slug`}
        component={ViewDetail}
      />

      

      <Route exact path={Path.login} component={Login} />
      <Route exact path={Path.dealerLogin} component={Dealerlogin} />
      <Route exact path={Path.dealerSignUp} component={DealerSignup} />      
      <Route exact path={Path.aboutUs} component={AboutUs} />
      <Route exact path={Path.contactUs} component={ContactUs} />
      <Route exact path={Path.customerReview} component={CustomerReview} />
      <Route exact path={Path.carListing} component={CarListing} />
      <Route exact path={Path.userSetting} component={UserSetting} />
      {/* <Route exact path={Path.viewDetail} component={ViewDetail} /> */}
      <Route
        exact
        path={`${Path.vehicleDetail}:slug`}
        component={VeshicleDetail}
      />
      <Route exact path={Path.sellingProcess} component={SellingProcess} />
      {/* <PrivateRoute exact path={Path.sellingProcess} component={SellingProcess} /> */}
      {/* <Route exact path={`${Path.customerMessage}/:slug`} component={CustomerMessage} /> */}
      <Route exact path={Path.termsConditions} component={TermsConditions} />
      <Route exact path={Path.privacyPolicy} component={PrivacyPolicy} />
      <Route exact path={Path.cookiePolicy} component={CookiePolicy} />
      <Route exact path={Path.pricing} component={Pricing} />
      <Route exact path={Path.reviewDetails} component={ReviewDetails} />

      <Route exact path={Path.carDetail} component={CarDetail} />
      <Route path={Path.sellerProfile} component={SellerProfile} />
      <Route path={`${Path.updateMileage}/:slug`} component={UpdateMileage} />
      <Route
        exact
        path={`${Path.sellerSignUp}:slug`}
        component={SellerSignup}
      />
      <Route exact path={"/test"} component={TestComponent} />

      <Route exact path={Path.home} component={Home} />
      
     </Switch>

  );
};

export default Routes;
