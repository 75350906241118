import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../Components/Common/Button";
import CommonBanner from "../../Components/Common/CommonBanner/CommonBanner";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import Path from "../../Constants/Routes";
import { listAllVehicle } from "../../Services/vehicleList";
import CarList from "./../../Assets/Images/list-car.png";
import "./CarListing.scss";

const CarListing = () => {
  const history = useHistory();
  const [vehicleList, setVehicleList] = useState([]);
  const [total, totalVehicle] = useState(0);
  const [popupState, setPopupState] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getAllVehicle();
  }, []);

  const getAllVehicle = () => {
    listAllVehicle()
      .then((res) => {
        if (res.status === false) {
          console.log(res?.data?.message);
        } else {
          console.log("data", res?.data?.data?.data);
          setVehicleList(res?.data?.data?.data);
          totalVehicle(res?.data?.data?.total);
          setLoader(false);
          setTimeout(() => {
            setShowDetails(true);
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // handlePopup
  const handlePopup = () => {
    if (popupState) {
      setPopupState(false);
    } else {
      setPopupState(true);
    }
  };

  return (
    <>
      {loader && <LoaderWrapper />}
      <div className="car-listing">
        <CommonBanner>
          <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
            <h1 className="car-name">Vehicles For Sale</h1>
            {/* <div className="detail">
            <span className="info">Monday 7th December</span>
          </div> */}
          </div>
        </CommonBanner>

        <div className="listing-head">
          <Button className="secondary" onClick={handlePopup}>
            Filter
          </Button>
          <h3 className="heading">
            Showing {total > 12 ? 12 : total} vehicles from {total}
          </h3>
          {/* <select
            className="input"
            data-hj-whitelist="true"
            id="saleTimeFrameSelect"
            data-e2ename="sale timeframe"
            aria-expanded="false"
          >
            <option value="" disabled="">
              Select…
            </option>
            <option value="24 hours" data-e2ename="24 hours">
              option 1
            </option>
            <option value="7 days" data-e2ename="7 days">
              option 2
            </option>
            <option value="30 days" data-e2ename="30 days">
              option 3
            </option>
            <option value="3 months" data-e2ename="3 months">
              option 4
            </option>
            <option value="12 months" data-e2ename="12 months">
              option 5
            </option>
            <option value="not selling" data-e2ename="not selling">
              option 6
            </option>
          </select> */}
        </div>
        <div className="listing-wrapper">
          <div className="card-outer-wrapper">
            {showDetails ? (
              <>
                {vehicleList?.map((vehicle, index) => (
                  <Link
                    to={`${Path.vehicleDetail}${vehicle.id}`}
                    key={vehicle.id}
                    className="card-wrapper"
                  >
                    {console.log(JSON.parse(vehicle?.szVehicleDetails))}
                    <div className="list-card">
                      <div className="avatar">
                        <img src={CarList} alt="car" />
                      </div>
                      <div className="card-body">
                        <ul className="tab d-flex list-style-none">
                          <li className="title">{vehicle?.szVehicleNumber}</li>
                          {/* <li className="location">
                       <span className="icon"></span>
                       174 mi away
                     </li> */}
                        </ul>
                        <h2 className="card-heading">
                          {
                            JSON.parse(vehicle?.szVehicleDetails)
                              ?.vehicleDetails?.model
                          }
                        </h2>
                        <ul className="feature width-100 list-style-none">
                          <li>{`20${
                            JSON.parse(vehicle?.szVehicleDetails)
                              ?.vehicleDetails?.modelYear
                          }`}</li>
                          <li>{vehicle?.szMileage} miles</li>
                          <li>
                            {
                              JSON.parse(vehicle?.szVehicleDetails)
                                ?.vehicleDetails?.dvla?.data["13"]?.value
                            }
                          </li>
                          <li>
                            {
                              JSON.parse(vehicle?.szVehicleDetails)
                                ?.vehicleDetails?.smmt?.data["27"]?.value
                            }{" "}
                          </li>
                        </ul>
                        <h3 className="guid-price">
                          Guide price: £{vehicle?.dBuyNowPrice}
                        </h3>
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="filterpopup">
        <Popup
          onClick={handlePopup}
          state={popupState}
          heading="Filter Vehicles"
          btnText="Filter"
        >
          <form className="form">
            <div className="filterpopup-inner">
              <div className="filter-field">
                <label className="filterlabel">Mileage</label>
                <select
                  className="input"
                  data-hj-whitelist="true"
                  id="saleTimeFrameSelect"
                  data-e2ename="sale timeframe"
                  aria-expanded="false"
                >
                  <option value="" disabled="">
                    Select…
                  </option>
                  <option value="24 hours" data-e2ename="24 hours">
                    option 1
                  </option>
                  <option value="7 days" data-e2ename="7 days">
                    option 2
                  </option>
                  <option value="30 days" data-e2ename="30 days">
                    option 3
                  </option>
                  <option value="3 months" data-e2ename="3 months">
                    option 4
                  </option>
                  <option value="12 months" data-e2ename="12 months">
                    option 5
                  </option>
                  <option value="not selling" data-e2ename="not selling">
                    option 6
                  </option>
                </select>
              </div>
              <div className="filter-field">
                <label className="filterlabel">Guide Price</label>
                <input
                  type="range"
                  className="range"
                  name="points"
                  min="10"
                  max="102"
                />
                <span>0 - 10,000+ Mileg</span>
              </div>
              <div className="filter-field">
                <label className="filterlabel">Mileage</label>
                <input
                  type="range"
                  className="range"
                  name="points"
                  min="10"
                  max="102"
                />
                <span>0 - 10,000+ Mileg</span>
              </div>
              <div className="filter-field">
                <label className="filterlabel">Age</label>
                <input
                  type="range"
                  className="range"
                  name="points"
                  min="10"
                  max="102"
                />
                <span>0 - 70+ Years</span>
              </div>
              <div className="filter-field">
                <label className="filterlabel">Distance from LS18 4EG</label>
                <input
                  type="range"
                  className="range"
                  name="points"
                  min="10"
                  max="102"
                />
                <span>Up to 500 mileg</span>
              </div>
              <div className="filter-field">
                <label className="filterlabel">Fuel</label>
                <button> All</button>
                <button>Petrol</button>
                <button>Diesel</button>
              </div>
            </div>
          </form>
        </Popup>
      </div>
    </>
  );
};

export default CarListing;
