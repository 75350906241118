import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import RadioButton from "../../../Common/RadioBotton/RadioButton";
import RadioCircle from "../../../Common/RadioCircle";
import TextArea from "../../../Common/TextArea/TextArea";
import { validate } from "./validate";

function Index(props) {
  const [state, setstate] = useState();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="steps-form-details">
      <h3 className="heading">
        <span>Condition</span>
      </h3>
      <div className="form-wrapper">
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Scratches?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bScratches"}
                  id="scratchesYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  type="radio"
                  children="Yes"
                />
                <Field
                  name={"bScratches"}
                  id="scratchesNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
              {props.bScratches === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szScratchesDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Scuffs?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bScuffs"}
                  id="bScuffsYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                  type="radio"
                />
                <Field
                  name={"bScuffs"}
                  id="bScuffsNo"
                  component={RadioButton}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
              {props.bScuffs === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szScuffsDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Dents?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bDents"}
                  id="bDentsYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                  type="radio"
                />
                <Field
                  name={"bDents"}
                  id="bDentsNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
              {props.bDents === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szDentsDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Paintwork problems?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bPaintworkProblems"}
                  id="bPaintworkProblemsYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                  type="radio"
                />
                <Field
                  name={"bPaintworkProblems"}
                  id="bPaintworkProblemsNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
            </div>
            {props.bPaintworkProblems === "1" && (
              <div className="textarea-wrapper">
                <Field
                  name={"szPaintworkProblemsDescription"}
                  component={TextArea}
                  className="text-area"
                  placeholder="Type description..."
                  onChange={() => console.log("hello")}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">
                Broken/missing lights, mirrors, trim or fittings?
              </h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bBrokenMissing"}
                  id="bBrokenMissingYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                  type="radio"
                />
                <Field
                  name={"bBrokenMissing"}
                  id="bBrokenMissingNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
              {props.bBrokenMissing === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szBrokenMissingDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Air conditioning problems?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bAirConditioningProblems"}
                  id="bAirConditioningProblemsYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                  type="radio"
                />
                <Field
                  name={"bAirConditioningProblems"}
                  id="bAirConditioningProblemsNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
              {props.bAirConditioningProblems === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szAirConditioningProblemsDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Mechanical or electrical faults?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bMechanicalElectricalFaults"}
                  id="bMechanicalElectricalFaultsYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                  type="radio"
                />
                <Field
                  name={"bMechanicalElectricalFaults"}
                  id="bMechanicalElectricalFaultsNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
              {props.bMechanicalElectricalFaults === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szMechanicalElectricalFaultsDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Warning lights on dashboard?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bDashboardWarningLights"}
                  id="bDashboardWarningLightsYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                  type="radio"
                />
                <Field
                  name={"bDashboardWarningLights"}
                  id="bDashboardWarningLightsNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value={0}
                  type="radio"
                />
              </div>
              {props.bDashboardWarningLights === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szDashboardWarningLightsDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selector = formValueSelector("step3");
Index = reduxForm({
  // a unique name for the form
  form: "step3",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(Index);

export default connect((state) => {
  // can select values individually
  const bScratches = selector(state, "bScratches");
  const bScuffs = selector(state, "bScuffs");
  const bDents = selector(state, "bDents");
  const bPaintworkProblems = selector(state, "bPaintworkProblems");
  const bBrokenMissing = selector(state, "bBrokenMissing");
  const bAirConditioningProblems = selector(state, "bAirConditioningProblems");
  const bMechanicalElectricalFaults = selector(
    state,
    "bMechanicalElectricalFaults"
  );
  const bDashboardWarningLights = selector(state, "bDashboardWarningLights");

  return {
    bScratches,
    bScuffs,
    bDents,
    bPaintworkProblems,
    bBrokenMissing,
    bAirConditioningProblems,
    bMechanicalElectricalFaults,
    bDashboardWarningLights,
  };
})(Index);
