import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { required, validate } from "../../Components/Auth/SellerSignup/validate";
import Button from "../../Components/Common/Button";
import Input from "../../Components/Common/Input";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import { updateUsername, store } from "../../Helpers/Helpers";
import Path from "../../Constants/Routes";

import { updateDetails } from "../../Services/UpdateDetails";
import "./Role.scss";
import { getRoles } from "../../Services/getRoles";
import SelectBox from "../../Components/Common/SelectBox/SelectBox";
import { addEmployee } from "../../Services/addUser";
import { getUserDetails } from "../../Services/getUserDetails";
import { updateUser } from "../../Services/updateUser";

let EditUserForm = (props) => {
    const [loader, setLoader] = useState(false);
    const [popup, setPopup] = useState(false);
    let { slug } = useParams();

    const updateUserFun = (values) => {
        validate(values);
        let newvalues = {...{fullName:values.fullName,
        mobile: values.mobile,
        status:values.status,
        user_access_group: values.user_access_group},...{"id":slug}}
        console.log("addUser", newvalues)
        let req = newvalues;
        setLoader(true);
        updateUser(req)
            .then(() => {
                setPopup(true)
                setLoader(false);
            }).catch((err) => {
                console.log(err);
                setLoader(false);
            });
    }
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <>
            <form onSubmit={handleSubmit(updateUserFun)} className="form-horizontal form-label-left"  >
                <div className="item form-group bad">
                    <label className="control-label" for="name">Full Name <span className="required">*</span>
                    </label>
                    <div className="control">
                        <Field
                            name="fullName"
                            type="text"
                            component={Input}
                            className="form-control"
                            placeholder="Full Name"
                            // normalize={normalizePostCode}
                            validate={[required]}
                        />
                    </div>

                    <label className="control-label" for="name">Email <span className="required">*</span>
                    </label>
                    <div className="control">
                        <Field
                            name="email"
                            type="text"
                            component={Input}
                            className="form-control"
                            placeholder="Email"
                            disabled={true}
                             
                        />
                    </div>

                    <label className="control-label" for="name">Mobile <span className="required">*</span>
                    </label>
                    <div className="control">
                        <Field
                            name="mobile"
                            type="text"
                            component={Input}
                            className="form-control"
                            placeholder="Mobile"
                            // normalize={normalizePostCode}
                            validate={[required]}
                        />
                    </div>


                    <label className="control-label" for="name">Password <span className="required">*</span>
                    </label>
                    <div className="control">
                        <Field
                            name="password"
                            type="password"
                            component={Input}
                            className="form-control"
                            placeholder="Password"
                        // normalize={normalizePostCode}

                        />
                    </div>


                    <label className="control-label" for="name">Status <span className="required">*</span>
                    </label>
                    <div className="control">
                        <Field
                            name="status"
                            type="text"
                            component={SelectBox}
                            className="form-control"
                            placeholder="Status"
                            options={[{ value: 0, label: "Active" }, { value: 1, label: "In Active" }]}
                            // normalize={normalizePostCode}
                            
                        />
                    </div>


                    <label className="control-label" for="name">Role     <span className="required">*</span>
                    </label>
                    <div className="control">

                        <Field
                            name="user_access_group"
                            type="number"
                            component={SelectBox}
                            className="form-control"
                            placeholder="Role"
                            options={props.roleList}
                            validate={[required]}
                        />


                    </div>
                </div>
                <div className="form-group">
                    <div className="">

                        <Button className="addrole btn primary" type="submit">
                            Update
              </Button>
                        <Link className="addrole btn primary" to={Path.subUser}>
                            Cancel
              </Link>
                    </div>
                </div>
            </form>
        </>
    );
};



EditUserForm = reduxForm({
    // a unique name for the form
    form: "EditUserForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate,
})(EditUserForm);

export default connect((state) => {
    const formValues = state?.form["EditUserForm"]?.values;
    return {
        formValues,
        state
    };
    // return state;
})(EditUserForm);