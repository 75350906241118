import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LogoSvg } from '../../Assets/Icons';
import HdmailIcon from '../../Assets/Icons/hdmail .svg';
import Path from '../../Constants/Routes';
import { getToken, store } from '../../Helpers/Helpers';
import { ReactComponent as Close } from './../../Assets/Icons/close.svg';
import { ReactComponent as MenuIcon } from './../../Assets/Icons/hamburger.svg';
import MailOrange from './../../Assets/Icons/mail-orange.png';
import UserWhite from './../../Assets/Icons/user-white.png';
import { ReactComponent as UserSettingWhite } from './../../Assets/Icons/user-white.svg';
import User from './../../Assets/Icons/user.png';
import { ReactComponent as UserSetting } from './../../Assets/Icons/user.svg';
// @style
import './Header.scss';

const Header = (props) => {
    const [headerScroll, setHeaderSaroll] = useState(false);
    const [menuToggle, setToggleMenu] = useState(false);
    const [headerBG, setHeaderBG] = useState(false);
    const [auth, setAuth] = useState(false);
    const [settingMenu, setSettingMenu] = useState(false);
    const history = useHistory();
    const pathname = history.location.pathname;
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setHeaderSaroll(window.scrollY > 50);
        });
    }, []);

    useEffect(() => {
        setAuth(getToken('token'));
    });

    const location = useLocation();
    useEffect(() => {}, [location.pathname]);

    const toggleMenuClose = () => {
        setToggleMenu(false);
    };

    const toggleMenuOpen = () => {
        setToggleMenu(true);
    };

    const logout = () => {
        localStorage.clear();
        history.push(Path.home);
    };

    const toggleSettingMenuOpen = () => {
        setSettingMenu(true);
    };
    const toggleSettingMenuClose = () => {
        setSettingMenu(false);
    };
    let isHomePage = /^\/$/.test(pathname) || pathname === '';
    let userName = localStorage.getItem('szFullName');
    return (
        <div
            className={`header 
      ${(headerScroll && isHomePage) || !isHomePage ? '' : ''}`}
        >
            <div className="header-top">
                <div className="container">
                    <div className="header-top-inner">
                        <div className="customer-login">
                            <ul className="customer-toggle">
                                {!auth ? (
                                    <li className="menu-item logincls" onClick={toggleMenuClose}>
                                        <Link to={Path.login} className={`btn align-items-center ${(headerScroll && isHomePage) || !isHomePage ? '' : ''}`}>
                                            <span>Customer Login</span>
                                        </Link>
                                    </li>
                                ) : (
                                    <li className="menu-item " onClick={toggleMenuClose}>
                                        {window.innerWidth > 300 ? (
                                            <>
                                                {(headerScroll && isHomePage) || !isHomePage ? (
                                                    <div className="cursor-pointer" onClick={toggleSettingMenuOpen}>
                                                        <span className="text-white profile-name text-capitalize ml-1">{store.getState()}</span>
                                                    </div>
                                                ) : (
                                                    <div className="cursor-pointer" onClick={toggleSettingMenuOpen}>
                                                        <span className="text-white profile-name text-capitalize">{store.getState()}</span>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <div className={`setting-menu ${settingMenu ? 'show' : ''}`}>
                                            <span className="toggle-header d-flex">
                                                <h5>User Setting</h5>
                                                <Close className="icon" onClick={toggleSettingMenuClose} />
                                            </span>
                                            <span>
                                                <div className="menu-on-full">
                                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.myVehicle}>
                                                        My Account
                                                    </Link>
                                                </div>
                                                <div className="menu-on-small">
                                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.awaitingSubmission}>
                                                        Awaiting Submission
                                                    </Link>
                                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.currentAuctions}>
                                                        Current Auctions
                                                    </Link>
                                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.successfullySold}>
                                                        Successfully Sold
                                                    </Link>
                                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.unsuccessful}>
                                                        Unsuccessful
                                                    </Link>
                                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.withdrawn}>
                                                        Withdrawn
                                                    </Link>
                                                    <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.editProfile}>
                                                        Edit Profile
                                                    </Link>
                                                    {localStorage.getItem('userType') == 12 && (
                                                        <>
                                                            <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.role}>
                                                                Role
                                                            </Link>
                                                            <Link className="profile d-block" onClick={toggleSettingMenuClose} to={Path.subUser}>
                                                                Sub Users
                                                            </Link>
                                                        </>
                                                    )}
                                                </div>
                                            </span>
                                            <span className="d-block" onClick={toggleSettingMenuClose}>
                                                <span onClick={logout} className="logout d-block">
                                                    Logout
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mg-bt ref d-flex align-items-center">
                <div className="logo">
                    <Link to="/">
                        <LogoSvg className="width-100" />
                    </Link>
                </div>
                <div className="ml-auto d-flex">
                    {auth && (
                        <>
                            {window.innerWidth < 1070
                                ? ''
                                : // <UserSetting
                                  //   className="setting home"
                                  //   onClick={toggleSettingMenuOpen}
                                  // />
                                  ''}
                        </>
                    )}
                    <MenuIcon className="menu-icon" onClick={toggleMenuOpen} />
                </div>
                <div className={`navigation ${menuToggle ? 'left-0' : 'left-minus-100'}`}>
                    <Close className="close-menu" onClick={toggleMenuClose} />
                    <ul className="list-style-none menu  d-flex align-items-center">
                        <li className="menu-item" onClick={toggleMenuClose}>
                            <Link className="item" to={Path.home}>
                                Home {'>'}
                            </Link>
                        </li>
                        <li className="menu-item" onClick={toggleMenuClose}>
                            <Link className="item" to={Path.sellingProcess}>
                                Selling Process {'>'}
                            </Link>
                        </li>
                        <li className="menu-item" onClick={toggleMenuClose}>
                            <Link className="item" to={Path.contactUs}>
                                Contact Us {'>'}
                            </Link>
                        </li>
                        <li className="menu-item" onClick={toggleMenuClose}>
                            <Link className="item" to={Path.aboutUs}>
                                About Us {'>'}
                            </Link>
                        </li>
                        {/* <li
              className="menu-item email d-flex align-items-center"
              onClick={toggleMenuClose}
            >
               <img
                className="icon"
                src={
                  (headerScroll && isHomePage) || !isHomePage
                    ? MailOrange
                    : HdmailIcon
                }
                alt="mail"
              /> 
              <a className="item" href="mailto:sell@letthecargo.co.uk">
              sell@letthecargo.co.uk
              </a>
            </li> */}
                        {/* {!auth ? (
              <li className="menu-item logincls" onClick={toggleMenuClose}>
                <Link
                  to={Path.login}
                  className={`btn   d-flex align-items-center ${
                    (headerScroll && isHomePage) || !isHomePage
                      ? "primary-green"
                      : "secondary"
                  }`}
                >
                  <img
                    className="icon"
                    src={
                      (headerScroll && isHomePage) || !isHomePage
                        ? UserWhite
                        : User
                    }
                    alt="user"
                  />
                  <span>Login</span>
                </Link>
              </li>
            ) : (
              <li className="menu-item ml-auto" onClick={toggleMenuClose}>
                {window.innerWidth > 1069 ? (
                  <>
                    {(headerScroll && isHomePage) || !isHomePage ? (
                      <div
                        className="d-flex cursor-pointer"
                        onClick={toggleSettingMenuOpen}
                      >
                        <UserSetting className="setting" />
                        <span className="text-primary profile-name text-capitalize ml-1">
                          {store.getState()}
                        </span>
                      </div>
                    ) : (
                      <div
                        className="d-flex cursor-pointer"
                        onClick={toggleSettingMenuOpen}
                      >
                        <UserSettingWhite className="setting" />
                        <span className="text-white profile-name text-capitalize ml-1">
                          {store.getState()}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
                <div className={`setting-menu ${settingMenu ? "show" : ""}`}>
                  <span className="toggle-header d-flex">
                    <h5>User Setting</h5>
                    <Close className="icon" onClick={toggleSettingMenuClose} />
                  </span>
                  <span>
                    <Link
                      className="profile d-block"
                      onClick={toggleSettingMenuClose}
                      to={Path.myVehicle}
                    >
                      My Account
                    </Link>
                  </span>
                  <span className="d-block" onClick={toggleSettingMenuClose}>
                    <span onClick={logout} className="logout d-block">
                      Logout
                    </span>
                  </span>
                </div>
              </li>
            )} */}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default connect((state) => {
    return state;
    // return state;
})(Header);
