import React from "react";
import "./RadioCircle.scss";

const RadioCircle = ({ input, children, className, name, id, value, idValue }) => {
// console.log("inputinputinput========>",name,'m'+idValue,'t'+input.value,idValue.value==idValue)
  return (
    <div className={` ${className}`}>
      <input {...input} type="radio" name={name} id={id} value={idValue} checked={(input.value).toString()!="" && (input.value == idValue)}  />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export default RadioCircle;
