import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { validate } from "../../Components/Auth/SellerSignup/validate";
import Button from "../../Components/Common/Button";
import Input from "../../Components/Common/Input";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import {  updateUsername,store } from "../../Helpers/Helpers";
import { getSellerDetails } from "../../Services/getUserProfile";
import { updateSellerDetails } from "../../Services/updateSeller";
import "./EditProfile.scss";

  
let EditProfile = (props) => {
  const [sellerDetail, setSellerDetail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getSellerDetailFun();
  }, []);

  useEffect(() => {
    updateUsername(localStorage.getItem("szFullName"));
  }, [localStorage.getItem("szFullName")]);
  // @seller details
  const getSellerDetailFun = () => {
    setLoader(true);
    getSellerDetails()
      .then(async (res) => {
        if (res?.success !== false) {
          setSellerDetail(res?.data?.data);
          await (function () {
            props.dispatch(
              change("sellerProfile", "szFullName", res?.data?.data?.szFullName)
            );
            props.dispatch(
              change(
                "sellerProfile",
                "szPhoneNumber",
                res?.data?.data?.szPhoneNumber
              )
            );
            props.dispatch(
              change("sellerProfile", "szPostCode", res?.data?.data?.szPostCode)
            );
            props.dispatch(
              change(
                "sellerProfile",
                "iIsNotification",
                res?.data?.data?.iIsNotification
              )
            );
            props.dispatch(
              change("sellerProfile", "szEmail", res?.data?.data?.szEmail)
            );

            updateUsername(res?.data?.data?.szFullName);
            setLoader(false);
          })();
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  // @seller signup
  const sellerUpdate = (values) => {
    validate(values);
    let payload = props.formValues;
    payload["iIsNotification"] = 0;
    setLoader(true);
    store.dispatch({
      type: 'ADD_TODO',
      text: payload.szFullName
    })
    updateSellerDetails(payload)
      .then((res) => {
        
        localStorage.setItem("szFullName",payload.szFullName);
        getSellerDetails();
        setTimeout(()=>{
          setLoader(false);
          setPopup(true);
        })
        
      })
      .catch((err) => {
        setLoader(false);
      });
    // if(state.email &&)
  };

  const handlePopup = () => {
    setPopup(!popup);
  };

  const { handleSubmit, pristine, reset, submitting } = props;

  return (
    <div className="edit-profile">
      {loader && <LoaderWrapper />}
      <div className="container-edit-profile">
        <h1 className="heading">Your Details</h1>
        <div className="edit-profilefield">
          <form
            onSubmit={handleSubmit(sellerUpdate)}
            className="edit-profile-form form-label-left"
          >
            <div className="form-field">
              <label>Full Name</label>
              <Field
                name="szFullName"
                type="text"
                component={Input}
                className="width-100 text-uppercase"
                // normalize={normalizePostCode}
              />
            </div>
            <div className="form-field">
              <label>Email</label>
              <Field
                name="szEmail"
                type="email"
                component={Input}
                writeOnce={true}
                className="width-100 text-uppercase"
                // normalize={normalizePostCode}
              />
            </div>
            <div className="form-field">
              <label>Phone Number</label>
              <Field
                name="szPhoneNumber"
                type="text"
                component={Input}
                className="width-100 text-uppercase"
                // normalize={normalizePostCode}
              />
            </div>
            <div className="form-field">
              <label>Postcode</label>
              <Field
                name="szPostCode"
                type="text"
                component={Input}
                className="width-100 text-uppercase"
                // normalize={normalizePostCode}
              />
            </div>
            <div className="submit-mass">
              <Button className="primary" type="submit">
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
      {/* popup */}
      <Popup
        onClick={handlePopup}
        // continue={continueForm}
        state={popup}
        heading="Profile"
        btnText="Filter"
        // continueText="Save later"
      >
        <p>Profile has been updated successfully.</p>
        <button className="btn primary" onClick={handlePopup}>
          Ok
        </button>
      </Popup>
    </div>
  );
};
// const selector = formValueSelector("sellerSignup");
EditProfile = reduxForm({
  // a unique name for the form
  form: "sellerProfile",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(EditProfile);

export default connect((state) => {
  const formValues = state?.form["sellerProfile"]?.values;
  return {
    formValues,
    state,
  };
  // return state;
})(EditProfile);
