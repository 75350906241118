import React, { useEffect, useState } from 'react';
import {} from 'title-case';
import { ReactComponent as CameraIcon } from '../../Assets/Icons/camera.svg';
import { ReactComponent as FileIcon } from '../../Assets/Icons/file.svg';
import { ReactComponent as SettingsIcon } from '../../Assets/Icons/settings.svg';
import { ReactComponent as WheelIcon } from '../../Assets/Icons/wheel.svg';
import CommonBanner from '../../Components/Common/CommonBanner/CommonBanner';
import { s3URL } from '../../Constants/constants';
import { getVehicleDetail } from '../../Services/getVehicleDetails';
import './ReviewDetails.scss';
import { useHistory } from 'react-router-dom';
import LoaderWrapper from '../../Components/Common/Loader/Loader';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { updateVehicleDetails } from '../../Services/updateVehicleDetails';

let ReviewDetails = (props) => {
    const history = useHistory();
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState('');
    const setVehicleDetail = (id) => {
        setLoader(true);
        getVehicleDetail(id)
            .then((res) => {
                let data = res?.data?.data;
                setLoader(false);
                setFormData(data);
            })
            .catch((err) => {
                // this.props.hideLoader();
            });
    };

    useEffect(() => {
        let id = localStorage.getItem('idVehicleDetails');
        if (!id) {
            history.push('/');
        }

        setVehicleDetail(id);
    }, []);
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }, []);

    const handleEdit = (iStep) => {
        console.log('================');
        let tt = JSON.parse(localStorage.getItem('objVehicleDetails'));
        tt.iStep = iStep;
        localStorage.setItem('objVehicleDetails', JSON.stringify(tt));
        history.push('/car-detail');
    };

    return (
        <div className="detailpage">
            {loader && <LoaderWrapper />}

            <div className="container-review-details">
                <div className="detailpage-inner">
                    <h3 className="heading">
                        <span>Details Submitted</span>
                    </h3>
                    <p className="content-p">Congratulations, the details have been submitted. We will be in touch shortly to discuss details to submit vehicle into auction.</p>

                    <div className="summary-items">
                        <div className="summary-item">
                            <h3 className="heading">
                                <FileIcon />
                                <span>Basic Details</span>
                                <span className="cg-link" onClick={() => handleEdit(1)}>
                                    Edit
                                </span>
                            </h3>
                            <ul className="specList">
                                <h2 data-label="Features">Features</h2>
                                <li>
                                    {formData?.szPatNav == '1' ? (
                                        <p className="d-block" style={{ fontSize: '16px' }} data-key="Features" data-value="Sat nav">
                                            Sat Nav
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {formData?.szPanoramicRoof == '1' ? (
                                        <p className="d-block" style={{ fontSize: '16px' }} data-key="Features" data-value="Panoramic roof">
                                            Panoramic roof
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {formData?.szHeatedSeats == '1' ? (
                                        <p className="d-block" style={{ fontSize: '16px' }} data-key="Features" data-value="Heated seats">
                                            Heated seats
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {formData?.szParkingCamera == '1' ? (
                                        <p className="d-block" data-key="Features" style={{ fontSize: '16px' }} data-value="Parking camera">
                                            Parking camera
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {formData?.szUpgradedSoundSystem == '1' ? (
                                        <p className="d-block" data-key="Features" style={{ fontSize: '16px' }} data-value="Upgraded sound system">
                                            Upgraded sound system
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </li>
                                <li>
                                    <span data-label="Vehicle Colour">Vehicle Colour</span>
                                    <span data-key="Vehicle Colour" data-value="Black">
                                        {formData?.szCarColour}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="How are they upholstered?">Upholstered</span>
                                    <span data-key="Seat fabric" data-value="Half leather">
                                        {formData?.szUpholstered}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="What colour are the seats?">Seats Colour</span>
                                    <span data-key="What colour are the seats?" data-value="Half leather">
                                        {formData?.szSeatsColor}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Seat fabric">Written off</span>
                                    <span data-key="Seat fabric" data-value="Half leather">
                                        {formData?.bWrittenOff ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Seat fabric">Two Working Keys</span>
                                    <span data-key="Seat fabric" data-value="Half leather">
                                        {formData?.bTwoWorkingKeys ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Number of keys">V5C logbook</span>
                                    <span data-key="Number of keys" data-value="2">
                                        {formData?.bV5CLogBook ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="V5C logbook">Is the V5C logbook</span>
                                    <span data-key="V5C logbook" data-value="Yes">
                                        {formData?.bLogbookName ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Book Pack</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.bBookPack ? 'Yes' : 'No'}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="summary-item">
                            <h3 className="heading">
                                <SettingsIcon />
                                <span>Service History</span>
                                <span className="cg-link" onClick={() => handleEdit(2)}>
                                    Edit
                                </span>
                            </h3>
                            <ul className="specList">
                                <li>
                                    <span data-label="Service history">Service History</span>
                                    <span data-key="Service history" data-value="No service history">
                                        {formData?.szServiceHistoryCar == '1' && 'Full service history'}
                                        {formData?.szServiceHistoryCar == '4' && 'Partial service history'}
                                        {formData?.szServiceHistoryCar == '2' && 'No service history'}
                                        {formData?.szServiceHistoryCar == '3' && 'Not yet due first service'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Service Record</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.bServiceRecord == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Manufacturer</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.szManufacturer}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Independent Garage Services</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.szIndependentGarageServices}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Vehicle Last Serviced</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.dtVehicleLastServiced}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Mileage at the last service</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.szLastServiceMileage}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Cambelt ever been changed</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.bCambeltChanged == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Cambelt changed</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.dtCambeltChange}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Logbook in your name">Mileage when the cambelt was changed</span>
                                    <span data-key="Logbook in your name" data-value="-">
                                        {formData?.szMilageCambeltChanged}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="summary-item">
                            <h3 className="heading">
                                <WheelIcon />
                                <span>Condition</span>
                                <span className="cg-link" onClick={() => handleEdit(3)}>
                                    Edit
                                </span>
                            </h3>
                            <ul className="specList">
                                <li>
                                    <span data-label="Tyres damaged">Scratches</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bScratches == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Scratches Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szScratchesDescription}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Scuffs</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bScuffs == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Scuffs Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szScuffsDescription}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Dents</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bDents == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Dents Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szDentsDescription}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Paintwork Problems</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bPaintworkProblems == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Paintwork Problems Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szPaintworkProblemsDescription}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Broken/missing lights, mirrors, trim or fittings</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bBrokenMissing == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Broken/missing lights, mirrors, trim or fittings</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szBrokenMissingDescription}
                                    </span>
                                </li>

                                <li>
                                    <span data-label="Tyres damaged">Air conditioning problems </span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bAirConditioningProblems == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Air conditioning problems Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szAirConditioningProblemsDescription}
                                    </span>
                                </li>

                                <li>
                                    <span data-label="Tyres damaged">Mechanical or electrical faults</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bMechanicalElectricalFaults == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Mechanical or electrical faults Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szMechanicalElectricalFaultsDescription}
                                    </span>
                                </li>

                                <li>
                                    <span data-label="Tyres damaged">Warning lights on dashboard</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bDashboardWarningLights == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Warning lights on dashboard Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szDashboardWarningLightsDescription}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="summary-item">
                            <h3 className="heading">
                                <WheelIcon />
                                <span>Wheels & tyres</span>
                                <span className="cg-link" onClick={() => handleEdit(4)}>
                                    Edit
                                </span>
                            </h3>
                            <ul className="specList">
                                <li>
                                    <span data-label="Tyres damaged">Alloys been kerbed or scuffed</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bKerbedOrScuffed == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Problems with the tyres</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bProblemsWithTyres == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Problems with the tyres Description</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.szDetailsOfProblemsWithTyres}
                                    </span>
                                </li>

                                <li>
                                    <span data-label="Tyres damaged">Locking wheel nut</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bLockingWheelNut == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                                <li>
                                    <span data-label="Tyres damaged">Tool pack</span>
                                    <span data-key="Tyres damaged" data-value="No">
                                        {formData?.bToolPack == '1' ? 'Yes' : 'No'}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="summary-item">
                            <h3 className="heading">
                                <CameraIcon />
                                <span>Photos</span>
                                <span className="cg-link" onClick={() => handleEdit(5)}>
                                    Edit
                                </span>
                            </h3>
                            <div className="imageUpload__component">
                                <div className="imageUpload__sectionHeader">
                                    <h4>Exterior</h4>
                                    <ul className="imageUpload__image_section">
                                        <li>
                                            <span class="imageUpload__image_input_label">Front</span>
                                            <span class="imageUpload__image_input_label">Driver Side</span>
                                            <div className="photoInput-module_component__18191 photoInput-module_image photoInput-module_medium__34JbJ">
                                                <label>
                                                    <img src={s3URL + formData?.szExteriorFrontDriverSide} alt="Front Driver Side" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Back</span>
                                            <span class="imageUpload__image_input_label">Driver Side</span>
                                            <div className="photoInput-module_component__18191 photoInput-module_image photoInput-module_medium__34JbJ">
                                                <label>
                                                    {/* <img
                            className="icon"
                            src={WheelImg}
                            alt="free valuation"
                          /> */}
                                                    <img src={s3URL + formData?.szExteriorBackDriverSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Front</span>
                                            <span class="imageUpload__image_input_label">Passenger side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="exterior_front_passenger"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szExteriorFrontPassengerSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Back</span>
                                            <span class="imageUpload__image_input_label">Passenger side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="exterior_rear_passenger"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szExteriorBackPassengerSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Interior */}
                                <div className="imageUpload__sectionHeader">
                                    <h4>Interior</h4>
                                    <ul className="imageUpload__image_section">
                                        <li>
                                            <span class="imageUpload__image_input_label">Front Seat</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="interior_front_seats"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szInteriorFrontSeats} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Rear Seat</span>
                                            <div className="photoInput-module_component__18191 photoInput-module_medium__34JbJ" data-name="interior_rear_seats">
                                                <label>
                                                    <img src={s3URL + formData?.szInteriorRearSeats} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Dashboard</span>
                                            <div className="photoInput-module_component__18191 photoInput-module_medium__34JbJ" data-name="interior_dashboard">
                                                <label>
                                                    <img src={s3URL + formData?.szInteriorDashboard} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Boot Interior</span>
                                            <div className="photoInput-module_component__18191 photoInput-module_medium__34JbJ" data-name="interior_boot">
                                                <label>
                                                    <img src={s3URL + formData?.szInteriorBoot} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                {/* Wheels */}
                                <div className="imageUpload__sectionHeader">
                                    <h4>Wheels</h4>
                                    <ul className="imageUpload__image_section">
                                        <li>
                                            <span class="imageUpload__image_input_label">Front</span>
                                            <span class="imageUpload__image_input_label">Driver Side</span>
                                            <div className="photoInput-module_component__18191 photoInput-module_medium__34JbJ" data-name="wheels_front_driver">
                                                <label>
                                                    <img src={s3URL + formData?.szWheelsFrontDriverSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Back</span>
                                            <span class="imageUpload__image_input_label">Driver Side</span>
                                            <div className="photoInput-module_component__18191 photoInput-module_medium__34JbJ" data-name="wheels_rear_driver">
                                                <label>
                                                    <img src={s3URL + formData?.szWheelsBackDriverSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Front</span>
                                            <span class="imageUpload__image_input_label">Passenger side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="wheels_front_passenger"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szWheelsFrontPassengerSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Back</span>
                                            <span class="imageUpload__image_input_label">Passenger side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="wheels_rear_passenger"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szWheelsBackPassengerSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Tyre treads */}
                                <div className="imageUpload__sectionHeader">
                                    <h4>Tyre treads</h4>
                                    <ul className="imageUpload__image_section">
                                        <li>
                                            <span class="imageUpload__image_input_label">Front</span>
                                            <span class="imageUpload__image_input_label">Driver Side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szTyreFrontDriverSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Rear</span>
                                            <span class="imageUpload__image_input_label">Driver Side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_rear_driver"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szTyreBackDriverSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Front</span>
                                            <span class="imageUpload__image_input_label">Passenger side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_passenger"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szTyreFrontPassengerSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="imageUpload__image_input_label">Rear</span>
                                            <span class="imageUpload__image_input_label">Passenger side</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_rear_passenger"
                                            >
                                                <label>
                                                    <img src={s3URL + formData?.szTyreBackPassengerSide} alt="" />
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Condition Photos */}
                                <div className="imageUpload__sectionHeader">
                                    <h4>Condition Photos</h4>
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Scratches and scuffs</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionScratchesAndScuffs'] &&
                                                    JSON.parse(formData['szConditionScratchesAndScuffs'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Dents */}
                                <div className="imageUpload__sectionHeader">
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Dents</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionDents'] &&
                                                    JSON.parse(formData['szConditionDents'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Paintwork problems */}
                                <div className="imageUpload__sectionHeader">
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Paintwork problems</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionPaintworkProblems'] &&
                                                    JSON.parse(formData['szConditionPaintworkProblems'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Missing Trims */}
                                <div className="imageUpload__sectionHeader">
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Missing Trims</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionMissingTrims'] &&
                                                    JSON.parse(formData['szConditionMissingTrims'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Warning Lights */}
                                <div className="imageUpload__sectionHeader">
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Warning Lights</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionWarningLights'] &&
                                                    JSON.parse(formData['szConditionWarningLights'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Windscreen Problems */}
                                <div className="imageUpload__sectionHeader">
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Windscreen Problems</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionWindscreenProblems'] &&
                                                    JSON.parse(formData['szConditionWindscreenProblems'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Alloy Scuffs */}
                                <div className="imageUpload__sectionHeader">
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Alloy Scuffs</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionAlloyScuffs'] &&
                                                    JSON.parse(formData['szConditionAlloyScuffs'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Type Problems */}
                                <div className="imageUpload__sectionHeader">
                                    <ul className="imageUpload__image_section">
                                        <li className="multi-images">
                                            <span class="imageUpload__image_input_label">Type Problems</span>
                                            <div
                                                className="photoInput-module_component__18191 photoInput-module_medium__34JbJ"
                                                data-name="tyre_tread_front_driver"
                                            >
                                                {formData['szConditionTypeProblems'] &&
                                                    JSON.parse(formData['szConditionTypeProblems'])?.map((item, index) => (
                                                        <div className="image-multi-item" key={index}>
                                                            <div className="image-cropper">
                                                                <img className="icon" src={s3URL + item} alt="free valuation" />
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
ReviewDetails = reduxForm({
    // a unique name for the form
    form: 'stepForm',
    enableReinitialize: true,
    destroyOnUnmount: false
    // validate,
})(ReviewDetails);

export default connect((state) => {
    const formValues = state?.form['stepForm']?.values;
    return {
        formValues
    };
})(ReviewDetails);
// export default ReviewDetails;
