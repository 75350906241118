import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Button from "../../Components/Common/Button";
import { s3URL } from "../../Constants/constants";
import Path from "../../Constants/Routes";
import { vehicleBidList } from "../../Services/vehicleBidList";
import { ReactComponent as CommentIcon } from "../../Assets/Icons/comment.svg";
import CarList from "./../../Assets/Icons/carlist.svg";
import BidListSub from "./BidListSub";
import CurrencyFormat from "react-currency-format";

const SuccessfullySold = ({ data, name, count, PageClick, pageNo }) => {
  const [bidList, setBidList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [bidlistFlag, setBidlistFlag] = useState(false);
  const [pageNoBid, setPageNoBid] = useState(0);
  const [countBid, setCountBid] = useState(0);
  
  // @api
  const getSellerVehicle = (id) => {
    setLoader(true);
    vehicleBidList(id)
      .then(async (res) => {
        if (res.status === false) {
          console.log("something went wrong");
        } else {
          console.log("salman", res?.data?.data?.data);
          await setBidList(res?.data?.data?.data);
          await setCountBid(res?.data?.data?.total);
          await console.log("api call", res);
          await setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handleBidList = (id) => {
    console.log("check id -------", id);
    setBidlistFlag(true);
    getSellerVehicle(id);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  const handlePageClick = (data) => {
    // console.log("+++++++)))____)((((", data);
    
    PageClick(data);
  };
  const handlePageClickBid = (data)=>{
    setPageNoBid(data.selected);
  }
  // console.log("+++++++)))____)((((=======", count);
  return (
    <>
      {!bidlistFlag ? (
        <>
        <ul className="myvehicle">
          {data?.map((bid, index) => {
            return (
              <li key={index}>
                <div className="item">
                  <div className="accountVehicleCardView">
                    <div className="vehiclesSection-lft">
                      <div className="photoArea">
                        <img
                          src={
                            bid?.szExteriorFrontDriverSide
                              ? s3URL + bid?.szExteriorFrontDriverSide
                              : CarList
                          }
                          alt="car logo"
                        />
                      </div>
                    </div>
                    <div className="vehiclesSection-rgt">
                      <div className="vrmArea">
                        <div className="vrmBadge-styles_badge">
                          <span>{bid?.szVehicleNumber}</span>
                        </div>
                      </div>
                     
                      <div className="nameArea">
                      <span>{bid?.szVehicleDetails?.make||'N/A'}</span>
                      </div>
                      <ul class="feature width-100 list-style-none">
                      <li>{bid?.szVehicleDetails?.yearOfManufacture||'N/A'}</li>                        
                        <li>
                        {
                          bid?.szVehicleDetails?.mileageDetails
                          ? <CurrencyFormat value={bid?.szVehicleDetails?.mileageDetails} displayType={"text"} thousandSeparator={true} />
                          :"N/A"
                        }
                        </li>
                        <li>{bid?.szVehicleDetails?.fuelType||'N/A'}</li>
                        <li>{bid?.szVehicleDetails?.colour||'N/A'} </li>
                      </ul>
                      <div className="bidareacls">
                     
                        <div className="bidArea">
                          <span className="mw-title-title6">
                            Total Bids:
                          </span>
                          <span className="bidsnumber">{bid?.i_bid_count}</span>
                        </div>
                        <div className="bidArea">
                          <span className="mw-title-title6">
                          Start Date:
                          </span>
                          <span className="bidsnumber">{bid?.dtAuctionStartDate}</span>
                        </div>
                        <div className="bids-status">
                          <span className="mw-title-title6">
                          End Date:
                          </span>
                          <span className="running">
                          {bid?.dtAuctionEndDate}
                          </span>
                        </div>
                        <div className="bids-status">
                          <span className="mw-title-title6">
                          Highest bid:
                          </span>
                          <span className="running">
                          {bid?.dHighestBid}
                          </span>
                        </div>
                        </div>
                        {/* <div className="bids-status">
                          <span className="mw-title-title6">
                            Auction Status:
                          </span>
                          <span className="running">
                            {bid?.status }
                          </span>
                        </div> */}
                        <div className="viewdetail">
                          <Button
                            className="sublink btn primary"
                            title="View detail"
                            onClick={() => handleBidList(bid?.id)}
                          >
                            View Bids
                          </Button>
                          {/* <Button
                            className="sublink btn primary"
                            title="View detail"
                            onClick={() => handleBidList(bid?.id)}
                          >
                            View detail
                          </Button> */}
                          <Link
                            title="View detail"
                            className="btn primary"
                            to={Path.ViewVehicleDetails + bid.id}
                          >
                            View details
                          </Link>
                          <Link
                              title="Message"
                              className="btn primary chatbtn"
                              to={`${Path.customerMessage}/${bid?.id}`}
                            >
                              <CommentIcon />
                            </Link>
                        </div>                                           
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {data.length<1 && <h3 style={{ textAlign: "center" }}>No vehicles submitted for auction.</h3>}
         {data.length>1 && count>10 &&
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={count ? count / 10 : 0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pull-right pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={pageNo}
            />
          </div>}
          </>
      ) : (
        <BidListSub
          data={bidList}
          bidListFun={getSellerVehicle}
          loader={loader}
          pageNo={pageNoBid}
          PageClick={handlePageClickBid}
          countBid={countBid}
        />
      )}
     
    </>
  );
};

export default SuccessfullySold;
