import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import { getRoles } from "../../Services/getRoles";
import Popup from "../../Components/Common/Popup/Popup";
import { deleteRole } from "../../Services/deleteRole";
import Path from "../../Constants/Routes";
import "./Role.scss";

const Role = () => {
  const [loader, setLoader] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [popup, setPopup] = useState(false);
  let [idRole, setIdRole] = useState(0);

  let { slug } = useParams();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    // getRoleList(slug);
    // setRoleLists(false)
  }, [slug]);

  useEffect(() => {
    getRoleList(slug);
  }, [slug]);
  const getRoleList = (req) => {
    setLoader(true);
    getRoles(req)

      .then((res) => {
        if (res?.data?.success == true) {
          setRoleList((res?.data?.data?.data));
          console.log("res", res?.data?.data?.data);
          setLoader(false);
        } else {
          console.log("Permission list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const deleteRoleFun = ()=>{
    setLoader(true);
    let req = {"roleId":idRole}
    console.log("reqreqreq",req)
    deleteRole(req)
    .then((result)=>{
      getRoleList(slug);
      setLoader(false);
      setPopup(false)
    })
    .catch((err) => {
      setLoader(false);
      setPopup(false)
      console.log(err);
    });
  }

  const confirmDelete = (idRole)=>{
    setIdRole(idRole)
    setPopup(true)
  }

  const cancelDelete = ()=>{
    setPopup(false)
  }

  return (
    <div className="x_panel">
      {loader && <LoaderWrapper />}
      <div className="x_content">
        <div className="addrolebtn">
          <Link className="addrole btn primary" to={Path.addRole}>
            Add Role
          </Link>
        </div>
        <div className="dataTables_wrapper">
          <table className="table table-striped">
            <thead>
              <tr role="row">
                <th className="sorting_asc">Sr. No.</th>
                <th className="sorting">Role Name</th>
                <th className="sorting">Action</th>
              </tr>
            </thead>
            <tbody>
              {roleList?.map((perm, index) => (
                <tr role="row" className="odd">
                  <td tabindex="0" className="sorting_1">{index + 1}</td>
                  <td>{perm.business_name}</td>
                  <td>
                    {/* <Link
    className="edit btn primary"    
    >
    Edit
    </Link> */}
                    <button
                      className="delate btn primary"
                      onClick={()=>{confirmDelete(perm.business_id)}}
                    >
                      Delete
    </button>
                    <Link
                      className="permission btn primary" to={`${Path.addPermission}/${perm.business_id}`}
                    >
                      Permission
    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Popup
        onClick={cancelDelete}
        // continue={continueForm}
        state={popup}
        heading="Role"
        btnText="Filter"
        // continueText="Save later"
      >
        <p>Are you sure you want to delete this role ?.</p>
        <button className="btn primary" onClick={cancelDelete}>
          cancel
        </button>
        <button className="btn primary" onClick={deleteRoleFun}>
          yes
        </button>
      </Popup>
    </div>
    
  );
};

export default Role;
