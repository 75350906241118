import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getPermission } from "../../Services/getPermission";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import { Field } from "redux-form";
import Path from "../../Constants/Routes";
import Checkbox from "../../Components/Common/Checkbox/Checkbox";

import "./Role.scss";
import CheckBox from "./CheckBox";
import { updatepermission } from "../../Services/updatePermission";

const AddPermission = (props) => {
  const [permission, setPemission] = useState("");
  const [loader, setLoader] = useState(false);
  let [permissionList, setPermissionList] = useState([]);
  let [dealerId, setDealerId] = useState(null);

  let { slug } = useParams();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    getPermissionList(slug);
  },[]);

  const getPermissionList = (req) => {
    console.log("check id -------", req)
    let payload = {
      roleId: req,

    };
    setLoader(true);
    getPermission(payload)
      .then((res) => {
        if (res?.data?.success == true) {
          setPermissionList((res?.data?.data)); //.sort().reverse()
          setLoader(false);
        } else {
          console.log("Permission list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const handleCheckChieldElement = (event) => {
    let fruites = [...permissionList,...[]];
    console.log("event.target.checked",event.target.checked,event.target.value)
    fruites.map((fruite, index) => {
      if (fruite.menu_name === event.target.value){
        fruite.menu_permision = event.target.checked ? 1 : 0;
      }
    })
    setPermissionList(fruites)
  }

  const updatePermission=()=>{
    setLoader(true);
    let arReq = [];
    permissionList.map((fruite, index) => {
      if (fruite.menu_permision === 1){
        arReq.push(fruite.menu_id);
      }
    })
    updatepermission({permisioarr:arReq,roleId:slug})
    .then((result)=>{
      console.log("resultresult",result)
      setLoader(false);
    })
    .catch((err) => {
      setLoader(false);
      console.log(err);
    });
  }

  return (
    <div className="x_panel">
      {loader && <LoaderWrapper />}
      <div className="x_content">
        <div className="addrolebtn">
          <Link className="addrole btn primary" to={Path.role}>
            Back
    </Link>
        </div>
        <div className="dataTables_wrapper">
          <table className="table table-striped">
            <thead>

              <tr role="row">
                <th className="sorting_asc">Sr. No.</th>
                <th className="sorting">Role Name</th>
                <th className="sorting">Action</th>
              </tr>
            </thead>
            <tbody>
              {console.log("fruite.menu_name",permissionList)}
              {permissionList?.map((perm, index) => (
                <>
                  {console.log(perm)}
                  {perm?.menu_name ?
                    <tr role="row" className="odd">

                      <td tabindex="0" className="sorting_1">{index + 1}</td>
                      <td>{perm?.menu_name}</td>
                      <td>
                        <input
                          name="szServiceHistoryCarp"
                          id="serviceHistoryFullp"
                          type="checkbox"
                          className="radio width-100"
                          // props={{ value: "one" }}
                          checked={perm?.menu_permision}
                          key={perm.menu_id} 
                          onClick={handleCheckChieldElement}  
                          value={perm?.menu_name}
                        />
                        {/* <CheckBox {...perm} handleCheckChieldElement = {handleCheckChieldElement} /> */}
                      </td>
                    </tr>
                    : ''}
                </>
              ))}
            </tbody>
          </table>
          <div>
            <button className="addrole btn primary" onClick={updatePermission} >Update Permission</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPermission;
