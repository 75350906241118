import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputCustom from "../../Components/Common/Input/InputCustom";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import { getToken, scrollToTop, isNumberKeyNew, store } from "../../Helpers/Helpers";
import { checkVehicleIsRegistered } from "../../Services/checkVehicle";
import { getPriceAndAddVehicle, getPriceAndRegisterVahicle, searchCar } from "../../Services/searchCar";
import Path from "./../../Constants/Routes";
import "./Home.scss";
import Button from "../../Components/Common/Button";
import Checkbox from "../../Components/Common/Checkbox/Checkbox";
import { addVehicleDetail } from "../../Services/addVehicle";
import CurrencyFormat from "react-currency-format";
import ReactGA from 'react-ga';
import { Field } from "redux-form";
import CheckboxCircle from "../../Components/Common/CheckboxCircle";
import { sendPhotoLink } from "../../Services/sendPhotoLink";
import LHS from '../../Assets/Images/CarGo_Homepage_Flash_02_LHS.png';
import RHS from '../../Assets/Images/CarGo_Homepage_Flash_02_RHS.png';
import BOTTOM from '../../Assets/Images/CarGo_Homepage_Flash_v2_MobileFlash.png';

const errorMsgsForField = {
  regNo:"Please enter car number",
  mileage:"Please enter car mileage",
  name:"Please enter name",
  ph:"Please enter phone number",
  email:"Please enter email"
}
function Home(props) {
  let history = useHistory();  
  const [error, setError] = useState({ regNo: "", mileage: '', name:"", ph:"", email:"" });
  const [errorMsg, setErrorMsg] = useState(null);
  const [loader, setLoader] = useState(false);  
  const [vehicleDetails, setVehicleDetails] = useState(null);  
const [state, setState] = useState({ regNo: "", mileage: '', name:"", ph:"", email:"", privacy: false });
const [requestLogin, setRequestLogin] = useState(false);

const isLoggedIn = getToken("token");

  useEffect(() => {
    scrollToTop();
    ReactGA.initialize('G-0XKQYZ8KWX');
    ReactGA.pageview(window.location.pathname);
  }, []);

  // handle Enter
  const handleEnter = (e) => {
    if (e.key == "Enter") {
      getVehicleDetails();
    }
  };
  
  const setFieldError = (name=null, value=null)=>{
      if(name===null){
        Object.keys(errorMsgsForField).forEach(k=>{
          setError(st => ({ 
            ...st, 
            [k]: state[k] ? "" : errorMsgsForField[k]
          }));
        })
      }else{
        setError(st => ({ 
          ...st, 
          [name]: value ? "" : errorMsgsForField[name]
        }));
      }      
  }

  const getVehicleDetails = () => {
    let allReq = Boolean(state.regNo)
    && Boolean(state.mileage);
    if(!isLoggedIn){
      allReq = allReq && Boolean(state.name)
      && Boolean(state.ph)
      && Boolean(state.email)
    }    
    if ( allReq) {
      if(isLoggedIn || state.privacy){
        let request = {}
        request['szVehicleNumber'] = state.regNo.replace(/\s+/g, '').trim();
        setLoader(true);
        checkVehicleIsRegistered(request)
          .then(res => {            
            if (res.data.success) {
              return (isLoggedIn ? getPriceAndAddVehicle : getPriceAndRegisterVahicle)(state).then(res => {
                const resData = {...res.data, requestLogin: true};
                  if (resData.success) {
                  const dataNode = resData.data
                  localStorage.setItem("carNo", state.regNo);
                  localStorage.setItem("_#homePageState#_", JSON.stringify(state));
                  localStorage.setItem("_#vehicleDetailsFetched#_", JSON.stringify(dataNode.vehicleDetails));
                  setVehicleDetails(dataNode.vehicleDetails);
                  if(!isLoggedIn){                    
                    localStorage.setItem("temp_token", dataNode.token || "");
                    localStorage.setItem("szFullName", dataNode.szFullName);
                    localStorage.setItem("_#sellerid#_", dataNode.sellerid);
                    
                    store.dispatch({
                      type: "ADD_TODO",
                      text: dataNode.szFullName,
                    });
                  }
                
                
                  localStorage.setItem(
                    "objVehicleDetails",
                    JSON.stringify(dataNode.objVehicleDetails) || ""
                  );
                  localStorage.setItem(
                    "idVehicleDetails",
                    dataNode.idVehicleDetails
                  );                
                  sendLinkFun();
                  if (!dataNode?.vehicleDetails?.guideprice) {
                    setErrorMsg("We are unable to get price of the supplied vehicle.");
                  }
                } else {
                  setErrorMsg(resData.message || 'Something went wrong.');
                }
              });
            }
          })
          .catch((ex) => {            
            console.error('Exception: ', ex);
            if(ex.httpStatusCode===409){
              setRequestLogin(true);
            }
            if (!ex.success) {
              setErrorMsg(ex.message || 'Something went wrong.');
            }
          })
          .finally(() => {          
            setLoader(false);
          })
      }
      else{
        setErrorMsg("Please accept privacy policy.");
      }
    }
    else {
      setFieldError();
    }    
  }

  const sendLinkFun = () => {
    let req = {};
      req['idVehicle'] = localStorage.getItem("idVehicleDetails");
    sendPhotoLink(req)
      .then((res) => {
        console.log("SEND LINK RESPONSE=====>>>>", res)
      })
  }

    
  const navigateToSignupOrDetails = () => {    
    localStorage.setItem('mileage', +state.mileage);
    if (isLoggedIn) {
      //registerVehicle();
      history.push(Path.carDetail,);
    } else {
      history.push(`${Path.sellerSignUp}${state.regNo}`);
    }
  }

  const handlePrivacyChange = ()=> {
    setState(st=>({...st, privacy: !st.privacy}))
  }

  
const handleFieldChange = e=> {
  let {name, value, type, valueAsNumber} = e.target;
  if(type==="number"){
    if(name==="mileage"){
      if (valueAsNumber > 999999) {
        valueAsNumber = 999999;
      }
      if (valueAsNumber < 0) {
        valueAsNumber = 0;
      }
    }
    setState(st=>({...st, [name]: valueAsNumber}))
    setFieldError(name, valueAsNumber);
  }else{
    setState(st=>({...st, [name]: value}))
    setFieldError(name, value);
  }
}
 

  const clearErrorMsg = navigateToLogin => () => {
    setErrorMsg(null);
    setRequestLogin(false);
    if(navigateToLogin){
      history.push(Path.login);
    }
  }

  return (
    <>
      {loader && <LoaderWrapper />}
      <div className={`cargo-home ${vehicleDetails ? 'cargo-next' : ''}`}>
        {/* <img className="homebg" src={HomeBg} alt="" /> */}
        <div className="registered-fold">
          <div className="registered-fold-inner">
            <div className="container">
              <div className="firstscreen">

              <img src={LHS} className="side-image" alt="Left Hand Side" />
              <img src={RHS} className="side-image" alt="RHS Hand Side" style={{ marginLeft: '425px' }} />

                <div className="value-content-wrapper">
                  <h1 className="value-heading">Value and sell your car</h1>
                  {/* <p className="tagline">You Could Get up to £1,000* More</p> */}
                  <div className="input-reg">
                    <div className="input-inner">
                      <InputCustom
                        type="text"
                        name="regNo"
                        className={`register-no ${error.regNo !== "" ? "input-error shake-email" : ""
                          }`}
                        placeholder="ENTER YOUR REG"
                        value={state.regNo}
                        onChange={handleFieldChange}
                        disable={!!vehicleDetails}
                        handleKeyPress={handleEnter}
                      />
                      {!!vehicleDetails && <span className="clear-n-restart" title="Clear and Restart" onClick={e => setVehicleDetails(null)}>&times;</span>}
                    </div>
                  </div>
                  <div className="input-normal">
                    <div className="input-normal-inner">
                      <InputCustom
                        type="number"
                        name="mileage"
                        className={`vehicle-mileage ${error.mileage !== "" ? "input-error shake-email" : ""}`}                        
                        placeholder="ENTER MILEAGE"
                        max={999999}                        
                        value={state.mileage}
                        onChange={handleFieldChange}
                        disable={!!vehicleDetails}
                      />
                    </div>
                  </div>
                  {!isLoggedIn &&<>
                    <div className="input-normal">
                      <div className="input-normal-inner">
                        <InputCustom
                          type="text"
                          name="name"
                          className={error.name !== "" ? "input-error shake-email" : ""}                        
                          value={state.name}
                          onChange={handleFieldChange}
                          placeholder="ENTER YOUR NAME"
                          disable={!!vehicleDetails}
                          handleKeyPress={handleEnter}
                        />
                      </div>
                    </div>
                    <div className="input-normal">
                      <div className="input-normal-inner">
                        <InputCustom
                          pattern="[0-9]"                        
                          name="ph"
                          value={state.ph}
                          className={error.ph !== "" ? "input-error shake-email" : ""}
                          placeholder="PHONE NUMBER"                        
                          onChange={handleFieldChange}
                          max={999999999999}                        
                          disable={!!vehicleDetails}
                        />
                      </div>
                    </div>
                    <div className="input-normal">
                      <div className="input-normal-inner">
                        <InputCustom
                          type="text"
                          name="email"                        
                          value={state.email}
                          onChange={handleFieldChange}
                          className={error.email !== "" ? "input-error shake-email" : ""}
                          placeholder="ENTER EMAIL"
                          disable={!!vehicleDetails}
                        />
                      </div>
                    </div>
                    <div className="input-checkbox">
                      <form>
                        <input type="checkbox" id="privacy" name="privacy" value="privacy" checked={state.privacy} onChange={handlePrivacyChange}/>
                        <label for="privacy"> I have read the Privacy Policy and accept the Terms & Conditions.</label><br />
                      </form>
                    </div>
                  </>}
                  {vehicleDetails && <>
                    <div className="pricedetail-new">
                      <ul>
                        <li><span>{vehicleDetails?.make}</span></li>
                        <li><span>.</span></li>
                        <li><span>{vehicleDetails?.yearOfManufacture}</span></li>
                        <li><span>.</span></li>
                        <li><span>{vehicleDetails?.colour}</span></li>
                        <li><span>.</span></li>
                        <li><span>{vehicleDetails?.fuelType}</span></li>

                      </ul>
                      <div className="priceshow">
                        <CurrencyFormat
                          value={vehicleDetails?.guideprice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"£"}
                        />
                        <p>Guide price based on your mileage</p>
                        <p className="title"> <strong>Enter your details to submit to auction</strong> </p>
                      </div>
                    </div>
                  </>
                  }
                  {vehicleDetails
                    ? <Button className="primary ideal-button" onClick={navigateToSignupOrDetails}>
                      Enter Details <span>{'>>>'}</span>
                    </Button>
                    : <Button className="primary ideal-button" onClick={getVehicleDetails}>
                      Get Price <span>{'>>>'}</span>
                    </Button>}
                </div>

                <img src={BOTTOM} className="bottom-image" alt="bottom" />

              </div>
              <div className="bottom-contant">
                {/* <h2>
                  Selling Made Simple with CarGo
              </h2> */}
                <div className="offer-cargo">
                  <ul>
                    <li><span>{'>'}</span> Enter Reg</li>
                    <li><span>{'>'}</span> Free Valuation</li>
                    <li><span>{'>'}</span> Sell Your Car </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* vehicle already in aution */}
      {!!errorMsg && <Popup
        onClick={clearErrorMsg(false)}
        state={true}
        heading="Vehicle"
        btnText="Filter"
      >
        <h3>{errorMsg}</h3>
        <button className="btn primary" onClick={clearErrorMsg(requestLogin)}>
        {requestLogin ? 'Login' : 'OK'}
        </button>
      </Popup>
      }
    </>
  );
}

export default Home;
