import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { change, Field, reduxForm } from "redux-form";
import Path from "../../../../Constants/Routes";
import { scrollToTop } from "../../../../Helpers/Helpers";
import { getVehicleDetail } from "../../../../Services/getVehicleDetails";
import { sendPhotoLink } from "../../../../Services/sendPhotoLink";
import { updateVehicleDetails } from "../../../../Services/updateVehicleDetails";
import Button from "../../../Common/Button";
import CheckboxCircle from "../../../Common/CheckboxCircle";
import Input from "../../../Common/Input";
import LoaderWrapper from "../../../Common/Loader/Loader";
import Popup from "../../../Common/Popup/Popup";
import RadioCircle from "../../../Common/RadioCircle";
import SelectBox from "../../../Common/SelectBox/SelectBox";
import TextArea from "../../../Common/TextArea/TextArea";
import WrittenOff from "../../../Common/WrittenOff/WrittenOff";
import UploadImages from "../UploadImages/UploadImages";
import { VehicleCondition } from "./../../../../Data/stepsFiels";

const renderField = ({ field, ...rest }) => {
  const { szType, szOnKeypress } = field;
  if (szType === "text" || szType === "email" || szType === "number") {
    return (
      <Input
        {...rest}
        type={szType}
        onkeypress={szOnKeypress}
        placeholder={field.szPlaceholder}
        className={field.szClassName}
      />
    );
  } else if (szType === "date") {
    return (
      <Input
        {...rest}
        type={szType}
        placeholder={field.szPlaceholder}
        className={field.szClassName}
        max={moment().format("YYYY-MM-DD")}
      />
      // <h1>hello</h1>
    );
  } else if (szType === "checkbox") {
    return (
      <CheckboxCircle
        {...rest}
        name={field.szName}
        className={field.szClassName}
        children={field.szChildren}
        id={field.szId}
      />
      // <h1>hello</h1>
    );
  } else if (szType === "radio") {
    return (
      <RadioCircle
        {...rest}
        name={field.szName}
        className={field.szClassName}
        children={field.szChildren}
        id={field.szId}
        idValue={field.szValue}
      />
    );
  } else if (szType === "select") {
    const { options } = field;
    return (
      <SelectBox
        {...rest}
        name={field.szName}
        type={field.szType}
        className={field.szClassName}
        options={field.arOptions}
      />
    );
  } else if (szType === "textarea") {
    const { options } = field;
    return (
      <TextArea
        {...rest}
        className={field.szClassName}
        placeholder={field.szPlaceholder}
        name={field.szName}
      />
    );
  } else {
    return <div>Type not supported.</div>;
  }
};

function Index(props) {
  const [isOpen, setOpen] = useState(false);
  const [saveLaterPopup, setSaveLaterPopup] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const idVehicle = new URLSearchParams(search).get("id");
  const [copySuccess, setCopySuccess] = useState(false);
  const [url, setURL] = useState("");
  const [loader, setLoader] = useState();
  const textAreaRef = useRef(null);
  const copyCodeToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    setCopySuccess(true);
  };

  const vehicleDetails = () => {
    if (idVehicle) {
      getVehicleDetail(idVehicle).then((res) => {
        let data = res?.data?.data;
        localStorage.setItem("idVehicleDetails", idVehicle);
        localStorage.setItem("objVehicleDetails", JSON.stringify(data));
      });
    }
  };
  useEffect(() => {
    vehicleDetails();
  }, []);

  useMemo(() => {
    let stepObj = VehicleCondition[props?.formValues?.iStep - 1]?.data;
    if (stepObj) {
      for (let qstObj of stepObj) {
        if (qstObj["szFieldType"] == "radio") {
          let szValue = props?.formValues[qstObj["fields"][0]?.szName];
          let childArr = stepObj.filter(O => O.szParentName == qstObj["fields"][0].szName);          

          if (childArr) {
            let selectedQsFieldSzName = [];
             childArr.filter(c=>c.szParentValue == szValue).forEach(q=>q.fields?.forEach(cqf=>selectedQsFieldSzName.push(cqf.szName)));
            for (let childObj of childArr) {
              if (szValue != childObj.szParentValue) {
                if (childObj && childObj?.fields) {
                  for (let levelChildObj of childObj.fields) {                    
                    selectedQsFieldSzName.indexOf(levelChildObj.szName)===-1 && props.dispatch(change("stepForm", levelChildObj.szName, null));
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [props]);

  useEffect(() => {
    scrollToTop();
  }, [props?.formValues?.iStep]);

  const next = () => {
    setLoader(true);
    if (props?.formValues?.iStep < VehicleCondition.length) {
      props.dispatch(change("stepForm", "iStep", +(props?.formValues?.iStep||'0') + 1));
      const idVehicleDetails = localStorage.getItem("idVehicleDetails");
      props.formValues["idVehicleDetails"] = idVehicleDetails;
      let req = props?.formValues;
      req.idVehicleDetails = +req.idVehicleDetails;
      return updateVehicleDetails(req)
        .then((res) => {
          console.log("resresres", res);
          if (res.status === 200) {
            getVehicleDetail(idVehicleDetails)
              .then((res) => {
                let data = res?.data?.data;
                localStorage.setItem("objVehicleDetails", JSON.stringify(data));
                // var elmnt = document.getElementById("content");
                // elmnt?.scrollIntoView(false);
                setLoader(false);
              })
              .catch((err) => {
                // this.props.hideLoader();
                setLoader(false);
              });
          } else {
            console.log(res);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
    
  };

  const prev = () => {
    if (props?.formValues?.iStep > 1) {
      props.dispatch(change("stepForm", "iStep", props?.formValues?.iStep - 1));
    }
  };

    const sendLinkFun = () => {
    let req = {};
    req['idVehicle'] = localStorage.getItem("idVehicleDetails");
    sendPhotoLink(req)
      .then((res) => {
        console.log("SEND LINK RESPONSE=====>>>>", res)
      })
  }

  const handleSaveLaterPopup = () => {
    setSaveLaterPopup(false);
    history.push(Path.home);
  };

  const continueForm = () => {
    setSaveLaterPopup(false);
  };

  // const copyCodeToClipboard = (input) => {
  //   const el = input;
  //   el.select();
  //   document.execCommand("copy");
  //   this.setState({ copySuccess: true });
  // };
  const saveForLater = () => {
    let req = props?.formValues;
    let idVehicleDetails = localStorage.getItem("idVehicleDetails");
    req.idVehicleDetails = idVehicleDetails;
    // req.iStep = req.iStep+1
    return updateVehicleDetails(req)
      .then((res) => {
        if (res.status === 200) {
          getVehicleDetail(idVehicleDetails)
            .then((res) => {
              let data = res?.data?.data;
              localStorage.setItem("objVehicleDetails", JSON.stringify(data));
              const vechileID = localStorage.getItem("idVehicleDetails");
              setSaveLaterPopup(true);
              setURL(`${window.location.href}?id=${vechileID}`);
            })
            .catch((err) => {
              // this.props.hideLoader();
            });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderFormFields = (element) => {
    return (
      <div className="col-100">
        <div
          className={`form-field ${props?.formValues?.bWrittenOff == "1" &&
              element.question == "Has your car been written off at any time?"
              ? "Form__writeOffZIndex"
              : ""
            }`}
        >
          <h3 className="field-label">{element.question}</h3>
          <ul className="list-style-none list-items">
            {element?.szFieldType == "radio" && !element?.szListRadio ? (
              <div className="d-flex radio-button-wrapper width-100">
                {element.fields.map((formItem, index) => (
                  <Field
                    // name={formItem.szName}
                    component={renderField}
                    field={formItem}
                    name={formItem.szName}
                  />
                ))}
              </div>
            ) : element?.szFieldType == "radio" &&
              element?.szListRadio == "list" ? (
              <ul className="list-style-none radio-button-wrapper width-100">
                {element.fields.map((formItem, index) => (
                  <li className="item">
                    <Field
                      // name={formItem.szName}
                      component={renderField}
                      field={formItem}
                      name={formItem.szName}
                    />
                  </li>
                ))}
              </ul>
            ) : element?.szFieldType == "checkbox" ? (
              <li className="item">
                {element.fields.map((formItem, index) => (
                  <Field
                    // name={formItem.szName}
                    component={renderField}
                    field={formItem}
                    name={formItem.szName}
                  />
                ))}
              </li>
            ) : element?.szFieldType == "component" ? (
              <WrittenOff />
            ) : (
              <>
                {element.fields.map((formItem, index) => (
                  <Field
                    // name={formItem.szName}
                    component={renderField}
                    field={formItem}
                    name={formItem.szName}
                  />
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      {loader && <LoaderWrapper />}
      <ul className="steps list-style-none d-flex">
        {VehicleCondition.map((item, index) => (
          <>
            <li
              className={`step ${item.step <= props?.formValues?.iStep ? "step-fill" : ""
                }`}
            >
              <span className="step-caption">
                {item?.step} {props?.formValues?.step}
              </span>
              <span className="arrowcls-basic">{'>'}</span>
            </li>
          </>
        ))}
      </ul>
      <div className="basic-details-wrapper" id="content">
        <div
          className={`steps-form-details ${props?.formValues?.iStep == 5 ? "step-five" : ""
            }`}
        >
          <div className="form-wrapper">
            <Field name={"iStep"} component={Input} type="hidden" />
            <div className="d-block custom-margin">
              {VehicleCondition?.map((item, index) => (
                <>
                  {item.step == props?.formValues?.iStep ? (
                    <h3 className="heading">
                      <span>{item?.szStepHeading}</span>
                    </h3>
                  ) : (
                    ""
                  )}

                  {item?.szStepType == "component" &&
                    props?.formValues?.iStep == 5 ? (
                    <UploadImages sendLinkFun={sendLinkFun} />
                  ) : (
                    <>
                      {item.step == props?.formValues?.iStep
                        ? item.data.map((element, index) => (
                          // renderFormFields(element)
                          <>
                            {element?.szParentName &&
                              props.formValues &&
                              props.formValues[element?.szParentName] ==
                              element.szParentValue &&
                              renderFormFields(element)}
                            {!element?.szParentName &&
                              renderFormFields(element)}
                          </>
                        ))
                        : ""}
                    </>
                  )}
                </>
              ))}
            </div>
            {props?.formValues?.bWrittenOff == "1" && (
              <div className="Form__writeOffOverlay"></div>
            )}
          </div>
        </div>

        <div className="stepper-button-wrapper">
          <div className="width-100">
            {/* <Button
            className="primary form-width next"
            // disabled={invalidForms[step]}
            onClick={
              props?.formValues?.iStep < VehicleCondition.length
                ? next
                : history.push(Path.reviewDetails)
            }
          >
            {props?.formValues?.iStep < VehicleCondition.length ? "Next" : "finish"}
          </Button> */}
            {props?.formValues?.iStep < VehicleCondition.length && (
              <Button
                className="primary form-width next"
                // disabled={invalidForms[step]}
                onClick={next}
              >
                {/* {step == 4 ? "Submit" : "Next Step"} */}
              Next
              </Button>
            )}
          </div>
          {props?.formValues?.iStep == VehicleCondition.length && (
            <div className="width-100">
              <Link
                className="btn primary form-width next"
                to={Path.reviewDetails}
              >
                finish
            </Link>
            </div>
          )}
          <div className="preview-finish">
            {props?.formValues?.iStep > 1 && (
              <div className="col-50">
                <Button onClick={prev} className="primary-invert preview">
                  Previous
              </Button>
              </div>
            )}

            <div
              className={`width-${props?.formValues?.iStep > 1 ? "50" : "100"}`}
            >
              <Button className="primary-invert finish" onClick={saveForLater}>
                Save & finish later
            </Button>
            </div>
          </div>
        </div>
      </div>

      {/* save later popup */}
      <Popup
        onClick={handleSaveLaterPopup}
        continue={continueForm}
        state={saveLaterPopup}
        heading="Progress saved"
        btnText="Filter"
        continueText="CONTINUE"
      >
        <p>We have saved the details about your vehicle.</p>
        <p style={{ marginBottom: "15px" }}>
          You can continue at any time by visiting:
        </p>
        <input
          type="text"
          className="input"
          onClick={copyCodeToClipboard}
          ref={textAreaRef}
          value={url}
          style={{ width: "500px" }}
        />
        {copySuccess ? (
          <div style={{ color: "green" }}>Copied</div>
        ) : (
          <div style={{ color: "#3292b3" }}>Copy URL</div>
        )}
        <button className="btn primary" onClick={handleSaveLaterPopup}>
          SAVE AND COME BACK LATER
        </button>
      </Popup>
    </>
  );
}

// {
//   element?.szParentName && props.formValues && props.formValues[element?.szParentName]==element.szParentValue  && (
//     <h2>hello</h2>
//   )
//   !element?.szParentName && (
//     <h2>hello</h2>
//   )
// }

Index = reduxForm({
  // a unique name for the form
  form: "stepForm",
  enableReinitialize: true,
  destroyOnUnmount: false,
  // validate,
})(Index);

export default connect((state) => {
  const formValues = state?.form["stepForm"]?.values;
  return {
    formValues,
  };
})(Index);
