import React from 'react';
import { Link } from 'react-router-dom';
import Path from '../../Constants/Routes';
import CallIcon from './../../Assets/Icons/call.png';
import LocationIcon from './../../Assets/Icons/location.png';
import MailIcon from './../../Assets/Icons/mail-icon.png';
import FooterCar from './../../Assets/Images/footercar.png';
import LogoFooter from './../../Assets/Images/logo-footer.png';
import { ReactComponent as PhoneFt } from '../../Assets/Icons/phoneft.svg';
import { ReactComponent as MailFt } from '../../Assets/Icons/mailft.svg';
import { ReactComponent as MapFt } from '../../Assets/Icons/mapft.svg';
import { ReactComponent as FacebookSquare } from './../../Assets/Icons/facebook-square.svg';
import { ReactComponent as TwitterSquare } from './../../Assets/Icons/twitter-square.svg';
import { ReactComponent as LinkedinSquare } from './../../Assets/Icons/linkedin-square.svg';
import { ReactComponent as InstagramSquare } from './../../Assets/Icons/instagram-square.svg';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import './Footer.scss';

const date = new Date().getFullYear()

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="dealerlogin">
                    <div className="container">
                        <div className="dealerlogin-inner">
                            <li className="menu-item">
                                <a target="_blank" href="https://dealer.letthecargo.co.uk/login" className="item">
                                    Dealer Login
                                </a>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="footer-md">
                    <div className="container">
                        <div className="row">
                            <div className="cg-sm-2 footer-one">
                                <Link className="footerlogo" target="_blank" to="/">
                                    Let The CarGo
                                </Link>
                            </div>
                            <div className="cg-sm-8 footer-two">
                                <ul>
                                    <li>
                                        <PhoneFt className="fticon" />
                                        <span>
                                            <a href="tel:0113 518 6900">0113 518 6900</a>
                                        </span>
                                    </li>
                                    <li>
                                        <MailFt className="fticon" />
                                        <span>
                                            <a href="mailto:sell@letthecargo.co.uk">sell@letthecargo.co.uk</a>
                                        </span>
                                    </li>
                                    <li>
                                        <MapFt className="fticon" />
                                        <span>Low Hall Business Park, Horsforth, Leeds, LS18 4EG</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="cg-sm-2 footer-three">
                                <div className="cargo-socal-media">
                                    <div className="cargo-sm-inner">
                                        <div className="socal-media">
                                            <ul>
                                                <li>
                                                    <a title="Facebook" href="#">
                                                        <FacebookSquare />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a title="Twitter" href="#">
                                                        <TwitterSquare />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a title="Instagram" href="#">
                                                        <InstagramSquare />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bt">
                    <div className="container">
                        <ul>
                            <li>
                                <div className="copyright">©Let The CarGo {date}</div>
                            </li>
                            <li>
                                <Link to={Path.termsConditions}>Terms</Link>
                            </li>
                            <li>
                                <Link to={Path.cookiePolicy}>Cookies</Link>
                            </li>
                            <li>
                                <Link to={Path.privacyPolicy}>Privacy Policy</Link>
                            </li>
                            {/* <li>
        <Link target='_blank' to={Path.pricing}>Pricing</Link>
        </li> */}
                        </ul>
                    </div>
                </div>
                <div className="footer-bt-copyright-mobile">
                    <div className="copyright">©Let The CarGo {date}</div>
                </div>
                <CookieConsent
                    setDeclineCookie={false}
                    enableDeclineButton
                    buttonText="Accept"
                    declineButtonText="More Details"
                    onDecline={() => {
                        window.location.href = '/cookie';
                    }}
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
            </div>
        </>
    );
};

export default Footer;
