import React from "react";
import "./Input.scss";

const Input = ({
  input,
  type,
  className,
  placeholder,
  max,
 
  writeOnce,
  disabled,
  min=0,
  meta: { touched, error, warning },
  onkeypress
}) => {
  return (
    <>
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        max={max}
        min={min}
        disabled={disabled} 
        className={`input ${className} ${touched && error && "input-error"}`}
        onKeyPress={onkeypress}
      />
      {touched && error && <span className="error-custom">{error}</span>}
    </>
  );
};
export default Input;
