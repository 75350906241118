import { compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import { reducers } from "./Reducers";

let initialState = {
  // initial state
};

let logger = createLogger();

export const store = createStore(
  reducers,
  initialState,
  compose(
    // applyMiddleware(logger),
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f) => f
  )
);
// window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : f => f) )
