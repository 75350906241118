import { Redirect, Route } from "react-router";
import Path from "./Constants/Routes";
import { getToken } from "./Helpers/Helpers";

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => getToken() === true
                ? <Component {...props} />
                : <Redirect to={{ pathname:Path.home, state: { from: props.location } }} />}
        />
    )
}
export default PrivateRoute;