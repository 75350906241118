import React, { useEffect, useState } from "react";
import S3 from "react-aws-s3";
import "react-dropzone-uploader/dist/styles.css";
import { Link, useLocation } from "react-router-dom";
import Path from "../../../../Constants/Routes";
import { getVehicleDetail } from "../../../../Services/getVehicleDetails";
import { updateVehicleDetails } from "../../../../Services/updateVehicleDetails";
import SmatPhone from "./../../../../Assets/Images/smartphone.png";
import DropZoneArea from "./DropZoneArea";

const UploadImages = (props) => {
  const [sendMessage, setSendMessage] = useState(false);
  const [unableToUseSmartPhone, setUnableToUseSmartPhone] = useState(false);
  const [photoAlreadyUpload, setPhotoAlreadyUpload] = useState(false);
  const [uploadPhotos, setUploadPhotos] = useState(true);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const location = useLocation();

  // specify upload params and url for your files
  const config = {
    bucketName: "cargofront",
    // dirName: "media" /* optional */,
    region: "ap-south-1",
    accessKeyId: "AKIAUOI2YXDKSM7D3B6Z",
    secretAccessKey: "MF0+bCDgeR9yektULsOsd/i1t68GjbYa9eEoua6R",
    // s3Url: "https:/your-custom-s3-url.com/" /* optional */,
  };

  const ReactS3Client = new S3(config);
  /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

  /* This is optional */
  const newFileName = "test-file";

  const uploadss = () => {
    console.log("heloooooooo");
    ReactS3Client.uploadFile(droppedFiles, newFileName)
      .then((data) => console.log("check data ==================", data))
      .catch((err) => console.error("error===========", err));
  };

  /**
   * {
   *   Response: {
   *     bucket: "myBucket",
   *     key: "image/test-image.jpg",
   *     location: "https://myBucket.s3.amazonaws.com/media/test-file.jpg"
   *   }
   * }
   */
  // })
  // called every time a file's `status` changes
  const handleDrop = (files) => {
    const reader = new FileReader();
    // reader.onabort = () => console.log('file reading was aborted')
    // reader.onerror = () => console.log('file reading has failed')
    reader.onload = () =>
      // Do whatever you want with the file contents
      reader.result;

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setDroppedFiles(
      droppedFiles.concat(files)
      // function () {
      //   // this.handleFileValidation();
      //   if (droppedFiles.length > 0) {
      //     this.setState({ disablePostButton: false });
      //   }
      // }
    );
    setTimeout(() => {
      uploadss();
    }, 1000);
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };
  const showUploadImages = () => {
    setUploadPhotos(true);
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  };

  const getVehicleDetails = () => {
    // const search = location.search;    
    const idVehicleStored = new URLSearchParams(location.search).get("id") || localStorage.getItem("idVehicleDetails");    
    console.log("id-------------", idVehicleStored);
    // const idVehicle = new URLSearchParams(search).get("id");
    getVehicleDetail(idVehicleStored)
      .then((res) => {
        let data = res?.data?.data;
        setVehicleDetails(data || {});
      })
      .catch((err) => {
        // this.props.hideLoader();
      });
  };

  useEffect(() => {
    getVehicleDetails();
  }, []);

  const uploadImage = async file => {
    const formData = new FormData();
    formData.append('file', file);
    const files   = new File([file], "abc",{type: "image/png"});
    const req = {};
    const idVehicleStored = localStorage.getItem("idVehicleDetails");
    req.idVehicleDetails = idVehicleStored;
    
    console.log("file=======MMMMMMM====?>>>>",files)
    // Connect to a seaweedfs instance
     
    ReactS3Client.uploadFile(files)
      .then((data) => {
        console.log("check data ==================", data);
        req.szExteriorFrontDriverSide = data.key;
        updateFiles(req);
      })
      .catch((err) => console.error("error===========", err));
      
  };

  const updateFiles = (req) => {
    req.idVehicleDetails = +req.idVehicleDetails;
    updateVehicleDetails(req)
      .then((res) => {
        if (res.status === 200) {
           
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="step-5 mx-auto">
      <h3 className="heading">
        <span>Vehicle Photos</span>
      </h3>
      <h4 style={{"background": "red", "color": "white", "padding": 10, "borderRadius": 3}}>
        Please take/upload photos taken in the landscape mode
      </h4>
      {!uploadPhotos ? (
        <>
          <p className="subtitle">
            The final step is to add photos of your vehicle. This only takes a
            few minutes and enables us to get a guaranteed offer for your
            vehicle.
            
          </p>

          <div className="msg-sent">
            <h3 className="head">Check Your Phone!</h3>
            <p className="title">
              We have just sent a message to <span>3012698015</span> with a link
              to take photos. Open the message to continue.
            </p>
          </div>
          <div className="smartphone">
            <img
              src={SmatPhone}
              className="smartphone-image"
              alt="smartphone"
            />

            <div className="car-detail-photo-info">
              <div className="accordion-wrapper">
                <div className="accordion-items">
                  <div
                    className={`accordion-title ${sendMessage ? "open" : ""}`}
                    onClick={() => setSendMessage(!sendMessage)}
                  >
                    <h2 className="head">Didn't receive message?</h2>
                  </div>
                  <div
                    className={`accordion-item ${
                      !sendMessage ? "collapsed" : ""
                    }`}
                  >
                    <div className="accordion-content">
                      <p>
                      The final step is to add photos of your vehicle. This only takes a few
                  minutes and enables us to get a guaranteed offer for your vehicle.
                      </p>
                    
                      <div className="cm-dt-btn">
                        <div className="item" onClick={props.sendLinkFun}>
                          <span>Resend link to my phone</span>
                        </div>
                      </div>
                      {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's
                        <span className="activelink">
                          <Link className="item" to={Path}>
                            <span>96584215</span>
                          </Link>
                        </span>
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="accordion-items">
                  <div
                    className={`accordion-title ${
                      unableToUseSmartPhone ? "open" : ""
                    }`}
                    onClick={() =>
                      setUnableToUseSmartPhone(!unableToUseSmartPhone)
                    }
                  >
                    <h2 className="head">Unable to use a smartphone?</h2>
                  </div>
                  <div
                    className={`accordion-item ${
                      !unableToUseSmartPhone ? "collapsed" : ""
                    }`}
                  >
                    <div className="accordion-content">
                     
                      <p>
                      You may upload photos from your desktop/laptop if you have not got access to a mobile device. Please click
                        <span
                          className="item cursor-pointer"
                          onClick={showUploadImages}
                        >
                          here
                        </span> to upload photos.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="accordion-items">
                  <div
                    className={`accordion-title ${
                      photoAlreadyUpload ? "open" : ""
                    }`}
                    onClick={() => setPhotoAlreadyUpload(!photoAlreadyUpload)}
                  >
                    <h2 className="head">Click here to upload photos if you have already taken.</h2>
                  </div>
                  <div
                    className={`accordion-item ${
                      !photoAlreadyUpload ? "collapsed" : ""
                    }`}
                  >
                    <div className="accordion-content">
                    <p>
                      You may upload photos from your desktop/laptop if you have not got access to a mobile device. Please click
                        <span
                          className="item cursor-pointer"
                          onClick={showUploadImages}
                        >
                          here
                        </span> to upload photos.
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="imageUpload__component">
          <div className="imageUpload__sectionHeader">
            <h4>Exterior</h4>
            <div>
              <p>Ensure the whole of the car is visible in each photo.</p>
              <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
                
              </div>
            </div>
            <ul className="imageUpload__image_section">
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szExteriorFrontDriverSide"]}
                  name={"szExteriorFrontDriverSide"}
                  title1={"Front"}
                  title2={"Driver Side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szExteriorBackDriverSide"]}
                  name={"szExteriorBackDriverSide"}
                  title1={"Back"}
                  title2={"Driver side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szExteriorFrontPassengerSide"]}
                  name={"szExteriorFrontPassengerSide"}
                  title1={"Front"}
                  title2={"Passenger side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szExteriorBackPassengerSide"]}
                  name={"szExteriorBackPassengerSide"}
                  title1={"Back"}
                  title2={"Passenger side"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <h4>Interior</h4>
            <div>
              <p>Ensure the whole of the car is visible in each photo.</p>
              <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
              </div>
            </div>
            <ul className="imageUpload__image_section">
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szInteriorFrontSeats"]}
                  name={"szInteriorFrontSeats"}
                  title1={"Front Seat"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szInteriorRearSeats"]}
                  name={"szInteriorRearSeats"}
                  title1={"Rear Seat"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szInteriorDashboard"]}
                  name={"szInteriorDashboard"}
                  title1={"Dashboard"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szInteriorBoot"]}
                  name={"szInteriorBoot"}
                  title1={"Boot Interior"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <h4>Wheels</h4>
            <div>
              <p>Ensure the whole of the car is visible in each photo.</p>
              <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
              </div>
            </div>
            <ul className="imageUpload__image_section">
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szWheelsFrontDriverSide"]}
                  name={"szWheelsFrontDriverSide"}
                  title1={"Front"}
                  title2={"Driver Side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szWheelsBackDriverSide"]}
                  name={"szWheelsBackDriverSide"}
                  title1={"Back"}
                  title2={"Driver side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szWheelsFrontPassengerSide"]}
                  name={"szWheelsFrontPassengerSide"}
                  title1={"Front"}
                  title2={"Passenger side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szWheelsBackPassengerSide"]}
                  name={"szWheelsBackPassengerSide"}
                  title1={"Back"}
                  title2={"Passenger side"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <h4>Tyre Treads</h4>
            <div>
              <p>Ensure the whole of the car is visible in each photo.</p>
              <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
              </div>
            </div>
            <ul className="imageUpload__image_section">
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szTyreFrontDriverSide"]}
                  name={"szTyreFrontDriverSide"}
                  title1={"Front"}
                  title2={"Driver Side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szTyreBackDriverSide"]}
                  name={"szTyreBackDriverSide"}
                  title1={"Back"}
                  title2={"Driver side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szTyreFrontPassengerSide"]}
                  name={"szTyreFrontPassengerSide"}
                  title1={"Front"}
                  title2={"Passenger side"}
                />
              </li>
              <li>
                <DropZoneArea
                  urls={vehicleDetails["szTyreBackPassengerSide"]}
                  name={"szTyreBackPassengerSide"}
                  title1={"Back"}
                  title2={"Passenger side"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <h4>Condition Photos</h4>
            <div>
              <p>Ensure the whole of the car is visible in each photo.</p>
              <div className="Helper__component undefined Helper__marginLess ImageUpload__helper">
                {/* <a href="#help" className="mw-link mw-link-small mw-link-blue">
                  See examples
                </a> */}
              </div>
            </div>
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionScratchesAndScuffs"] &&
                    JSON.parse(vehicleDetails["szConditionScratchesAndScuffs"])
                  }
                  multiple={true}
                  name={"szConditionScratchesAndScuffs"}
                  title1={"Scratches and Scuffs"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionDents"] &&
                    JSON.parse(vehicleDetails["szConditionDents"])
                  }
                  multiple={true}
                  name={"szConditionDents"}
                  title1={"Dents"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionPaintworkProblems"] &&
                    JSON.parse(vehicleDetails["szConditionPaintworkProblems"])
                  }
                  multiple={true}
                  name={"szConditionPaintworkProblems"}
                  title1={"Paintwork Problems"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionMissingTrims"] &&
                    JSON.parse(vehicleDetails["szConditionMissingTrims"])
                  }
                  multiple={true}
                  name={"szConditionMissingTrims"}
                  title1={"Missing Trims"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionWarningLights"] &&
                    JSON.parse(vehicleDetails["szConditionWarningLights"])
                  }
                  multiple={true}
                  name={"szConditionWarningLights"}
                  title1={"Warning Lights"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionWindscreenProblems"] &&
                    JSON.parse(vehicleDetails["szConditionWindscreenProblems"])
                  }
                  multiple={true}
                  name={"szConditionWindscreenProblems"}
                  title1={"Windscreen Problems"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionAlloyScuffs"] &&
                    JSON.parse(vehicleDetails["szConditionAlloyScuffs"])
                  }
                  multiple={true}
                  name={"szConditionAlloyScuffs"}
                  title1={"Alloy Scuffs"}
                />
              </li>
            </ul>
          </div>

          <div className="imageUpload__sectionHeader">
            <ul className="imageUpload__image_section">
              <li className="multi-images">
                <DropZoneArea
                  urls={
                    vehicleDetails["szConditionTypeProblems"] &&
                    JSON.parse(vehicleDetails["szConditionTypeProblems"])
                  }
                  multiple={true}
                  name={"szConditionTypeProblems"}
                  title1={"Type Problems"}
                />
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadImages;
