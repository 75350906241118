import React, { useEffect } from 'react';
import review2 from '../../Assets/Images/Profile_Pci.png';
import CommonBanner from '../../Components/Common/CommonBanner/CommonBanner';
import './AboutUs.scss';

const AboutUs = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }, []);
    return (
        <div className="about-us">
            <div className="container-about custom-scrollbar">
                <h1 className="heading">About Us</h1>
                <p className="text">
                    Let The CarGo provides a platform for sellers to realise their true worth. We’re creating a new kind of approach, laying a foundation for
                    security and balance, ensuring the right deal for all involved.
                </p>
                <p className="text">
                    Times are changing, and CarGo’s investment in technology is now giving you maximum power to get the very best out of your assets. You no
                    longer need to wonder if you’re getting a fair price in a straight sale or an honest value in a part-exchange. Our platform levels the
                    playing field, creating parity between you and the dealer.
                </p>
                <p className="text text-center">
                    CarGo’s development of next generation applications sees our approved dealer network compete to acquire the finest low-mileage stock. We
                    choose carefully, and all our partners have a proven track record of paying a premium for the highest quality. Simply enter your vehicle
                    into our system with just a few clicks and then relax while we run our daily online auctions, bypassing the hassle of a regular trade.
                </p>
                <p className="text text-center">
                    Backed by experience and youthful innovation, the Let The CarGo team reflects the modern age of diversity and cooperation. With a strong
                    tradition in buying, selling and financing prestige vehicles, we have a keen perception of the motor industry and an appreciation of the
                    difficulty in navigating the market as a private seller. And, as enthusiasts ourselves, we recognise the constant need for improved, faster
                    and more convenient methods to fit seamlessly into the life of today.
                </p>
                {/* <div className="ourteam">
          <h2 className="text-center heading">
            <span>Meet the team</span>
          </h2>
          <p className="text text-center">          
                Duis nunc nunc, efficitur ac euismod ultricies,<br></br> 
                viverra dignissim risus. In hac habitasse platea dictumst.<br></br>  
                In placerat tempor odio, sit amet viverra luctus eget.             
          </p>
          <div className="ourteamlist">
            <ul>
              <li>
                <div className="ourteam-wrapper">
                  <div className="team-img">
                    <img
                      className="company-team"
                      src={review2}
                      alt="companies team"
                    />
                  </div>
                  <div className="team-name">
                    <span className="littlename">Jack Smith</span>
                  </div>
                  <div className="team-title">
                    <span className="largtitle">Director</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="ourteam-wrapper">
                  <div className="team-img">
                    <img
                      className="company-team"
                      src={review2}
                      alt="companies team"
                    />
                  </div>
                  <div className="team-name">
                    <span className="littlename">Oliver Rayner</span>
                  </div>
                  <div className="team-title">
                    <span className="largtitle">Director</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="ourteam-wrapper">
                  <div className="team-img">
                    <img
                      className="company-team"
                      src={review2}
                      alt="companies team"
                    />
                  </div>
                  <div className="team-name">
                    <span className="littlename">Doug Smith</span>
                  </div>
                  <div className="team-title">
                    <span className="largtitle">Chairman</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> */}
                <div className="ourteam">
                    {/* <h2 className="text-center heading">
            <span>Highlight Unique Selling Point</span>
          </h2> */}
                    {/* <p className="text text-center">
            <strong>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </strong>
          </p> */}
                    <div className="selling-point">
                        <p className="text">Selling made simple.</p>
                        <p className="text">hassle free</p>
                        <p className="text">handheld service for both buyer and seller.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
