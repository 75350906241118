// export const validate = (values) => {
//   const errors = {};
//   if (!values.szMileage) {
//     errors.szMileage = "Required";
//   }
//   if (!values.szFullName) {
//     errors.szMileage = "Required";
//     // errors.firstName = "Please Enter Fullname";
//   }
//   if (!values.szEmail) {
//     errors.szEmail = "Required";
//   } else if (
//     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.szEmail)
//   ) {
//     errors.szEmail = "Invalid email address";
//   }

//   if (!values.szPostCode) {
//     errors.szPostCode = "Required";
//   } else if (values.szPostCode < 0) {
//     return (values.szPostCode = 0);
//   } else {
//     return values.szPostCode;
//   }

//   // !/^(0|[1-9][0-9]{9})$/i.test(value);
//   // if (!values.age) {
//   //   errors.age = 'Required'
//   // } else if (isNaN(Number(values.age))) {
//   //   errors.age = 'Must be a number'
//   // } else if (Number(values.age) < 18) {
//   //   errors.age = 'Sorry, you must be at least 18 years old'
//   // }
//   return errors;
// };

// const normalizePostCode = (value) => {
//   // if (value < 0) {
//   //   return 0;
//   // } else {
//   //   return value;
//   // }
// };

// const normalizePhone = (value, previousValue) => {
//   if (!value) {
//     return value;
//   }
//   // const onlyNums = value.replace(/[^\d]/g, "");
//   // if (!previousValue || value.length > previousValue.length) {
//   //   // typing forward
//   //   if (onlyNums.length === 3) {
//   //     return onlyNums + "-";
//   //   }
//   //   if (onlyNums.length === 6) {
//   //     return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3) + "-";
//   //   }
//   // }
//   // if (onlyNums.length <= 3) {
//   //   return onlyNums;
//   // }
//   // if (onlyNums.length <= 6) {
//   //   return onlyNums.slice(0, 3) + "-" + onlyNums.slice(3);
//   // }
//   // return (
//   //   onlyNums.slice(0, 3) +
//   //   "-" +
//   //   onlyNums.slice(3, 6) +
//   //   "-" +
//   //   onlyNums.slice(6, 10)
//   // );
// };

// const required = (value) => (value ? undefined : "Required");

// export { normalizePhone, normalizePostCode, required };

const required = (value) => (value ? undefined : "Required");
const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
const maxLength15 = maxLength(15);
const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
const minValue18 = minValue(18);
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
const tooOld = (value) =>
  value && value > 65 ? "You might be too old for this" : undefined;
const aol = (value) =>
  value && /.+@aol\.com/.test(value)
    ? "Really? You still use AOL for your email?"
    : undefined;

export {
  required,
  maxLength,
  maxLength15,
  number,
  minValue,
  minValue18,
  email,
  tooOld,
  aol,
};
