import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import CommonBanner from "../../Components/Common/CommonBanner/CommonBanner";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import { getVehicleDetail } from "./../../Services/getVehicleDetails";
import ImageCard from "./ImageCard";
import ProgressCard from "./ProgressCard";
import { s3URL } from "../../Constants/constants";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Swiper from 'react-id-swiper';
import IMGmedium from "../../Assets/Images/IMG_9056-medium.jpg";
import IMGmedium2 from "../../Assets/Images/IMG_9057-medium.jpg";
import IMGmedium3 from "../../Assets/Images/IMG_9058-medium.jpg";
import IMGmedium4 from "../../Assets/Images/IMG_9059-medium.jpg";
import IMGmedium5 from "../../Assets/Images/IMG_9063-medium.jpg";

import 'swiper/swiper.scss';
import "./VehicleDetail.scss";
import CurrencyFormat from "react-currency-format";

const VeshicleDetail = (props) => {
  let { slug } = useParams();
  const history = useHistory();
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toggle, setToggle] = useState(true);
 

  const [toggleName, setToggleName] = useState(`t1`);
  const toggleupdate = (name) => {

    if(toggleName==name){
        setToggle(!toggle)
    }else{
        setToggle(toggle)
    }
    setToggleName(name)
  };

  

  // Image Slider 
  const swiperRef = useRef(null);
  const params = {
    slidesPerView: 3,
    spaceBetween: 4,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 4
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 4
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      }
    },
  }
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getVehicleDetailFun(slug);
  }, []); 

  // @Vehicle details
  const getVehicleDetailFun = (slug) => {
    setLoader(true);
    getVehicleDetail(slug)
      .then(async (res) => {
        setVehicleDetail(res?.data?.data);
        setLoader(false);
        console.log(
          "vehicleDetailvehicleDetail",
          res?.data?.data,
          vehicleDetail
        );
        await setShowDetails(true);
      })
      .catch((err) => {
        // this.props.hideLoader();
        console.log(err);
        setLoader(false);
      });
  };

  if (loader) {
    return <LoaderWrapper />;
  }

  return showDetails ? (
    <div className="vehicle-detail">
    <CommonBanner>
        <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
          <h1 className="car-name">Vehicle Details</h1>
        </div>
      </CommonBanner>
     
      <div className="vehicle-detail-body">
        <div className="car-detail-column-left">
          <label className="title">{vehicleDetail?.szVehicleNumber}</label>
          <h2 className="heading">
            <span>
              {JSON.parse(vehicleDetail?.szVehicleDetails)?.model
                ? JSON.parse(vehicleDetail?.szVehicleDetails)?.model
                : JSON.parse(vehicleDetail?.szVehicleDetails)?.make}
            </span>
          </h2>
          {/* <div className="car-info">
            <span className="info">{vehicleDetail?.szCarColour}</span>
          </div> */}
          <ul className="feature width-100 list-style-none">
                      <li>
                        {
                          JSON.parse(vehicleDetail?.szVehicleDetails)
                            ?.yearOfManufacture
                        }
                      </li>
                      <li>{vehicleDetail?.szMileage} miles</li>
                      <li>{JSON.parse(vehicleDetail?.szVehicleDetails)?.fuelType}</li>
                      <li>{JSON.parse(vehicleDetail?.szVehicleDetails)?.colour} </li>
                    </ul>
          <ul className="car-feature list-style-none">
            {vehicleDetail.szPatNav ? <li>Sat nav</li> : ""}
            {vehicleDetail.szPanoramicRoof ? <li>Panoramic roof</li> : ""}
            {vehicleDetail.szHeatedSeats ? <li>Heated seats</li> : ""}
            {vehicleDetail.szParkingCamera ? <li>Parking cam</li> : ""}
            {vehicleDetail.szUpgradedSoundSystem ? (
              <li>Upgraded sound system</li>
            ) : (
              ""
            )}
          </ul>

          <div className="specification">
          <div className={`specification-tabs ${toggle && toggleName=="t1"? 'open': `` }`}>
<h4 className="head" onClick={() => toggleupdate("t1")}>
            Specification:
            </h4>
            <div className="tabletab">
            <table className="table">
              <tr>
                <td>Colour of car:{" "}</td>
                <th><span className="code">{vehicleDetail.szCarColour}</span></th>
              </tr>
              <tr>
                <td>Upholstered:</td>
                <th><span className="code">{vehicleDetail.szUpholstered}</span></th>
              </tr>
              <tr>
                <td>Seats Colour:</td>
                <th><span className="code">{vehicleDetail.szSeatsColor}</span></th>
              </tr>
              <tr>
                <td>Fuel type:</td>
                <th><span className="code">{JSON.parse(vehicleDetail.szVehicleDetails)?.fuelType}</span></th>
              </tr>
              <tr>
                <td>Engine Capacity:</td>
                <th><span className="code">{JSON.parse(vehicleDetail.szVehicleDetails)?.engineCapacity}</span></th>
              </tr>
              </table>
              </div>
              </div>
              <div className={`specification-tabs ${toggle && toggleName=="t2"? 'open': `` }`}>
<h4 className="head" onClick={() => toggleupdate("t2")}>
            Documentation:
            </h4>
            <div className="tabletab">
            <table className="table">
              <tr>
                <td>Registration Number:{" "}</td>
                <th><span className="code">{vehicleDetail.szVehicleNumber}</span></th>
              </tr>
              
              <tr>
                <td>First registered:</td>
                <th><span className="code">{JSON.parse(vehicleDetail.szVehicleDetails)?.monthOfFirstRegistration}</span></th>
              </tr>
              
              <tr>
                <td>Last MOT date:</td>
                <th><span className="code">{JSON.parse(vehicleDetail.szVehicleDetails)?.motExpiryDate}</span></th>
              </tr>
              </table>
              </div>
              </div>
              <div className={`specification-tabs ${toggle && toggleName=="t3"? 'open': `` }`}>
<h4 className="head" onClick={() => toggleupdate("t3")}>
              Service history:
            </h4>
            <div className="tabletab">
            <table className="table">
              <tr>
                <td>Service history:{" "}</td>
                <th><span className="code">{vehicleDetail.szServiceHistoryCar? 'Yes' :'No' }</span></th>
              </tr>
              <tr>
                <td>Main dealer services:</td>
                <th><span className="code">{vehicleDetail.szManufacturer}</span></th>
              </tr>
              <tr>
                <td>Independent garage service:</td>
                <th><span className="code">{vehicleDetail.szIndependentGarageServices}</span></th>
              </tr>
              <tr>
                <td>Last service date:</td>
                <th><span className="code">{vehicleDetail.dtVehicleLastServiced }</span></th>
              </tr>
              <tr>
                <td>Last service mileage:</td>                
                <th><CurrencyFormat value={vehicleDetail.szLastServiceMileage||""} displayType={"text"} thousandSeparator={true} /></th>
              </tr>
              <tr>
                <td>Cambelt changed:</td>
                <th><span className="code">{vehicleDetail.bCambeltChanged ? 'Yes' : 'No'}</span></th>
              </tr>
              </table>
              </div>
              </div>
              <div className={`specification-tabs ${toggle && toggleName=="t4"? 'open': `` }`}>
<h4 className="head" onClick={() => toggleupdate("t4")}>
              Condition & damage:
            </h4>
            <div className="tabletab">
            <table className="table">
              <tr>
                <td>Scratches:{" "}</td>
                <th><span className="code">{vehicleDetail.bScratches ? 'Yes' : 'No'}</span></th>
              </tr>
              <tr>
                <td>Scuffs:</td>
                <th><span className="code">{vehicleDetail.bScuffs ? 'Yes' : 'No'}</span></th>
              </tr>
              <tr>
                <td>Dents:</td>
                <th><span className="code">{vehicleDetail.bDents ? 'Yes' : 'No'}</span></th>
              </tr>
              <tr>
                <td>Paintwork problems:</td>
                <th><span className="code">{vehicleDetail.bPaintworkProblems ? "yes" : "no"}</span></th>
              </tr>
              <tr>
                <td>Broken/missing lights, mirrors, trim or fittings:</td>
                <th><span className="code">{vehicleDetail.bBrokenMissing ? "yes" : "no"}</span></th>
              </tr>
              <tr>
                <td>Air conditioning problems:</td>
                <th><span className="code">{vehicleDetail.bAirConditioningProblems ? "yes" : "no"}</span></th>
              </tr>
              <tr>
                <td>Mechanical or electrical faults:</td>
                <th><span className="code">{vehicleDetail.bMechanicalElectricalFaults ? 'Yes' : 'No'}</span></th>
              </tr>
              <tr>
                <td>Warning lights on dashboard:</td>
                <th><span className="code">{vehicleDetail.bDashboardWarningLights ? "yes" : "no"}</span></th>
              </tr>
              </table>
              </div>
              </div>
              <div className={`specification-tabs ${toggle && toggleName=="t5"? 'open': `` }`}>
                <h4 className="head" onClick={() => toggleupdate("t5")}>
                              Wheels & tyres:
                            </h4>
              <div className="tabletab">
                <table className="table">
                  <tr>
                    <td>Have any of the alloys been kerbed or scuffed:{" "}</td>
                    <th><span className="code">{vehicleDetail.bDashboardWarningLights ? 'Yes' : 'No'}</span></th>
                  </tr>
                  <tr>
                    <td>Are there any problems with the tyres:</td>
                    <th><span className="code">{vehicleDetail.bProblemsWithTyres ? 'Yes' : 'No'}</span></th>
                  </tr>
                  <tr>
                    <td>Do you have the locking wheel nut:</td>
                    <th><span className="code">{vehicleDetail.bLockingWheelNut ? 'Yes' : 'No'}</span></th>
                  </tr>
                  <tr>
                    <td>Do you have the tool pack:</td>
                    <th><span className="code">{vehicleDetail.bToolPack ? "yes" : "no"}</span></th>
                  </tr>
                  </table>
                </div>
              </div>
          </div>
        </div>
        {window.innerWidth > 767 && (
          <div className="car-detail-column-right">
            <ProgressCard data={vehicleDetail} />
            <div className="gallery-img">
            <ImageCard avtar={vehicleDetail?.szExteriorBackDriverSide} />
            </div>
          </div>
        )}

        <div className="vehicle-detail-slider">
            <SimpleReactLightbox>
              <SRLWrapper>
              <Swiper {...params} ref={swiperRef}>
                <div>
                <ImageCard avtar={vehicleDetail?.szExteriorBackDriverSide} />
                </div>
                <div>
                <ImageCard avtar={vehicleDetail?.szExteriorFrontDriverSide} />
                </div>
                {vehicleDetail?.szExteriorBackPassengerSide?
                <div>
                <ImageCard avtar={vehicleDetail?.szExteriorBackPassengerSide} />
                </div>
                :''}
                <div>
                <ImageCard avtar={ vehicleDetail?.szInteriorFrontSeats} />
                </div>
                <div>
                <ImageCard avtar={ vehicleDetail?.szInteriorDashboard} />
                </div>
                <div>
                <ImageCard avtar={ vehicleDetail?.szInteriorBoot} />
                </div>
                <div>
                <ImageCard avtar={ vehicleDetail?.szInteriorRearSeats} />
                </div>
              </Swiper>
              </SRLWrapper>
            </SimpleReactLightbox>
              <div className="next-prev-btn">
              <button className="swiper-button-next" onClick={goPrev}></button>
              <button className="swiper-button-prev" onClick={goNext}></button>
              </div>
            </div>

      </div>
      <div className="max-size">
        {/* wheels */}
        <div className="images-wrapper">
          <h3 className="head">Wheels:</h3> 
          <div className="images">
            
            <SimpleReactLightbox className="vehicle-images-litebox">
            <SRLWrapper>
              
              <ImageCard avtar={ vehicleDetail?.szWheelsFrontDriverSide} />
              <ImageCard avtar={ vehicleDetail?.szWheelsBackDriverSide} />
              <ImageCard avtar={ vehicleDetail?.szWheelsFrontPassengerSide} />
              <ImageCard avtar={ vehicleDetail?.szWheelsBackPassengerSide} />
 
            </SRLWrapper>
          </SimpleReactLightbox>
          </div>
          <h3 className="head">Tyre treads:</h3> 
          <div className="images">
            
            <SimpleReactLightbox className="vehicle-images-litebox">
            <SRLWrapper>
             <ImageCard avtar={ vehicleDetail?.szTyreFrontPassengerSide} />
              <ImageCard avtar={ vehicleDetail?.szTyreBackPassengerSide} />           
              <ImageCard avtar={vehicleDetail?.szTyreFrontDriverSide} />           
              <ImageCard avtar={ vehicleDetail?.szTyreBackDriverSide} />   
            </SRLWrapper>
          </SimpleReactLightbox>
          </div>
        </div>
        {/* wheels */}
        {/* <div className="images-wrapper">
          {/* <h3 className="head">Tyre treads</h3> 
          <div className="images">
            <ImageCard avtar={vehicleDetail?.szTyreFrontDriverSide} />
            <ImageCard avtar={vehicleDetail?.szTyreBackDriverSide} />
            <ImageCard avtar={vehicleDetail?.szTyreFrontPassengerSide} />
            <ImageCard avtar={vehicleDetail?.szTyreBackPassengerSide} />
          </div>
        </div> */}
      </div>
      {window.innerWidth < 768 && (
        <div className="vehicle-detail-body mt-0">
          <div className="car-detail-column-right">
         
            <ProgressCard data={vehicleDetail} />
          </div>
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

export default VeshicleDetail;