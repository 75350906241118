import { isNumberKeyNew } from '../Helpers/Helpers';

const VehicleCondition = [
    {
        szStepHeading: 'Basic Details',
        step: 1,
        data: [
            {
                question: 'Does your car have any of these features?',
                szFieldType: 'checkbox',
                fields: [
                    {
                        szName: 'szPatNav',
                        szType: 'checkbox',
                        szClassName: 'circle-radio',
                        szId: 'satNav',
                        szValue: '',
                        szChildren: 'Sat Nav'
                    },
                    {
                        szName: 'szPanoramicRoof',
                        szType: 'checkbox',
                        szClassName: 'circle-radio',
                        szId: 'panoramicRoof',
                        szValue: '',
                        szChildren: 'Panoramic roof'
                    },
                    {
                        szName: 'szHeatedSeats',
                        szType: 'checkbox',
                        szClassName: 'circle-radio',
                        szId: 'heatedSeats',
                        szValue: '',
                        szChildren: 'Heated seats'
                    },
                    {
                        szName: 'szParkingCamera',
                        szType: 'checkbox',
                        szClassName: 'circle-radio',
                        szId: 'parkingCamera',
                        szValue: '',
                        szChildren: 'Parking camera'
                    },
                    {
                        szName: 'szUpgradedSoundSystem',
                        szType: 'checkbox',
                        szClassName: 'circle-radio',
                        szId: 'upgradedSoundSystem',
                        szValue: '',
                        szChildren: 'Upgraded sound system'
                    }
                ]
            },
            // {
            //   question: "What colour of your car?",
            //   fields: [
            //     {
            //       szName: "szCarColour",
            //       szType: "select",
            //       szClassName: "width-100",
            //       arOptions: [{
            //         value: "",
            //         label: "Select Color...",
            //         disabled: true,
            //       },
            //       {
            //         value: "white",
            //         label: "White",
            //       },
            //       {
            //         value: "cream",
            //         label: "Cream",
            //       },
            //       {
            //         value: "tan",
            //         label: "Tan",
            //       },
            //       {
            //         value: "brown",
            //         label: "Brown",
            //       },
            // {
            //         value: "black",
            //         label: "Black",
            //       },
            // {
            //         value: "Grey",
            //         label: "Grey",
            //       },
            // {
            //         value: "Red",
            //         label: "Red",
            //       },
            // {
            //         value: "Blue",
            //         label: "Blue",
            //       },

            //          {
            //         value: "blackblue",
            //         label: "Black & blue",
            //       },
            //       {
            //         value: "Blackred",
            //         label: "Black & red",
            //       },
            //       {
            //         value: "Blackwhite",
            //         label: "Black & white",
            //       },
            //       {
            //         value: "Blackgrey",
            //         label: "Black & grey",
            //       },

            //       {
            //         value: "other",
            //         label: "Other",
            //       },
            //     ],
            //     },
            //   ],
            // },
            {
                question: 'What colour are the seats?',
                fields: [
                    {
                        szName: 'szSeatsColor',
                        szType: 'select',
                        szClassName: 'width-100',
                        arOptions: [
                            {
                                value: '',
                                label: 'Select fabric...',
                                disabled: true
                            },
                            {
                                value: 'white',
                                label: 'White'
                            },
                            {
                                value: 'cream',
                                label: 'Cream'
                            },
                            {
                                value: 'tan',
                                label: 'Tan'
                            },
                            {
                                value: 'brown',
                                label: 'Brown'
                            },
                            {
                                value: 'black',
                                label: 'Black'
                            },
                            {
                                value: 'Grey',
                                label: 'Grey'
                            },
                            {
                                value: 'Red',
                                label: 'Red'
                            },
                            {
                                value: 'Blue',
                                label: 'Blue'
                            },

                            {
                                value: 'blackblue',
                                label: 'Black & blue'
                            },
                            {
                                value: 'Blackred',
                                label: 'Black & red'
                            },
                            {
                                value: 'Blackwhite',
                                label: 'Black & white'
                            },
                            {
                                value: 'Blackgrey',
                                label: 'Black & grey'
                            },

                            {
                                value: 'other',
                                label: 'Other'
                            }
                        ]
                    }
                ]
            },
            {
                question: 'How are they upholstered?',
                fields: [
                    {
                        szName: 'szUpholstered',
                        szType: 'select',
                        szClassName: 'width-100',
                        arOptions: [
                            {
                                value: '',
                                label: 'Select fabric...',
                                disabled: true
                            },
                            {
                                value: 'leather',
                                label: 'Leather'
                            },
                            {
                                value: 'half leather',
                                label: 'Half leather'
                            },
                            {
                                value: 'cloth',
                                label: 'Cloth'
                            },
                            {
                                value: 'suede',
                                label: 'Suede'
                            },

                            {
                                value: 'half suede',
                                label: 'Half Suede'
                            }
                        ]
                    }
                ]
            },

            {
                question: 'Has your car been written off at any time?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bWrittenOff',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'writtenOffYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bWrittenOff',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'writtenOffNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                // question: "Has your car been written off at any time?",
                szFieldType: 'component',
                szParentName: 'bWrittenOff',
                szParentValue: 1
            },
            {
                question: 'Do you have two working keys for the car?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bTwoWorkingKeys',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'workingKeysYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bTwoWorkingKeys',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'workingKeysNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: 'How many working keys do you have?',
                szFieldType: 'radio',
                szParentName: 'bTwoWorkingKeys',
                szParentValue: 0,
                szListRadio: 'list',
                fields: [
                    {
                        szName: 'szWorkingKeys',
                        szType: 'radio',
                        szClassName: 'radio width-100',
                        szId: 'szWorkingKeysNone',
                        szChildren: 'None',
                        szValue: 'None'
                    },
                    {
                        szName: 'szWorkingKeys',
                        szType: 'radio',
                        szClassName: 'radio width-100',
                        szId: 'szWorkingKeysOne',
                        szChildren: '1',
                        szValue: '1'
                    },
                    {
                        szName: 'szWorkingKeys',
                        szType: 'radio',
                        szClassName: 'radio width-100',
                        szId: 'szWorkingKeysMore',
                        szChildren: '3+',
                        szValue: '3+'
                    }
                ]
            },
            {
                question: 'Do you have the V5C logbook?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bV5CLogBook',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'logbookYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bV5CLogBook',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'logbookNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: 'Is the V5C logbook in your name?',
                szFieldType: 'radio',
                szParentName: 'bV5CLogBook',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'bLogbookName',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'logbookNameYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bLogbookName',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'logbookNameNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: 'Do you have the book pack?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bBookPack',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bookpackYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bBookPack',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bookpackNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            }
        ]
    },
    {
        szStepHeading: 'Service History',
        step: 2,
        data: [
            {
                question: 'What is the service history of your car?',
                szFieldType: 'radio',
                szListRadio: 'list',
                fields: [
                    {
                        szName: 'szServiceHistoryCar',
                        szType: 'radio',
                        szClassName: 'radio width-100',
                        szId: 'serviceHistoryFull',
                        szChildren: 'Full service history',
                        szValue: 1
                    },
                    {
                        szName: 'szServiceHistoryCar',
                        szType: 'radio',
                        szClassName: 'radio width-100',
                        szId: 'serviceHistoryPartial',
                        szChildren: 'Partial service history',
                        szValue: 4
                    },
                    {
                        szName: 'szServiceHistoryCar',
                        szType: 'radio',
                        szClassName: 'radio width-100',
                        szId: 'serviceHistoryNoService',
                        szChildren: 'No service history',
                        szValue: 2
                    },
                    {
                        szName: 'szServiceHistoryCar',
                        szType: 'radio',
                        szClassName: 'radio width-100',
                        szId: 'serviceHistoryNotYet',
                        szChildren: 'Not yet due first service',
                        szValue: 3
                    }
                ]
            },

            {
                question: 'Do you have the service record?',
                szFieldType: 'radio',
                szParentName: 'szServiceHistoryCar',
                szParentValue: 4,

                fields: [
                    {
                        szName: 'bServiceRecord',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'serveceRecordYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bServiceRecord',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'serveceRecordNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: 'Do you have the service record?',
                szFieldType: 'radio',
                szParentName: 'szServiceHistoryCar',
                szParentValue: 1,

                fields: [
                    {
                        szName: 'bServiceRecord',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'serveceRecordYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bServiceRecord',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'serveceRecordNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },

            {
                question: 'Manufacturer or official dealer services:',
                szFieldType: '',
                szParentName: 'bServiceRecord',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szManufacturer',
                        szType: 'text',
                        szClassName: 'width-100',
                        szPlaceholder: 'e.g. 2',
                        szOnKeypress: (e) => {
                            return isNumberKeyNew(e);
                        }
                        // normalize: "normalizeNumber",
                    }
                ]
            },
            {
                question: 'Independent garage services:',
                szFieldType: '',
                szParentName: 'bServiceRecord',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szIndependentGarageServices',
                        szType: 'text',
                        szClassName: 'width-100',
                        szPlaceholder: 'e.g. 3',
                        szOnKeypress: (e) => {
                            return isNumberKeyNew(e);
                        }
                        // normalize: "normalizeNumber",
                    }
                ]
            },
            {
                question: 'When was the vehicle last serviced?',
                szFieldType: '',
                szParentName: 'bServiceRecord',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'dtVehicleLastServiced',
                        szType: 'date',
                        szClassName: 'width-100',
                        szPlaceholder: 'e.g. YYYY-MM-DD'
                    }
                ]
            },
            {
                question: 'What was the mileage at the last service?',
                szFieldType: '',
                szParentName: 'bServiceRecord',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szLastServiceMileage',
                        szType: 'text',
                        szClassName: 'width-100',
                        szPlaceholder: 'e.g. 15000',
                        szOnKeypress: (e) => {
                            return isNumberKeyNew(e);
                        }
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Has the cambelt ever been changed?',
                szFieldType: 'radio',
                szParentName: 'bServiceRecord',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'bCambeltChanged',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'cambeltYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bCambeltChanged',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'cambeltNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: 'When was the cambelt changed?',
                szFieldType: '',
                szParentName: 'bCambeltChanged',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'dtCambeltChange',
                        szType: 'date',
                        szClassName: 'width-100',
                        szPlaceholder: 'e.g. YYYY-MM-DD'
                    }
                ]
            },
            {
                question: 'What was the mileage when the cambelt was changed?',
                szFieldType: '',
                szParentName: 'bCambeltChanged',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szMilageCambeltChanged',
                        szType: 'text',
                        szClassName: 'width-100',
                        szPlaceholder: 'e.g. 50000',
                        szOnKeypress: (e) => {
                            return isNumberKeyNew(e);
                        }
                        // normalize={normalizeNumber}
                    }
                ]
            }
        ]
    },
    {
        szStepHeading: 'Vehicle Condition',
        step: 3,
        data: [
            {
                question: 'Scratches?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bScratches',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'scratchesYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bScratches',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'scratchesNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bScratches',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szScratchesDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Scratches?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Scuffs?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bScuffs',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bScuffsYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bScuffs',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bScuffsNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bScuffs',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szScuffsDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Scuffs?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Dents?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bDents',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bDentsYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bDents',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bDentsNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bDents',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szDentsDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Dents?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Paintwork problems?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bPaintworkProblems',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bPaintworkProblemsYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bPaintworkProblems',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bPaintworkProblemsNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bPaintworkProblems',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szPaintworkProblemsDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Paintwork problems?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Broken/missing lights, mirrors, trim or fittings?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bBrokenMissing',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bBrokenMissingYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bBrokenMissing',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bBrokenMissingNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bBrokenMissing',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szBrokenMissingDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Broken/missing lights, mirrors, trim or fittings?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Air conditioning problems?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bAirConditioningProblems',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bAirConditioningProblemsYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bAirConditioningProblems',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bAirConditioningProblemsNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bAirConditioningProblems',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szAirConditioningProblemsDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Air conditioning problems?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Mechanical or electrical faults?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bMechanicalElectricalFaults',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bMechanicalElectricalFaultsYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bMechanicalElectricalFaults',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bMechanicalElectricalFaultsNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bMechanicalElectricalFaults',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szMechanicalElectricalFaultsDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Mechanical or electrical faults?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Warning lights on dashboard?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bDashboardWarningLights',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bDashboardWarningLightsYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bDashboardWarningLights',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bDashboardWarningLightsNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: '',
                szParentName: 'bDashboardWarningLights',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szDashboardWarningLightsDescription',
                        szType: 'textarea',
                        szClassName: 'textarea width-100',
                        szPlaceholder: 'Warning lights on dashboard?'
                        // normalize={normalizeNumber}
                    }
                ]
            }
        ]
    },
    {
        szStepHeading: 'Wheels & Tyres',
        step: 4,
        data: [
            {
                question: 'Have any of the alloys been kerbed or scuffed?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bKerbedOrScuffed',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bKerbedOrScuffedYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bKerbedOrScuffed',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bKerbedOrScuffedNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: 'Are there any problems with the tyres?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bProblemsWithTyres',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bProblemsWithTyresYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bProblemsWithTyres',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bProblemsWithTyresNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: '',
                szFieldType: 'textarea',
                szParentName: 'bProblemsWithTyres',
                szParentValue: 1,
                fields: [
                    {
                        szName: 'szDetailsOfProblemsWithTyres',
                        szType: 'textarea',
                        szClassName: 'text-area width-100',
                        szPlaceholder: 'Are there any problems with the tyres?'
                        // normalize={normalizeNumber}
                    }
                ]
            },
            {
                question: 'Do you have the locking wheel nut?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bLockingWheelNut',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bLockingWheelNutYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bLockingWheelNut',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bLockingWheelNutNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            },
            {
                question: 'Do you have the tool pack?',
                szFieldType: 'radio',
                fields: [
                    {
                        szName: 'bToolPack',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bToolPackYes',
                        szChildren: 'Yes',
                        szValue: 1
                    },
                    {
                        szName: 'bToolPack',
                        szType: 'radio',
                        szClassName: 'radio-button width-50',
                        szId: 'bToolPackNo',
                        szChildren: 'No',
                        szValue: 0
                    }
                ]
            }
        ]
    },
    {
        step: 5,
        szStepType: 'component',
        data: []
    }
];

export { VehicleCondition };
