import React, { useEffect, useState } from "react";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import StickyBox from "react-sticky-box";
import CommonBanner from "../../Components/Common/CommonBanner/CommonBanner";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Path from "../../Constants/Routes";
import { getSellerVehicleList } from "../../Services/sellerVehicleList";
import EditProfile from "../EditProfile/EditProfile";
import CustomerMessage from "./CustomerMessage/CustomerMessage";
import AwaitingSubmission from "../SellerProfile/AwaitingSubmission";
import CurrentAuctions from "../SellerProfile/CurrentAuctions";
import SuccessfullySold from "../SellerProfile/SuccessfullySold";
import Unsuccessful from "../SellerProfile/Unsuccessful";
import Withdrawn from "../SellerProfile/Withdrawn";
import Role from "../SellerProfile/Role";
import AddRole from "../SellerProfile/AddRole";
import SubUser from "../SellerProfile/SubUser";
import "./SellerProfile.scss";
import AddPermission from "../SellerProfile/AddPermission";
import VehicleList from "./VehicleList";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import PrivateRoute from "../../PrivateRoute";

const SellerProfile = () => {
  const [bidsToggle, setBidsToggle] = useState(false);
  const [purchaseToggle, setPurchaseToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [iIsApprovedStatus, setIsApproved] = useState(null);
  const [szStatus, setSzStatus] = useState(null);
  const [bidList, setBidList] = useState([]);
  const [count, setCount] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [title, setTitle] = useState("Seller Profile");
  const type = localStorage.getItem("type");
  const params = useHistory();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    if (params.location.pathname == "/seller-profile/my-vehicle"
        || params.location.pathname == "/seller-profile/awaiting-submission") {
		getSellerVehicle("-1")
      setTitle("Awaiting Submission");
    }
    if (params.location.pathname == "/seller-profile/current-auctions") {
		getSellerVehicle("1");
      setTitle("Current Auctions");
    }
    if (params.location.pathname == "/seller-profile/successfully-sold") {
		getSellerVehicle("2", null, "Won");
      setTitle("Successfully Sold");
    }
    if (params.location.pathname == "/seller-profile/unsuccessful") {
		getSellerVehicle("0", null, "Reject");
      setTitle("Unsuccessful");
    }
    if (params.location.pathname == "/seller-profile/withdrawn") {
		getSellerVehicle("3", null, "Withdrawn");
      setTitle("Withdrawn");
    }
    if (params.location.pathname == "/seller-profile/edit-profile") {
		getSellerVehicle("1", null, "Withdrawn");
      setTitle("Edit Profile");
    }

    if (params.location.pathname == "/seller-profile/role") {
		getSellerVehicle("1", null, "Withdrawn");
      setTitle("Manage Role");
    }
    if (params.location.pathname == "/seller-profile/sub-user") {
		getSellerVehicle("1", null, "Withdrawn");
      setTitle("Manage Sub Users");
    }

  }, [params.location]);

  // @api
  const getSellerVehicle = (iIsApproved = null, pageNo = null, szStatus = null) => {
    setLoader(true);
    let obj = {};
    setIsApproved(iIsApproved);
    setSzStatus(szStatus);
    if (iIsApproved) {
      obj["iIsApproved"] = iIsApproved;
    }
    if (szStatus) {
      obj["szStatus"] = szStatus;
    }
    obj["page"] = pageNo + 1;

    getSellerVehicleList(obj)
      .then(async (res) => {
        if (res.status === false) {
          console.log("something went wrong");
        } else {
          console.log(res?.data?.data?.data);
          await setCount(res?.data?.data?.total);
          await setBidList(res?.data?.data?.data?.map(b=>{
            const details = b?.szVehicleDetails;            
            return {...b, szVehicleDetails: details ? JSON.parse(details) : undefined}
          }));
          await setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handleToggle = (toggleItem) => {
    if (toggleItem === "bid") {
      setBidsToggle(true);
      setPurchaseToggle(false);
    } else if (toggleItem === "purchase") {
      setPurchaseToggle(true);
      setBidsToggle(false);
    }
  };

  const handlePageClick = (data) => {
    console.log("++++++______+++++", data);
    setPageNo(data.selected);
    getSellerVehicle(iIsApprovedStatus, data.selected, szStatus);
  };



  return (
    <div className="customer-profile">
      {loader && <LoaderWrapper />}
      <CommonBanner>
        <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
          <h1 className="car-name">{title}</h1>
        </div>
      </CommonBanner>
      <div className="container-customer d-flexs custom-scrollbar">
        {/* <div className="filterSection">
          <select className="cg-select" id="AccountDateRangeFilter">
            <option value="ALL">All</option>
            <option value="TODAY">Today</option>
            <option value="YESTERDAY">Yesterday</option>
            <option value="LAST_7_DAYS">Last 7 days</option>
            <option value="LAST_30_DAYS">Last 30 days</option>
          </select>
        </div> */}
        <div className="profilwrapper" style={{ display: "flex", alignItems: "flex-start" }}>
          <StickyBox className="sideBarSection" offsetTop={0} offsetBottom={20}>
            <div className="sideBarSection-inner">
              <div className="sideBarSection-cg">
                <nav className="navbar">
                  <NavLink
                    onClick={() => {
                      getSellerVehicle("-1")
                      setTitle("Awaiting Submission");
                    }}
                    exact
                    className={`navbar__link ${(params.location.pathname == "/seller-profile" && iIsApprovedStatus == "-1") && 'Active-custome'}`}
                    to={Path.awaitingSubmission}
                  >
                    Awaiting Submission
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      getSellerVehicle("1");
                      setTitle("Current Auctions ");
                    }}
                    className={`navbar__link ${(params.location.pathname == "/seller-profile" && iIsApprovedStatus == "1") && 'Active-custome'}`}
                    to={Path.currentAuctions}
                  >
                    Current Auctions
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      getSellerVehicle("2", null, "Won");
                      setTitle("Successfully Sold");
                    }}
                    className={`navbar__link ${(params.location.pathname == "/seller-profile" && iIsApprovedStatus == "2") && 'Active-custome'}`}
                    to={Path.successfullySold}
                  >
                    Successfully Sold
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      getSellerVehicle("0", null, "Reject");
                      setTitle("Unsuccessful");
                    }}
                    className={`navbar__link ${(params.location.pathname == "/seller-profile" && iIsApprovedStatus == "0") && 'Active-custome'}`}
                    to={Path.unsuccessful}
                  >
                    Unsuccessful
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      getSellerVehicle("3", null, "Withdrawn");
                      setTitle("Withdrawn");
                    }}
                    className={`navbar__link ${(params.location.pathname == "/seller-profile" && iIsApprovedStatus == "3") && 'Active-custome'}`}
                    to={Path.withdrawn}
                  >
                    Withdrawn
                  </NavLink>

                  <NavLink
                    onClick={() => {
                      getSellerVehicle("1", null, "Withdrawn");
                      setTitle("Edit Profile");
                    }}
                    className={`navbar__link ${params.location.pathname ==
                      "/seller-profile/edit-profile" && "Active-custome"
                      }`}
                    to={Path.editProfile}
                  >
                    Edit Profile
                  </NavLink>
                      {console.log("localStorage.getItem('userType')",localStorage.getItem('userType'))}
                  {
                    (localStorage.getItem('userType') && localStorage.getItem('userType') == 12) &&

                    (
                      <>
                        <NavLink
                          onClick={() => {
                            getSellerVehicle("1", null, "Withdrawn");
                            setTitle("Manage Role");
                          }}
                          className={`navbar__link ${params.location.pathname ==
                            "/seller-profile/role" && "Active-custome"
                            }`}
                          to={Path.role}
                        >
                          Role
                  </NavLink>

                        <NavLink
                          onClick={() => {
                            getSellerVehicle("1", null, "Withdrawn");
                            setTitle("Manage Sub Users");
                          }}
                          className={`navbar__link ${params.location.pathname ==
                            "/seller-profile/sub-user" && "Active-custome"
                            }`}
                          to={Path.subUser}
                        >
                          Sub Users
                  </NavLink>
                      </>
                    )
                  }
                </nav>
              </div>
            </div>

          </StickyBox>
          <div className="vehiclesSection">
            <Switch>
              {/* <Route
                exact={true}
                path={Path.myVehicle}
                component={() => <VehicleList data={bidList} />}
              /> */}
              {/* <Route path={Path.bidList} component={BidListSub} /> */}
              {/* <Route path={Path.myVehicle} component={MyVehicle} /> */}
              {/* <Route exact path={`${Path.bidList}:slug`} component={BidListSub} /> */}
              {/* <Route
                exact
                path={Path.customerMessage}
                component={CustomerMessage}
              /> */}

              <PrivateRoute
                exact={true}
                path={Path.awaitingSubmission}
                component={() => (<AwaitingSubmission
                  data={bidList}
                  count={count}
                  pageNo={pageNo}
                  PageClick={handlePageClick}
                />)}
              />
              <PrivateRoute
                exact={true}
                path={Path.currentAuctions}
                component={() => (<CurrentAuctions
                  data={bidList}
                  count={count}
                  pageNo={pageNo}
                  PageClick={handlePageClick}
                />)}
              />
              <PrivateRoute
                exact={true}
                path={Path.successfullySold}
                component={() => (<SuccessfullySold
                  data={bidList}
                  count={count}
                  pageNo={pageNo}
                  PageClick={handlePageClick}
                />)}
              />
              <PrivateRoute
                exact={true}
                path={Path.unsuccessful}
                component={() => (
                  <Unsuccessful
                    data={bidList}
                    count={count}
                    pageNo={pageNo}
                    PageClick={handlePageClick}
                  />
                )}
              />
              <PrivateRoute
                exact={true}
                path={Path.withdrawn}
                component={() => (
                  <Withdrawn
                    data={bidList}
                    count={count}
                    pageNo={pageNo}
                    PageClick={handlePageClick}
                  />
                )}
              />


              <PrivateRoute exact path={Path.editProfile} component={EditProfile} />
              <PrivateRoute exact path={Path.role} component={Role} />
              <PrivateRoute exact path={Path.addRole} component={AddRole} />
              <PrivateRoute exact path={Path.addUser} component={AddUser} />
              <PrivateRoute exact path={`${Path.editUser}/:slug`} component={EditUser} />
              <PrivateRoute exact path={Path.subUser} component={SubUser} />
              <PrivateRoute exact path={`${Path.addPermission}/:slug`} component={AddPermission} />
              {/* <Route exact path={Path.customerMessage} component={CustomerMessage} /> */}
              <PrivateRoute exact path={`${Path.customerMessage}/:slug`} component={CustomerMessage} />
              <PrivateRoute
                exact={true}
                path=""
                component={() => (
                  <AwaitingSubmission
                    data={bidList}
                    count={count}
                    pageNo={pageNo}
                    PageClick={handlePageClick}
                  />
                )}
              />

              {/* <Route
                path={Path.completedAuctions}
                component={CompletedAuctions}
              />
              <Route path={Path.offAuction} component={OffAuction} /> */}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerProfile;
