import React from "react";
import "./RadioCircle.scss";

const CheckboxCircle = ({ input, children, className, id, label, name }) => {
  return (
    <div className={`radio ${className}`}>
      <input
        {...input}
        type="checkbox"
        id={id}
        name={name}
        checked={input.value ? true : false}
        // value={value}
      />
      <label htmlFor={id}>
        {children}
        <span className="d-block">{label}</span>
      </label>
    </div>
  );
};

export default CheckboxCircle;
