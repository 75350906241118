import ViewDetail from "../Pages/ViewDetail/ViewDetail";

const Path = {
  home: "/",
  sellerSignUp: `/`,
  login: "/login",
  dealerLogin: "/dealer-login",
  dealerSignUp: "/dealer-signup",
  aboutUs: "/about",
  team: "/team",
  faq: "/faq",
  terms: "/terms",
  privacyPolicy: "/privacy-policies",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  changePassword: "/change-password",
  contactUs: "/contact-us",
  customerReview: "/customer-review",
  sellingProcess: "/selling-process",
  userSetting: "/user-setting",
  more: "/more",
  carDetail: "/car-detail",
  vehicleDetail: "/vehicle-detail/",
  carListing: "/car-listing",
  customerMessage: "/seller-profile/message",
  termsConditions: "/terms",
  privacyPolicy: "/privacy",
  cookiePolicy: "/Cookie",
  updateMileage: "/update-mileage",
  sellerProfile: "/seller-profile",
  vehicleList: "/seller-profile",
  bidList: "/seller-profile/bid-list/",
  reviewDetails: "/review-details",
  editProfile: "/seller-profile/edit-profile",  
  completedAuctions: "/seller-profile/completed-auctions",
  offAuction: "/seller-profile/off-auction",
  myVehicle: "/seller-profile/my-vehicle",
  veshicleDetail: "/veshicle-detail",
  ViewDetail: "/view-detail",
  ViewVehicleDetails: "/view-vehicle-details/",
  awaitingSubmission: "/seller-profile/awaiting-submission",
  currentAuctions: "/seller-profile/current-auctions",
  successfullySold: "/seller-profile/successfully-sold",
  subUser: "/seller-profile/sub-users",  
  unsuccessful: "/seller-profile/unsuccessful",
  withdrawn: "/seller-profile/withdrawn",
  pricing: "/pricing",
  role: "/seller-profile/role",
  addRole: "/seller-profile/add-role",
  addUser: "/seller-profile/add-user",
  addPermission: "/seller-profile/add-permission",
  subUser: "/seller-profile/sub-user",
  xqzewxghxd: "/xqzewxghxd",
  editUser: "/seller-profile/edit-user",
  // ViewBidList: "/seller-profile/bid-list/",
  
  // select
};
export default Path;
