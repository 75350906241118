import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFormValues, isInvalid } from "redux-form";
import Path from "../../Constants/Routes";
import Button from "../Common/Button";
// import "./Shared.scss";

export const PrevNext = ({
  lastStep,
  children,
  onStepChange,
  formvalues,
  invalidForms,
  handleSubmit,
}) => {
  const [step, setStep] = useState(lastStep || 0);
  const modules = React.Children.map(children, (item) => item && item);
  const prev = () => {
    if (step > 0) {
      setStep(step - 1);
      onStepChange(formvalues[step - 1]);
    }
  };
  const history = useHistory();
  const next = () => {
    if (step < modules.length - 1) {
      setStep(() => {
        handleSubmit(step, formvalues[step]);
        onStepChange(formvalues[step + 1]);
        return step + 1;
      });
    }
  };

  const jump = (i) => {
    setStep(i);
    onStepChange(formvalues[i]);
  };
  const redirectToVehicleDetail = () => {
    history.push(Path.vehicleDetail);
  };

  useEffect(() => {
      setStep(lastStep);
  }, [lastStep]);

  return (
    <div>
      
      {/* {step === 5 ? null : (
        <ul className="steps list-style-none d-flex">
          {modules.map((item, index) => {
            return (
              <>
                {index < 5 && (
                  <li
                    className={`step ${
                      step < index || invalidForms[index] ? "" : "step-fill"
                    }`}
                    onClick={() => jump(index)}
                    disabled={step < index || invalidForms[index]}
                  >
                    <span className="step-caption">0{index + 1}</span>
                  </li>
                )}
              </>
            );
          })}
        </ul>
      )} */}
      {modules[step]}
      {step === 5 ? (
        ""
      ) : (
        <div className="stepper-button-wrapper">
          <div className="width-100">
            <Button
              className="primary form-width next"
              disabled={invalidForms[step]}
              onClick={next}
            >
              {step == 4 ? "Submit" : "Next Step"}
            </Button>
          </div>
          <div className="preview-finish">
            {step !== 0 && (
              <div className="col-50">
                <Button className="primary-invert preview" onClick={prev}>
                  Previous
                </Button>
              </div>
            )}
            <div className={`${step !== 0 ? "col-50" : "width-100"}`}>
              <Button
                className="primary-invert finish"
                onClick={redirectToVehicleDetail}
              >
                Finish
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const props = {
    formvalues: [],
    invalidForms: [],
  };
  const { formNames } = ownProps;
  for (let i = 0; i < formNames.length; i++) {
    props.formvalues[i] = getFormValues(formNames[i])(state);
    props.invalidForms[i] = isInvalid(formNames[i])(state);
  }
  return props;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrevNext);
