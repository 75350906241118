import React, { useEffect } from "react";
import CommonBanner from "../../Components/Common/CommonBanner/CommonBanner";
import "./CookiePolicy.scss";

const CookiePolicy = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="cookie-policy">      
      <div className="container-cookie custom-scrollbar">
      <h1 className="heading">Cookies Policy</h1>
      <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
<p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>
<p>&nbsp;</p>
<p>We use the following cookies:</p>
<p>&nbsp;</p>
<ul>
<li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website and list your vehicle for sale.</li>
<p>&nbsp;</p>
<li><strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>
<p>&nbsp;</p>
<li><strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, email address).</li>
<p>&nbsp;</p>
<li><strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.</li>
</ul>
<p>&nbsp;</p>
<p>You can find more information about the individual cookies (stored in local storage) we use and the purposes for which we use them in the table below:</p>
<p>&nbsp;</p>
<table width="100%" border="1">
<tbody>
<tr>
<td width="18%">
<p><strong>Cookie Title</strong></p>
<p><strong>Cookie Name</strong></p>
</td>
<td width="57%">
<p><strong>Purpose </strong></p>
</td>
<td width="24%">
<p><strong>More information</strong></p>
</td>
</tr>
<tr>
<td width="18%">
<p>szName</p>
<p>&nbsp;</p>
</td>
<td width="57%">
<p>This cookie is used internally to ensure functioning of the website to display logged in person&rsquo;s name</p>
</td>
<td width="24%">
<p>Stored in local storage</p>
</td>
</tr>
<tr>
<td width="18%">
<p>Token</p>
</td>
<td width="57%">
<p>This cookie is used internally to ensure functioning of the website</p>
</td>
<td width="24%">
<p>Stored in local storage</p>
</td>
</tr>
</tbody>
</table>                 
<p>&nbsp;</p>
<p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.</p>
<p>Except for essential cookies, all cookies will expire after one day.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
      </div>
    </div>
  );
};

export default CookiePolicy;
