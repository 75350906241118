import setting from "./settings";
export default (() => ({
  AUTH: `${setting.api.basicAuth}`,
  // Apis
  getVehicleDetail: `${setting.api.url}/vehicleDetails`,
  searchCar: `${setting.api.url}/vehicle`,
  sellerSignUp: `${setting.api.url}/sellerSignUp`,
  sellerLogin: `${setting.api.url}/sellerLogin`,
  sellerLoginOTP: `${setting.api.url}/verifyOtp`,
  dealerLogin: `${setting.api.url}/dealerLogin`,
  dealerSignup: `${setting.api.url}/dealerSignUp`,
  updateVehicleDetails: `${setting.api.url}/saveVehicleDetails`,
  vehicleList: `${setting.api.url}/vehicleList`,
  addBid: `${setting.api.url}/addBid`,
  listBids: `${setting.api.url}/getSellerVehicleList`,
  addVehicle: `${setting.api.url}/addVehicle`,
  sellerVehicleList: `${setting.api.url}/sellerVehicle`,
  vehicleBidList: `${setting.api.url}/vehicleBidList`,
  getSellerDetails: `${setting.api.url}/getSellerDetails`,
  updateSellerDetails: `${setting.api.url}/updateSellerDetails`,
  acceptRejectBid: `${setting.api.url}/acceptRejectBid`,
  sendQueryReply: `${setting.api.url}/sendQueryReply`,
  contactUs: `${setting.api.url}/contactUs`,
  messages: `${setting.api.url}/getChatList`,
  getMessageDealer: `${setting.api.url}/getMessageDealer`,
  checkVehicleIsRegistered: `${setting.api.url}/checkVehicleIsRegistered`,
  sendPhotoLink: `${setting.api.url}/sendPhotoLink`,
  getRoleList: `${setting.api.url}/getRoleList`,
  addRole: `${setting.api.url}/addRole`,
  getPermission: `${setting.api.url}/getRoleWisePermission`,
  deleteRole: `${setting.api.url}/deletebRole`,
  updatepermission: `${setting.api.url}/updatepermission`,
  addEmployee: `${setting.api.url}/addEmployee`,
  deleteEmployee: `${setting.api.url}/deleteEmployee`,
  getEmployee: `${setting.api.url}/getEmployee`,
  getEmployeeDetails: `${setting.api.url}/getEmployeeDetails`,
  updateEmployee: `${setting.api.url}/updateEmployee`,
  getPriceAndRegister: `${setting.api.url}/vehiclePriceAndSignUp`, // no logged in
  getPriceAndAddVehicle: `${setting.api.url}/vehiclePriceAndAddVehicle`, // logged in
  completeSellerSignUp: `${setting.api.url}/CompleteSignUp`, // will be called when entering post code.(no signin)
  // searchCar: ``
  fileUploadToApiServerUrl: `${setting.api.url}/uploadPhoto`
}))();
