import React from "react";
import "./TextArea.scss";

const TextArea = ({
  className,
  placeholder,
  input,
  meta: { touched, error },
}) => {
  return (
    <textarea
      {...input}
      className={`textarea ${className} ${touched && error && "input-error"}`}
      placeholder={placeholder}
    ></textarea>
  );
};

export default TextArea;
