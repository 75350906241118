import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { required, validate } from "../../Components/Auth/SellerSignup/validate";
import Button from "../../Components/Common/Button";
import Input from "../../Components/Common/Input";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import { updateUsername, store } from "../../Helpers/Helpers";
import Path from "../../Constants/Routes";

import { updateDetails } from "../../Services/UpdateDetails";
import "./Role.scss";
import { getRoles } from "../../Services/getRoles";
import SelectBox from "../../Components/Common/SelectBox/SelectBox";
import { addEmployee } from "../../Services/addUser";
import { getUserDetails } from "../../Services/getUserDetails";
import EditUserForm from "./EditUserForm";
import { updateUser } from "../../Services/updateUser";

let EditUser = (props) => {
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  let { slug } = useParams();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getRoleList();
  }, []);

  useEffect(() => {
    getUserInfo(slug);
  }, []);
  
  const getRoleList = (req) => {
    setLoader(true);
    getRoles(req)
      .then((res) => {
        if (res?.data?.success == true) {
          let arRole = res?.data?.data?.data;
          arRole?.map((item, index) => {
            item['value'] = item.business_id;
            item['label'] = item.business_name;
          })
          setRoleList(arRole);
          console.log("res", res?.data?.data?.data);
          setLoader(false);
        } else {
          console.log("Permission list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const getUserInfo = (slug) => {
    setLoader(true);
    getUserDetails({idEmployee:slug})
      .then((res) => {
        if (res?.data?.success == true) {
          let arRole = res?.data?.data;
          console.log("userDetailsuserDetailsuserDetails",res?.data?.data)
          setUserDetails(arRole);
          setLoader(false);
        } else {
          console.log("Permission list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  

  

  const handlePopup = () => {
    setPopup(!popup);
  };
  

  return (
    <div className="x_panel">
      {loader && <LoaderWrapper />}
      <div className="x_content">
          {console.log("userDetailsuserDetailsuserDetails",userDetails)}
          {userDetails && <EditUserForm roleList={roleList}  initialValues={
            {
                fullName:userDetails?.fullName,
                email:userDetails?.email,
                mobile:userDetails?.mobile_no,
                status:+userDetails?.status,
                user_access_group:userDetails?.business_id,
            }
        } />
        }
        
       </div>
      <Popup
        onClick={handlePopup}
        // continue={continueForm}
        state={popup}
        heading="Role"
        btnText="Filter"
      // continueText="Save later"
      >
        <p>User added  successfully.</p>
        <button className="btn primary" onClick={handlePopup}>
          Ok
        </button>
      </Popup>
    </div>

  );
};

 

EditUser = reduxForm({
  // a unique name for the form
  form: "EditUser",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(EditUser);

export default connect((state) => {
  const formValues = state?.form["EditUser"]?.values;
  return {
    formValues,
    state,
    initialValues:state.initialValues
  };
  // return state;
})(EditUser);