import {
  BackendApiInstance
} from "../config/Interceptor";
import Api from "./../config/Api";

export function searchCar(vehNo, vehMileage) {
  return BackendApiInstance.post(`${Api.searchCar}`, {
    'vehicle_no': vehNo,
    vehicle_mileage: vehMileage
  });
}

export function getPriceAndRegisterVahicle({
  regNo,
  mileage,
  name,
  ph,
  email,
  privacy
}) {
  return BackendApiInstance.post(Api.getPriceAndRegister, {
    vehicle_no: regNo,
    vehicle_mileage: mileage,
    szFullName: name,
    szEmail: email,
    szPhoneNumber: ph,
    iIsPrivacyPolicy: privacy
  });
}

export function getPriceAndAddVehicle({
  regNo,
  mileage
}) {
  return BackendApiInstance.post(Api.getPriceAndAddVehicle, {
    vehicle_no: regNo,
    vehicle_mileage: mileage
  });
}
