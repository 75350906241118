import React, { useEffect, useState } from 'react';
import Button from '../../Components/Common/Button';
import InputCustom from '../../Components/Common/Input/InputCustom';
import { getVehicleDetail } from './../../Services/getVehicleDetails';
import Icon from './../../Assets/Icons/info-icon.png';
import Countdown from 'react-countdown';
import * as moment from 'moment';
import { addBid } from './../../Services/addBid';
import CurrencyFormat from 'react-currency-format';
import Popup from "../../Components/Common/Popup/Popup";
import "./VehicleDetail.scss";

const ProgressCard = (props) => {
    const [value, setValue] = useState('');
    const [vehicleDetail, setVehicleDetail] = useState({});
    const [error, setError] = useState('');
    const [popup, setPopup] = useState(false);

    // handleChange
    const handleChange = (value) => {
        setValue(value);
        if (!value || !value.trim()) {
            setError('Enter Car Number');
        } else {
            setError('');
        }
    };
    const handleThankuPopup = () => {
        setPopup(false);
    };
    // const getVehicleDetailFun = (slug = 158) => {
    // //  setLoader(true);
    //   getVehicleDetail(slug = 158)
    //     .then(async (res) => {
    //       setVehicleDetail(res?.data?.data);
    //      // setLoader(false);
    //       console.log(
    //         "vehicleDetailvehicleDetail",
    //         res?.data?.data,
    //         vehicleDetail
    //       );
    //      // await setShowDetails(true);
    //     })
    //     .catch((err) => {
    //       // this.props.hideLoader();
    //       console.log(err);
    //     //  setLoader(false);
    //     });
    // };

    const bidReqFun = () => {
        let req = { idVehicle: props.data.id, dBidAmount: value };
        addBid(req)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                // this.props.hideLoader();
                console.log(err);
            });
    };

    const buyNowFun = () => {
        let req = { idVehicle: props.data.id, dBidAmount: props.data.dBuyNowPrice };
        addBid(req)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                // this.props.hideLoader();
                console.log(err);
            });
    };

    return (
        <div className="progress-card">
            <ul className="list-style-none form-list">
                <li>
                    <label>Created At:</label>
                    <span>{moment(props?.data?.created_at).format('Do MMMM YY')}</span>
                </li>

                <li>
                    <label>Guide price:</label>
                    <span>
                        <CurrencyFormat value={props?.data?.dMaximumPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    </span>
                </li>
                <li>
                    <label>Buy Now price:</label>
                    <span>
                        <CurrencyFormat value={props?.data?.dBuyNowPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    </span>
                </li>
                <li>
                    <label>Minimum price:</label>
                    <span>
                        <CurrencyFormat value={props?.data?.dMinimumPrice} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    </span>
                </li>
                <li>
                    <label>Start Date:</label>
                    <span>{props?.data?.dtAuctionStartDate}</span>
                </li>
                <li>
                    <label>End Date:</label>
                    <span>{props?.data?.dtAuctionEndDate}</span>
                </li>
                <li>
                    <label>Location:</label>
                    <span
                        className="price"
                        onClick={() => {
                            setPopup(true);
                        }}
                    >
                        {props?.data?.seller_details?.szPostCode}
                    </span>
                </li>
               
                {/* <li>
          <label>Auction ends:</label>
          <span>
          <Countdown date={Date.now() + moment(props?.data?.dtAuctionEndDate,"YYYY-MM-DD").diff(moment())} /> <span className="dim">13 bids</span>
          </span>
        </li> */}
                {/* <li className="flex-column">
          <span className="d-block mr-auto ml-0">Enter maximum bid</span>
          <InputCustom placeholder="&#163;" type="number" onChange={(e) => handleChange(e.target.value)} />
          <img className="icon" src={Icon} alt="icon" />
          <Button onClick={bidReqFun} className="primary btn">Submit bid</Button>
          <Button onClick={buyNowFun} className="primary btn">Buy Now</Button>
        </li> */}
            </ul>
            <Popup onClick={handleThankuPopup} state={popup} heading="Map" btnText="Filter" additionalClass="map_CarGrid">
                <iframe
                    style={{ top: 0, border: '0px', height: 'calc(100vh - 230px)', width: '100%' }}
                    allowfullscreen
                    src={`https://maps.google.com/maps?q=${props?.data?.seller_details?.szPostCode}&output=embed`}
                ></iframe>
            </Popup>
            {/* <h3 className="card-heading">Auction in progress</h3> */}
        </div>
    );
};

export default ProgressCard;
