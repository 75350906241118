import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Path from "../../Constants/Routes";
import "./Role.scss";
import { getUserList } from "../../Services/getUserList";
import { deleteUser } from "../../Services/deleteUser";
import Popup from "../../Components/Common/Popup/Popup";

const SubUser = () => {
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [userList, setUserList] = useState([]);
  const [idEmployee, setIdEmployee] = useState(0);
  
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getUserListFun();
  }, []);

  const getUserListFun = (req) => {
    setLoader(true);
    getUserList(req)
      .then((res) => {
        if (res?.data?.success == true) {

          setUserList(res?.data?.data?.data)
          console.log("res", res?.data?.data?.data);
          setLoader(false);
        } else {
          console.log("Permission list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const deleteUserFun = ()=>{
    setLoader(true);
    let req = {"idEmployee":idEmployee}
    console.log("reqreqreq",req)
    deleteUser(req)
    .then((result)=>{
      getUserListFun({});
      setLoader(false);
      setPopup(false)
    })
    .catch((err) => {
      setLoader(false);
      setPopup(false)
      console.log(err);
    });
  }

  const confirmDelete = (idEmployee)=>{
    setIdEmployee(idEmployee)
    setPopup(true)
  }

  const cancelDelete = ()=>{
    setPopup(false)
  }

  return (
    <div className="x_panel">
      <div className="x_content">
        <div className="addrolebtn">
          <Link className="addrole btn primary" to={Path.addUser}>
            Add User
          </Link>
        </div>
        <div className="dataTables_wrapper">
          <table className="table table-striped">
            <thead>
              <tr role="row">
                <th className="sorting_asc">Sr. No.</th>
                <th className="sorting">Full Name</th>
                <th className="sorting">Email</th>
                <th className="sorting">Role</th>
                <th className="sorting">Create Date</th>
                <th className="sorting">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                userList?.map((value, index) => (
                  <tr role="row" className="odd">
                    <td tabindex="0" className="sorting_1">1</td>
                    <td>{value.fullName}</td>
                    <td>{value.email}</td>
                    <td>{value?.test_business?.business_name}</td>
                    <td>{value.created_at}</td>
                    <td>
                      <Link
                        className="edit btn primary"
                        to ={{
                        pathname: Path.editUser+"/"+value.id, 
                        state: { 
                          initialValues:{}
                          }
                         }}
                        // to={`${Path.editUser}/${value.id}`}
                      >
                        Edit
                  </Link>
                      <button
                        className="delate btn primary"
                        onClick={()=>{confirmDelete(value.id)}}
                      >
                        Delete
                      </button>

                    </td>
                  </tr>
                ))
              }


            </tbody>
          </table>
        </div>
      </div>
      <Popup
        onClick={cancelDelete}
        // continue={continueForm}
        state={popup}
        heading="User"
        btnText="Filter"
        // continueText="Save later"
      >
        <p>Are you sure you want to delete this user ?.</p>
        <button className="btn primary" onClick={cancelDelete}>
          cancel
        </button>
        <button className="btn primary" onClick={deleteUserFun}>
          yes
        </button>
      </Popup>
    </div>
  );
};

export default SubUser;
