import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Path from "../../../Constants/Routes";
import { dealerSignUp } from "../../../Services/dealerSignup";
import Button from "../../Common/Button";
import CheckboxCircle from "../../Common/CheckboxCircle";
import CommonBanner from "../../Common/CommonBanner/CommonBanner";
import Input from "../../Common/Input";
import SelectBox from "../../Common/SelectBox/SelectBox";
import {
  distanceOption,
  noOfVehicleOption,
  positionOption,
} from "./../../../Constants/optionConstant";
// import { dealerSignup } from "../../../Services/";
import "./../../Auth/Auth.scss";
import RadioCircle from "./../../Common/RadioCircle";
import { validate } from "./validate";

function DealerSignup(props) {
  const notify = () => toast("Please enter a all the field");
  const history = useHistory();
  // @dealer signup
  const handleSubmit = () => {
    // if(state.email &&)
    let payload = {
      iIsPrivacyPolicy: props.iIsPrivacyPolicy,
      szName: props.szName,
      szCompany: props.szCompany,
      szEmail: props.szEmail,
      szPhoneNumber: props.szPhoneNumber,
      szPosition: props.szPosition,
      szAddress: props.szAddress,
      szDealerType: props.szDealerType,
      szCompanyPhone: props.szCompanyPhone,
      szVehicleMake: props.szVehicleMake,
      szPriceRangeFrom: props.szPriceRangeFrom,
      szPriceRangeTo: props.szPriceRangeTo,
      szMileageRangeFrom: props.szMileageRangeFrom,
      szMileageRangeTo: props.szMileageRangeTo,
      szCollectVehicle: props.szCollectVehicle,
      szMonthVehicles: props.szMonthVehicles,
      szPassword: props.szPassword,
    };
    dealerSignUp(payload)
      .then((res) => {
        if (res.success === false) {
          notify();
        } else {
          // localStorage.setItem("dealer", res?.data?.data?.idUser || "");
          history.push(Path.home);
        }
      })
      .catch((err) => {
        console.log(err);
        notify();
      });
  };
  return (
    <div className="wrapper">
      <CommonBanner>
        <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
          <h1 className="car-name">Dealer Signup</h1>
        </div>
      </CommonBanner>
      <div className="auth-container-two">
        <div className="head">
          <h2 className="heading">
            <span>Personal Detail</span>
          </h2>
        </div>
        <div className="auth-form-wrapper">
          <div className="form-field w-2col">
            <label>Name</label>
            <Field
              name="szName"
              placeholder="e.g. John Deo"
              type="text"
              component={Input}
              className="width-100"
            />
          </div>
          <div className="form-field w-2col">
            <label>Company</label>
            <Field
              name="szCompany"
              placeholder="e.g. abc LLC"
              type="text"
              component={Input}
              className="width-100"
            />
          </div>
          <div className="form-field w-2col">
            <label>Email</label>
            <Field
              name="szEmail"
              placeholder="e.g. johndeo@gmail.com"
              type="email"
              component={Input}
              className="width-100"
            />
          </div>
          <div className="form-field w-2col">
            <label>Contact Number</label>
            <Field
              name="szPhoneNumber"
              placeholder="e.g. 7777222111"
              type="text"
              component={Input}
              className="width-100"
            />
          </div>
          <div className="form-field w-2col">
            <label>Position</label>
            <Field
              name={"szPosition"}
              type="text"
              component={SelectBox}
              options={positionOption}
              className="width-100"
            />
          </div>
        </div>

        {/* @address detail */}
        <div className="head">
          <h2 className="heading">
            <span>Address Detail</span>
          </h2>
        </div>
        <div className="auth-form-wrapper">
          <div className="form-field w-3col">
            <label>Address</label>
            <Field
              name="szAddress"
              placeholder="e.g. c-13 flat-133 Newyork"
              type="text"
              component={Input}
              className="width-100"
            />
          </div>
          <div className="form-field w-3col">
            <label>Dealer Type</label>
            <Field
              name="szDealerType"
              placeholder="e.g. Dealer Type"
              type="text"
              component={Input}
              className="width-100"
            />
          </div>
          <div className="form-field w-3col">
            <label>Company Contact</label>
            <Field
              name="szCompanyPhone"
              placeholder="e.g. +44 20 8759 9036"
              type="text"
              component={Input}
              className="width-100"
            />
          </div>
        </div>

        {/* @Buying Preferences */}
        <div className="head">
          <h2 className="heading">
            <span>Buying Preferences</span>
          </h2>
        </div>
        <div className="auth-form-wrapper">
          <div className="form-field pre-wrap width-100">
            <label>Which makes do you purchase?</label>
            <Field
              name="szVehicleMake"
              id="yes"
              type="radio"
              component={RadioCircle}
              children="All makes"
              className="radio width-50"
              value="Yes"
            />
            <Field
              name="szVehicleMake"
              id="no"
              type="radio"
              component={RadioCircle}
              children="Specific makes"
              className="radio width-50"
              value="No"
            />
          </div>
          <div className="form-field pre-wrap width-100">
            <label>What is the value of vehicles you typically purchase?</label>
            <div className="pre-wrap-row">
              <div className="pre-wrap-col">
                <Field
                  name="szPriceRangeFrom"
                  placeholder="e.g. From"
                  type="text"
                  component={Input}
                  className=""
                />
              </div>
              <div className="pre-wrap-col">
                <Field
                  name="szPriceRangeTo"
                  placeholder="e.g. To"
                  type="text"
                  component={Input}
                  className=""
                />
              </div>
            </div>
          </div>
          <div className="form-field pre-wrap width-100">
            <label>
              What is the mileage of vehicles you typically purchase?
            </label>
            <div className="pre-wrap-row">
              <div className="pre-wrap-col">
                <Field
                  name="szMileageRangeFrom"
                  placeholder="e.g. From"
                  type="text"
                  component={Input}
                  className=""
                />
              </div>
              <div className="pre-wrap-col">
                <Field
                  name="szMileageRangeTo"
                  placeholder="e.g. To"
                  type="text"
                  component={Input}
                  className=""
                />
              </div>
            </div>
          </div>
          <div className="form-field pre-wrap width-100">
            <label>How far will you travel to collect vehicles?</label>
            <Field
              name={"szCollectVehicle"}
              component={SelectBox}
              options={distanceOption}
              className="width-100"
            />
          </div>
          <div className="form-field pre-wrap width-100">
            <label>How many vehicles do you purchase each month?</label>
            <Field
              name={"szMonthVehicles"}
              component={SelectBox}
              options={noOfVehicleOption}
              className="width-100"
            />
          </div>
          <div className="form-field width-100">
            <label>Password</label>
            <Field
              name="szPassword"
              placeholder="Password"
              type="password"
              component={Input}
              className="width-100"
            />
          </div>
          <div className="form-field width-100">
            <Field
              name="iIsPrivacyPolicy"
              id="term2"
              component={CheckboxCircle}
              className="circle-radio"
              children="I have read the Privacy Policy and accept the Terms & Conditions."
              value={false}
            />
          </div>
          <div className="width-100">
            <Button className="primary m-0 width-100" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} />;
    </div>
  );
}

const selector = formValueSelector("dealerSignup");
DealerSignup = reduxForm({
  // a unique name for the form
  form: "dealerSignup",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(DealerSignup);

export default connect((state) => {
  // can select values individually
  const iIsPrivacyPolicy = selector(state, "iIsPrivacyPolicy");
  const szName = selector(state, "szName");
  const szCompany = selector(state, "szCompany");
  const szEmail = selector(state, "szEmail");
  const szPhoneNumber = selector(state, "szPhoneNumber");
  const szPosition = selector(state, "szPosition");
  const szAddress = selector(state, "szAddress");
  const szDealerType = selector(state, "szDealerType");
  const szCompanyPhone = selector(state, "szCompanyPhone");
  const szVehicleMake = selector(state, "szVehicleMake");
  const szPriceRangeFrom = selector(state, "szPriceRangeFrom");
  const szPriceRangeTo = selector(state, "szPriceRangeTo");
  const szMileageRangeFrom = selector(state, "szMileageRangeFrom");
  const szMileageRangeTo = selector(state, "szMileageRangeTo");
  const szCollectVehicle = selector(state, "szCollectVehicle");
  const szMonthVehicles = selector(state, "szMonthVehicles");
  const szPassword = selector(state, "szPassword");
  return {
    iIsPrivacyPolicy,
    szName,
    szCompany,
    szEmail,
    szPhoneNumber,
    szPosition,
    szAddress,
    szDealerType,
    szCompanyPhone,
    szVehicleMake,
    szPriceRangeFrom,
    szPriceRangeTo,
    szMileageRangeFrom,
    szMileageRangeTo,
    szCollectVehicle,
    szMonthVehicles,
    szPassword,
  };
})(DealerSignup);
