import React from "react";

function SelectBox({
  input,
  className,
  options,
  name,
  meta: { touched, error, warning },
}) {
  return (
    <>
      <select
        {...input}
        name={name}
        className={`input ${className} ${touched && error && "input-error"}`}
      >
        {options.map((item, index) => (
          <option disabled={item.disabled} value={item.value} key={index}>
            {item.label}
          </option>
        ))}
      </select>
      {/* {touched && error && <span className="error-custom">{error}</span>} */}
    </>
  );
}

export default SelectBox;
