import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import Input from "../../../Common/Input";
import RadioCircle from "../../../Common/RadioCircle";
import TextArea from "../../../Common/TextArea/TextArea";
import { normalizeNumber, validate } from "./validate";

function Index(props) {
  const [state, setstate] = useState();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  console.log("selector------", props.formValues);
  return (
    <div className="steps-form-details">
      <h3 className="heading">
        <span>Service history</span>
      </h3>
      <div className="form-wrapper">
        <div className="d-flex custom-margin">
          <div className="col-50">
            <div className="form-field ">
              <h3 className="field-label">
                What is the service history of your car?
              </h3>
              <ul className="list-style-none radio-list">
                <li className="item">
                  <Field
                    name={"szServiceHistoryCar"}
                    id="serviceHistoryFull"
                    type="radio"
                    component={RadioCircle}
                    className="radio width-100"
                    // props={{ value: "one" }}
                    value="1"
                    children="Full service history"
                  />
                </li>
                <li className="item">
                  <Field
                    name={"szServiceHistoryCarp"}
                    id="serviceHistoryFullp"
                    type="radio"
                    component={RadioCircle}
                    className="radio width-100"
                    // props={{ value: "one" }}
                    value="4"
                    children="Partial service history"
                  />
                </li>
                <li className="item">
                  <Field
                    name={"szServiceHistoryCar"}
                    type="radio"
                    id="serviceHistoryNoService"
                    component={RadioCircle}
                    className="radio width-100"
                    value="2"
                    children="No service history"
                  />
                </li>
                <li className="item">
                  <Field
                    name={"szServiceHistoryCar"}
                    id="serviceHistoryNotYet"
                    type="radio"
                    component={RadioCircle}
                    className="radio width-100"
                    value="3"
                    children="Not yet due first service"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-50">
            <div className="form-field">
              <h3 className="field-label">Do you have the service record?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bServiceRecord"}
                  id="serveceRecordYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value="1"
                  type="radio"
                  children="Yes"
                />
                <Field
                  name={"bServiceRecord"}
                  id="serveceRecordNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  value="0"
                  type="radio"
                />
              </div>
              {props.bServiceRecord === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szServiceRecordDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field">
              <h3 className="field-label">
                Manufacturer or official dealer services:
              </h3>
              <Field
                name={"szManufacturer"}
                type="number"
                component={Input}
                placeholder={"e.g. 2"}
                className="width-100"
                normalize={normalizeNumber}
              />
            </div>
          </div>
          <div className="col-50">
            <div className="form-field">
              <h3 className="field-label">Independent garage services:</h3>
              <Field
                name={"szIndependentGarageServices"}
                type="number"
                component={Input}
                placeholder={"e.g.2"}
                className="width-100"
                normalize={normalizeNumber}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field">
              <h3 className="field-label">
                When was the vehicle last serviced?
              </h3>
              {/* <Field
                name={"dtVehicleLastServiced"}
                component={renderDatePicker}
                className="width-50"
              /> */}
              <Field
                name={"dtVehicleLastServiced"}
                type="date"
                component={Input}
                placeholder={"e.g. YYYY-MM-DD"}
                className="width-100"
              />
            </div>
          </div>
          <div className="col-50">
            <div className="form-field">
              <h3 className="field-label">
                What was the mileage at the last service?
              </h3>
              <Field
                name={"szLastServiceMileage"}
                type="number"
                component={Input}
                placeholder={"e.g. 12345"}
                className="width-100"
                normalize={normalizeNumber}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field">
              <h3 className="field-label">
                Has the cambelt ever been changed?
              </h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bCambeltChanged"}
                  id="cambeltYes"
                  component={RadioCircle}
                  className="radio-button width-50"
                  value={1}
                  type="radio"
                  children="Yes"
                />
                <Field
                  name={"bCambeltChanged"}
                  id="cambeltNo"
                  component={RadioCircle}
                  className="radio-button width-50"
                  children="No"
                  type="radio"
                  value={0}
                />
              </div>
              {props.bCambeltChanged === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szCambeltChangedDescription"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Type description..."
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selector = formValueSelector("step2");
Index = reduxForm({
  // a unique name for the form
  form: "step2",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(Index);

export default connect((state) => {
  // can select values individually
  const formValues = state?.form["step2"]?.values;
  const bServiceRecord = selector(state, "bServiceRecord");
  const bCambeltChanged = selector(state, "bCambeltChanged");
  return {
    bServiceRecord,
    bCambeltChanged,
    formValues,
  };
})(Index);
