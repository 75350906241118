import React from "react";
import "./Input.scss";

const InputCustom = ({
  value,
  type,
  placeholder,
  className,
  onChange,
  disable = false,
  handleKeyPress,
  ...rest
}) => {
  return (
    <input
      className={`input ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disable}
      type={type}
      value={value}
      onKeyPress={handleKeyPress}
      {...rest}
    />
  );
};

export default InputCustom;
