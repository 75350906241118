import React from "react";
import "./RadioButton.scss";

const RadioButton = ({
  className,
  children,
  id,
  checked,
  value,
  input,
  meta,
}) => {
  return (
    <div className={`radio-button ${className}`}>
      <input {...input} type="radio" id={id} />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export default RadioButton;
