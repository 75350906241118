import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { validate } from "../../Components/Auth/SellerSignup/validate";
import Button from "../../Components/Common/Button";
import Input from "../../Components/Common/Input";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import { updateUsername, store } from "../../Helpers/Helpers";
import Path from "../../Constants/Routes";

import { updateDetails } from "../../Services/UpdateDetails";
import "./Role.scss";

let AddRole = (props) => {
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  const sellerUpdate = (values) => {
    validate(values);
    let payload = props.formValues;

    setLoader(true);

    updateDetails(payload)
      .then((res) => {

        setTimeout(() => {
          setLoader(false);
          setPopup(true);
        })
      })
      .catch((err) => {
        setLoader(false);
      });
    // if(state.email &&)
  };
  const handlePopup = () => {
    setPopup(!popup);
  };
  const { handleSubmit, pristine, reset, submitting } = props;

  return (
    <div className="x_panel">
      {loader && <LoaderWrapper />}
      <div className="x_content">
        <form className="form-horizontal form-label-left" onSubmit={handleSubmit(sellerUpdate)} >
          <div className="item form-group bad">
            <label className="control-label" for="name">Role Name <span className="required">*</span>
            </label>
            <div className="control">
              <Field
                name="szRoleName"
                type="text"
                component={Input}
                className="form-control"
                placeholder="Role Name"
              // normalize={normalizePostCode}
              />

            </div>
          </div>
          <div className="form-group">
            <div className="">

              <Button className="addrole btn primary" type="submit">
                Submit
</Button>
              <Link className="addrole btn primary" to={Path.role}>
                Cancel
</Link>

            </div>
          </div>
        </form>
      </div>
      <Popup
        onClick={handlePopup}
        // continue={continueForm}
        state={popup}
        heading="Role"
        btnText="Filter"
      // continueText="Save later"
      >
        <p>Role has been updated successfully.</p>
        <button className="btn primary" onClick={handlePopup}>
          Ok
        </button>
      </Popup>
    </div>

  );
};


AddRole = reduxForm({
  // a unique name for the form
  form: "AddRole",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(AddRole);

export default connect((state) => {
  const formValues = state?.form["AddRole"]?.values;
  return {
    formValues,
    state,
  };
  // return state;
})(AddRole);