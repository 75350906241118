import Api from "../config/Api";
import {
    BackendApiInstance
} from "../config/AxiosInstances";

export const UploadFile = (file, name) => {
    var frmData = new FormData();
    frmData.append("file", file);
    frmData.append("name", name);

    return BackendApiInstance.post(Api.fileUploadToApiServerUrl, frmData)
        .then(res => {
            return {
                ...res,
                key: name
            };
        });
}
