import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { required, validate } from "../../Components/Auth/SellerSignup/validate";
import Button from "../../Components/Common/Button";
import Input from "../../Components/Common/Input";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import { updateUsername, store } from "../../Helpers/Helpers";
import Path from "../../Constants/Routes";

import { updateDetails } from "../../Services/UpdateDetails";
import "./Role.scss";
import { getRoles } from "../../Services/getRoles";
import SelectBox from "../../Components/Common/SelectBox/SelectBox";
import { addEmployee } from "../../Services/addUser";

let AddUser = (props) => {
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState(false);
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    getRoleList();
  }, []);
  
  const getRoleList = (req) => {
    setLoader(true);
    getRoles(req)
      .then((res) => {
        if (res?.data?.success == true) {
          let arRole = res?.data?.data?.data;
          arRole?.map((item, index) => {
            item['value'] = item.business_id;
            item['label'] = item.business_name;
          })
          setRoleList(arRole);
          console.log("res", res?.data?.data?.data);
          setLoader(false);
        } else {
          console.log("Permission list");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  

  const addUser = (values) => {
    validate(values);
    console.log("addUser", values)
    let req = values;
    setLoader(true);
    addEmployee(req)
    .then(()=>{
      setPopup(true)
      setLoader(false);
    }).catch((err) => {
      console.log(err);
      setLoader(false);
    });
  }

  const handlePopup = () => {
    setPopup(!popup);
  };
  const { handleSubmit, pristine, reset, submitting } = props;

  return (
    <div className="x_panel">
      {loader && <LoaderWrapper />}
      <div className="x_content">
        <form onSubmit={handleSubmit(addUser)} className="form-horizontal form-label-left"  >
          <div className="item form-group bad">
            <label className="control-label" for="name">Full Name <span className="required">*</span>
            </label>
            <div className="control">
              <Field
                name="fullName"
                type="text"
                component={Input}
                className="form-control"
                placeholder="Full Name"
                // normalize={normalizePostCode}
                validate={[required]}
              />
            </div>

            <label className="control-label" for="name">Email <span className="required">*</span>
            </label>
            <div className="control">
              <Field
                name="email"
                type="text"
                component={Input}
                className="form-control"
                placeholder="Email"
                // normalize={normalizePostCode}
                validate={[required]}
              />
            </div>

            <label className="control-label" for="name">Mobile <span className="required">*</span>
            </label>
            <div className="control">
              <Field
                name="mobile"
                type="text"
                component={Input}
                className="form-control"
                placeholder="Mobile"
                // normalize={normalizePostCode}
                validate={[required]}
              />
            </div>


            <label className="control-label" for="name">Password <span className="required">*</span>
            </label>
            <div className="control">
              <Field
                name="password"
                type="password"
                component={Input}
                className="form-control"
                placeholder="Password"
                // normalize={normalizePostCode}
                validate={[required]}
              />
            </div>


            <label className="control-label" for="name">Status <span className="required">*</span>
            </label>
            <div className="control">
              <Field
                name="status"
                type="text"
                component={SelectBox}
                className="form-control"
                placeholder="Status"
                options={[{value:0,label:"Active"},{value:1,label:"In Active"}]}
                // normalize={normalizePostCode}
                
              />
            </div>


            <label className="control-label" for="name">Role     <span className="required">*</span>
            </label>
            <div className="control">

              <Field
                name="user_access_group"
                type="number"
                component={SelectBox}
                className="form-control"
                placeholder="Role"
                options={roleList}
                 
              />


            </div>
          </div>
          <div className="form-group">
            <div className="">

              <Button className="addrole btn primary" type="submit">
                Submit
              </Button>
              <Link className="addrole btn primary" to={Path.subUser}>
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </div>
      <Popup
        onClick={handlePopup}
        // continue={continueForm}
        state={popup}
        heading="Role"
        btnText="Filter"
      // continueText="Save later"
      >
        <p>User added  successfully.</p>
        <button className="btn primary" onClick={handlePopup}>
          Ok
        </button>
      </Popup>
    </div>

  );
};


AddUser = reduxForm({
  // a unique name for the form
  form: "AddUser",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(AddUser);

export default connect((state) => {
  const formValues = state?.form["AddUser"]?.values;
  return {
    formValues,
    state,
  };
  // return state;
})(AddUser);