import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Tooltip from "react-simple-tooltip";
import { change, Field, reduxForm } from "redux-form";
import { s3URL } from "../../../Constants/constants";
import Path from "../../../Constants/Routes";
import { searchCar } from "../../../Services/searchCar";
import { completeSellerSignup } from "../../../Services/selletSignup";
import Button from "../../Common/Button";
import CheckboxCircle from "../../Common/CheckboxCircle";
import CommonBanner from "../../Common/CommonBanner/CommonBanner";
import Input from "../../Common/Input";
import LoaderWrapper from "../../Common/Loader/Loader";
import Popup from "../../Common/Popup/Popup";
import InfoIcon from "./../../../Assets/Icons/info-icon.png";

import "./SellerSignup.scss";
import { required, validate } from "./validate";

function SellerSignup(props) {
  let { slug } = useParams();
  const history = useHistory();
  const [carDetail, setCarDetail] = useState([]);
  const [emailExistPopup, setEmailExistPopup] = useState(false);
  const [vehicleNotExist, setVehicleNotExist] = useState(false);
  const [vehicleAlreadyExist, setVehicleAlreadyExist] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {

    let homePgState = localStorage.getItem("_#homePageState#_");
    if(homePgState){
      homePgState = JSON.parse(homePgState);
      props.dispatch(change("sellerSignup", "szFullName", homePgState.name));   
      props.dispatch(change("sellerSignup", "szEmail", homePgState.email));      
      props.dispatch(change("sellerSignup", "szPhoneNumber", homePgState.ph));
      props.dispatch(change("sellerSignup", "iIsPrivacyPolicy", homePgState.privacy));
      props.dispatch(change("sellerSignup", "sellerid", localStorage.getItem("_#sellerid#_")));
    }      

    const vehDetFromHomePage = localStorage.getItem("_#vehicleDetailsFetched#_");
    if(vehDetFromHomePage){
      triggerAndSet(JSON.parse(vehDetFromHomePage));
    }
    else{
      getCarDetail(slug);
    }
  }, []);

  // email already exist
  const handlePopup = () => {
    setEmailExistPopup(false);
    localStorage.setItem("already-exist", "emailExist");
    localStorage.setItem("szEmail", props.formValues.szEmail);
    history.push(Path.login);
  };

  // handlePopupAlreadyVehicle
  const handlePopupAlreadyVehicle = () => {
    setVehicleAlreadyExist(false);
    history.push(Path.home);
  };

  // vehicle No invalid
  const handleInvalidVehicle = () => {
    setVehicleNotExist(false);
    history.push(Path.home);
  };

  const triggerAndSet = vehDetails=>{
    if (vehDetails) {
      setCarDetail(vehDetails);
      let mileage = "";
      if(localStorage.getItem("mileage")){
         mileage = localStorage.getItem("mileage") || "";
      }else{
         mileage = vehDetails?.mileageDetails || "";
      }
      props.dispatch(change("sellerSignup", "szMileage", mileage));      
    } else {
      setVehicleNotExist(true);
    }
    setLoader(false);
  }

  // @car detail
  const getCarDetail = (slug) => {
    searchCar(slug)
      .then((res) => {
        triggerAndSet(res?.data?.data)        
      })
      .catch((err) => {
        // this.props.hideLoader();
        if (err?.success === false) {
          setLoader(false);
          setVehicleNotExist(true);
        } else {
          console.log(err);
        }
      });
  };

  // @seller signup
  const sellerSignup = (values) => {
    if (!props.formValues["iIsPrivacyPolicy"]) {
      alert("please check privacy policy");
    } else {
      validate(values);

      if (props.szMileage == "") {
        alert("enter all detail");
      }
      if (props.szFullName == "") {
        alert("enter full name");
      } else {
        // let szThinkingOfSelling = state.szThinkingOfSelling;
        let szVehicleDetails = JSON.stringify(carDetail); //.replace(/"/g, "'")
        let szVehicleNumber = slug;
        props.formValues["szThinkingOfSelling"] = '7 days';
        props.formValues["szVehicleDetails"] = szVehicleDetails;
        props.formValues["szVehicleNumber"] = szVehicleNumber;        
        props.formValues['iIsNotification'] = !! props.formValues['iIsNotification'];        
        let payload = props.formValues;
        console.log("payload",payload)
        setLoader(true);
        completeSellerSignup(payload)
          .then((res) => {
            if(res?.data?.success===true){
              localStorage.setItem("token", localStorage.getItem("temp_token"));
              localStorage.removeItem("temp_token");
              window.gtag('event', 'conversion', {'send_to': 'AW-318878216/s_dGCPWz4OoCEIjkhpgB'});
              history.push(Path.carDetail);
            }            
          })
          .catch((err) => {
            alert(err?.message);
            console.log(err);
            if (err?.message == "Email already exist.") {
              setEmailExistPopup(true);              
            }
            setLoader(false);
          });
      }
    }
    // if(state.email &&)
  };

  const vali = true;

  const mileage = carDetail?.mileageDetails || "";
  const carColor = carDetail?.colour;
  const manufacturer = carDetail?.make;
  // const DvlaModel = carDetail?.wheelplan;
  const fuel = carDetail?.fuelType;
  const modalYear = carDetail?.yearOfManufacture;
  const bodyStyle = carDetail?.wheelplan;
  const engineDescription = carDetail?.registrationNumber;
  const { handleSubmit, pristine, reset, submitting } = props;

  return (
    <>
      <div className="car-informaion">
        {loader && <LoaderWrapper />}
        <CommonBanner>
          <div className="icon">
            <img src={s3URL + carDetail?.make?.toLowerCase()+'-logo.png'} alt="car logo" />
          </div>
          <div className="car-detail">
            <h2 className="car-model">{engineDescription}</h2>
            <div className="cg-row">
            <h1 className="car-name">
              {manufacturer}
              {/* {DvlaModel} */}
            </h1>
            <div className="carmodel">
            <span>{carDetail?.model}</span>
            </div>
            </div>
            <div className="detail">
              <span className="info">{modalYear}</span>
              <span className="info">{carColor}</span>              
              <span className="info">{fuel}</span>
              {/* <span className="info">Change car</span> */}
            </div>
          </div>
        </CommonBanner>
        <div className="container-wrapper-main">
          <div className="container-body custom-scrollbar">
            <div className="container-md">                
              <h2 className="heading">Enter details to Submit for auction</h2>
              <form onSubmit={handleSubmit(sellerSignup)}>
                <div className="form-wrapper">              
                  <div className="form-field">
                    <div className="label">
                      <label>Full Name</label>
                      <span className="field-info ml-auto"></span>
                      <Tooltip content="Full Name">
                        <img src={InfoIcon} className="field-info-icon" />
                      </Tooltip>
                    </div>
                    <div className="input-control">
                      <Field
                        name="szFullName"
                        type="text"
                        component={Input}
                        placeholder="Your full name"
                        className="width-100"
                        validate={[required]}
                      />
                    </div>
                  </div>
                  <div className="form-field">
                    <div className="label">
                      <label>Email</label>
                      <span className="field-info ml-auto"></span>
                      <Tooltip content="Email">
                        <img src={InfoIcon} className="field-info-icon" />
                      </Tooltip>
                    </div>
                    <div className="input-control">
                      <Field
                        name="szEmail"
                        placeholder="Enter your email"
                        type="text"
                        component={Input}
                        className="width-100 exist-email"
                        // validate={[required, email]}
                      />
                    </div>
                  </div>
                  <div className="form-field">
                    <div className="label">
                      <label>Phone number</label>
                      <span className="field-info ml-auto"></span>
                      <Tooltip content="Phone number">
                        <img src={InfoIcon} className="field-info-icon" />
                      </Tooltip>
                    </div>
                    <div className="input-control">
                      <Field
                        name="szPhoneNumber"
                        placeholder="e.g. 07777 111222"
                        type="number"
                        pattern="[0-9]"
                        component={Input}
                        className="width-100"
                        // validate={[required, phone]}
                        // normalize={
                        //   props.szMileage >= 18000 ? normalizePostCode : null
                        // }
                      />
                    </div>
                  </div>
                  <div className="form-field">
                    <div className="label">
                      <label>Postcode</label>
                      <span className="field-info ml-auto"></span>
                      <Tooltip content="Postcode">
                        <img src={InfoIcon} className="field-info-icon" />
                      </Tooltip>
                    </div>
                    <div className="input-control">
                      <Field
                        name="szPostCode"
                        placeholder="e.g. NW12LS"
                        type="text"
                        component={Input}
                        className="width-100 text-uppercase"
                        // normalize={normalizePostCode}
                      />
                    </div>
                  </div>              
                  <div
                    className="form-field width-100 "
                    style={{ marginBottom: "30px" }}
                  >
                    {/* <Field
                      name="iIsPrivacyPolicy"
                      id="term1"
                      component={CheckboxCircle}
                      className="circle-radio"
                      children={`I have read the Privacy Policy and accept the Terms. I understand
                  I will receive valuations and buyers may need to get in touch.
                  Find out more`}
                      label="Required"
                    /> */}

                    <Field
                      name="iIsNotification"
                      id="term2"
                      component={CheckboxCircle}
                      className="circle-radio"
                      children=" I'd like to receive email updates and insanely great offers from CarGo"
                      label="Optional"
                    />
                  </div>
                  <Button className="primary" type="submit">
                  Sign Up
                  </Button>
                  {/* <button type="submit" className="primary">See valuation</button> */}
                </div>
              </form>
            </div>      
          </div>
        </div>
      </div>      
      <Popup
        onClick={handlePopup}
        state={emailExistPopup}
        heading="User Registered"
        btnText="Filter"
      >
        <h3>
          This email is already registered, please click the Login button below
          to login.
        </h3>
        <button className="btn primary" onClick={handlePopup}>
          Login
        </button>
      </Popup>

      {/* vehicle already in aution */}
      <Popup
        onClick={handlePopupAlreadyVehicle}
        state={vehicleAlreadyExist}
        heading="Vehicle"
        btnText="Filter"
      >
        <h3>Vehicle already registered or in auction.</h3>
        <button className="btn primary" onClick={handlePopupAlreadyVehicle}>
          ok
        </button>
      </Popup>

      {/* invalid vehicle */}
      <Popup
        onClick={handleInvalidVehicle}
        state={vehicleNotExist}
        heading="Invalid Vehicle"
        btnText="Filter"
      >
        <h3>Invalid registration number.</h3>
        <button className="btn primary" onClick={handleInvalidVehicle}>
          Ok
        </button>
      </Popup>
    </>
  );
}

// const selector = formValueSelector("sellerSignup");
SellerSignup = reduxForm({
  // a unique name for the form
  form: "sellerSignup",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(SellerSignup);

export default connect((state) => {
  const formValues = state?.form["sellerSignup"]?.values;
  return {
    formValues,
    state,
  };
  // return state;
})(SellerSignup);
