import React from "react";
import CurrencyFormat from "react-currency-format";
import LogoBanner from "../../../Assets/Images/CARGO_BLK_LOGO.png";
import "./CommonBanner.scss";



const CommonBanner = ({ CarLogo, className, children, carDetail }) => {
  return (
    <div className="common-banner">
      <div className="common-container">
        <div className="common-container-inner">{children}
          { carDetail?.guideprice?.getjsondata && <div className="gideprice">
              <div className="gideprice-inner">
                <div className="gideprice-cls">                  
                  <CurrencyFormat
                      value={carDetail?.guideprice?.getjsondata?.TradeAverage}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"£"}
                    />
                </div>
                <div className="gideprice-logo">
                  <span>
                  <img
                          className="company-team"
                          src={LogoBanner}
                          alt="companies team"
                        />
                  </span>
                  <p>Guide Price</p>
                </div>
              </div>
            </div>          
          }
        </div>
      </div>
    </div>
  );
};

export default CommonBanner;
