import React from "react";
import { Link } from "react-router-dom";
import "./WrittenOff.scss";
import Button from "../Button";

const WrittenOff = () => {
  return (
    <div className="writtenoff">
        <div className="Form__writeOffZIndex Form__writeOffExplaination">
        <p>Unfortunately our Premium Service is not available for cars that are an 
        insurance write-off. This includes cars that are category A, B, C, D, N, S or U.</p>
        <p>Please select a different buyer to continue with your sale.</p>        
              <Link className="primary btn returntohome" to="/"> Return to Home</Link>
        </div>
       
    </div>
  );
};

export default WrittenOff;
