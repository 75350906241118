import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { dealerLogin } from "../../../Services/dealerLogin";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import Path from "./../../../Constants/Routes";
import "./../../Auth/Auth.scss";
import { validate } from "./validate";

function DealerLogin(props) {
  const [message, setMessage] = useState("");
  const history = useHistory();
  const notify = () => toast("Please enter a valid credential");
  // @api call
  const handleLogin = () => {
    if (props.szEmail !== "" && props.szPassword !== "") {
      let payload = {
        szEmail: props.szEmail,
        szPassword: props.szPassword,
      };
      dealerLogin(payload)
        .then((res) => {
          if (res.success === false) {
            notify();
          } else {
            history.push(Path.home);
          }
        })
        .catch((err) => {
          setMessage(err.message);
          notify();
        });
    } else {
      console.log("enter valid credential");
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form-wrapper">
        <div className="form-field">
          <label>Email</label>
          <Field
            name="szEmail"
            placeholder="e.g. johndeo@gmail.com"
            type="email"
            component={Input}
            className="width-100"
          />
        </div>
        <div className="form-field">
          <label>Password</label>
          <Field
            name="szPassword"
            placeholder="password"
            type="password"
            component={Input}
            className="width-100"
          />
        </div>
        <Button className="primary width-100" onClick={handleLogin}>
          Submit Email
        </Button>
      </div>
      <ToastContainer autoClose={3000} />;
    </div>
  );
}

const selector = formValueSelector("dealerLogin");
DealerLogin = reduxForm({
  // a unique name for the form
  form: "dealerLogin",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(DealerLogin);

export default connect((state) => {
  // can select values individually
  const szEmail = selector(state, "szEmail");
  const szPassword = selector(state, "szPassword");
  return {
    szEmail,
    szPassword,
  };
})(DealerLogin);
