import { BackendApiInstance, CarSearchApiInstance } from "./AxiosInstances";

// request interceptor
BackendApiInstance.interceptors.request.use(
  (request) => {
    let token = localStorage.getItem("token");
    // console.log("tokentoken",token)
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["authorization"] = `Basic ${token}`;
      headers["x-auth-token"] = token;
    }
    request.headers = headers;
    return request;
  },
  (error) => Promise.reject(error)
);

//on successful response
BackendApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      //   redirectUnAuth(error.response);
      return Promise.reject({...error.response.data, httpStatusCode: error.response?.status});
    }
    if (error.message === "Network Error") {
      // network error
      const networkError = {
        errorCodeList: ["NETWORK"],
      };
      return Promise.reject(networkError);
    } else if (error.message === `timeout of 3 ms exceeded`) {
      // timeout error
      const timeoutError = {
        errorCodeList: ["TIMEOUT"],
      };
      return Promise.reject(timeoutError);
    }
    return Promise.reject(error);
  }
);

export { BackendApiInstance, CarSearchApiInstance };
