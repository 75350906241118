import React, { useEffect } from 'react';
import processList1 from '../../Assets/Images/process-list1.png';
import processList2 from '../../Assets/Images/process-list2.png';
import processList3 from '../../Assets/Images/process-list3.png';
import processList4 from '../../Assets/Images/process-list4.png';
import './SellingProcess.scss';

const SellingProcess = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
    }, []);
    return (
        <div className="selling-process">
            {/* <CommonBanner>
        <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
          <h1 className="car-name">Selling Process</h1>
        </div>
      </CommonBanner> */}
            <div className="container-process custom-scrollbar">
                <div className="process-title">
                    <h1 className="heading">Selling Made Simple with CarGo</h1>
                </div>
                <div className="process-wrapper">
                    <div className="process-main">
                        <div className="process-lft">
                            <div className="process-list">
                                <span className="list-number">1.</span>
                                <div className="process-box">
                                    <img className="process-team" src={processList1} alt="Process List 1" />
                                    <div className="process-box-contant">
                                        <h4>Reg and mileage</h4>
                                        {/* <p>
            <strong>Receive your Let The CarGoGuide Price</strong>
            </p> */}
                                        <p>
                                            Enter your registration number and current mileage to receive an initial guide price based on the latest valuation
                                            data.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="process-list mobileview">
                                <span className="list-number">2.</span>
                                <div className="process-box">
                                    <img className="process-team" src={processList2} alt="Process List 1" />
                                    <div className="process-box-contant">
                                        <h4>Confirm vehicle details</h4>
                                        {/* <p>
            <strong>Agree price with the Let The CarGoTeam</strong>
            </p> */}
                                        <p>
                                            Tell us about your vehicle’s features and send your photos using the online form and we’ll take a quick look again
                                            at the guide price. High-specification qualities may lead to us revising the retail value and increasing the guide
                                            price to guarantee you the greatest payout.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="process-list">
                                <span className="list-number">3.</span>
                                <div className="process-box">
                                    <img className="process-team" src={processList3} alt="Process List 1" />
                                    <div className="process-box-contant">
                                        <h4>Welcome call</h4>
                                        {/* <p>
            <strong>Dealer offers you a price</strong>
            </p> */}
                                        <p>
                                            Our customer support team will give you a call to ensure you are comfortable with the process and discuss a reserve
                                            price.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process-rgt">
                            <div className="process-list desktopview">
                                <span className="list-number">2.</span>
                                <div className="process-box">
                                    <img className="process-team" src={processList2} alt="Process List 1" />
                                    <div className="process-box-contant">
                                        <h4>Confirm vehicle details</h4>

                                        <p>
                                            Tell us about your vehicle’s features and send your photos using the online form and we’ll take a quick look again
                                            at the guide price. High-specification qualities may lead to us revising the retail value and increasing the guide
                                            price to guarantee you the greatest payout.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="process-list">
                                <span className="list-number">4.</span>
                                <div className="process-box">
                                    <img className="process-team" src={processList4} alt="Process List 1" />
                                    <div className="process-box-contant">
                                        <h4>Sell your car</h4>

                                        <p>
                                            With a reserve agreed, we’ll put your car into the next available auction where our approved group of dealers can
                                            bid throughout the day. We’ll inform you of the best offer received, facilitate direct payment from the buyer and
                                            arrange to have your car collected with no additional fees.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bt-contant">
                        <h3>Other information</h3>
                        <p>
                            Please ensure the information submitted is accurate to prevent any handover issues.<br></br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellingProcess;
