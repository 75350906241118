import React, { useEffect, useState } from "react";
import S3 from "aws-s3";
import { s3URL } from "../../../../Constants/constants";
import { UploadFile } from "../../../../Services/UploadFileToApiServer";

const config = {
  bucketName: "cargofront",
  // dirName: "media" /* optional */,
  region: "ap-south-1",
  accessKeyId: "AKIAUOI2YXDKSM7D3B6Z",
  secretAccessKey: "MF0+bCDgeR9yektULsOsd/i1t68GjbYa9eEoua6R",
  // s3Url: "https:/your-custom-s3-url.com/" /* optional */,
};

const S3Client = new S3(config);


const imageSizeLimit = { height: 600, width: 800 };
const getResizedBlob = (selectedFile, cb) => {
  const can = document.createElement('canvas');
  document.body.append(can);
  can.style.display = 'none';

  var ctx = can.getContext('2d');
  var img = new Image();  
  img.onload = function () {
    let ch = img.height;
    let cw = img.width;    
    if (ch > imageSizeLimit.height || cw > imageSizeLimit.width) {
      if (ch > cw) {
        const ratio = imageSizeLimit.height / ch;
        cw = cw * ratio;
        ch = imageSizeLimit.height;
      } else {
        const ratio = imageSizeLimit.width / cw;
        ch = ch * ratio;
        cw = imageSizeLimit.width;
      }
    }
    if(ch > imageSizeLimit.height) {
      const ratio = imageSizeLimit.height / ch;
        cw = cw * ratio;
        ch = imageSizeLimit.height;
    }
    if( cw > imageSizeLimit.width){
      const ratio = imageSizeLimit.width / cw;
        ch = ch * ratio;
        cw = imageSizeLimit.width;
    }
    can.height = ch;
    can.width = cw;
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, cw, ch);
    const dataUrl = can.toDataURL('image/jpeg', 1);
    document.body.removeChild(can);
    cb(dataURLtoBlob(dataUrl));
  }
  img.src = URL.createObjectURL(selectedFile);
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}


const UploadShowImage = ({ file, newFileName, onUpload, index, onDelete }) => {
  const [src, setSrc] = useState();

  useEffect(() => {
    if (file instanceof File) {
      upload(file);
    } else {
      setSrc(file);
    }
  }, [file]);

  const upload = (file) => {
    getResizedBlob(file, (fileBlob) => {
      const ext = file.name.substr(file.name.lastIndexOf('.'));

      UploadFile(fileBlob, `${newFileName}${ext}`)
      .then((data) => {
            if (onUpload) onUpload({ url: data.key, index });
    
            setSrc(data.key);
          })
          .catch((err) => {
            alert("something went wrong");
          });
      });

    // getResizedBlob(file, (fileBlob)=>{    
    // S3Client.uploadFile(fileBlob, newFileName)
    //   .then((data) => {
    //     if (onUpload) onUpload({ url: data.key, index });

    //     setSrc(data.key);
    //   })
    //   .catch((err) => {
    //     alert("something went wrong");
    //   });
    // });
  };

  const onClickingDelete = ()=>{
    //S3Client.deleteFile(src).then(r=>console.log(`File deleted from S3:`, r));
    !isNaN(index) ? onDelete(index) : onDelete();
  }

  return (
    <div className="image-multi-item">
      {src ? (
        <div className="image-cropper">
          <img src={`${s3URL}${src}`} alt="avatar" className="profile-pic" />
        </div>
      ) : null}
      <button
        className="image_input_delete"
        onClick={onClickingDelete}
        type="button"
      >
        x
      </button>
    </div>
  );
};

export default UploadShowImage;
