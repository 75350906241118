import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CommonBanner from "../../Components/Common/CommonBanner/CommonBanner";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import { setToken, store } from "../../Helpers/Helpers";
import StepForms from "../../Components/shared/forms/step-forms";
import { s3URL,carGoURL } from "../../Constants/constants";
import Path from "../../Constants/Routes";
import { getVehicleDetail } from "../../Services/getVehicleDetails";
import { searchCar } from "../../Services/searchCar";
import CarLogo from "./../../Assets/Images/car-logo.png";
import "./CarDetail.scss";

const CarDetail = (heading) => {
  const history = useHistory();
  const [carDetail, setCarDetail] = useState([]);
  const [loader, setLoader] = useState(true);
  const [vehicleNotExist, setVehicleNotExist] = useState(false);
  const location = useLocation();
  const search = location.search;
  const idVehicle = new URLSearchParams(search).get("id");
  const step = new URLSearchParams(search).get("s");
  const st = new URLSearchParams(search).get("st");

  useEffect(() => {
    if(idVehicle){
      vehicleDetails();
    }else{
      const carNo = JSON.parse(localStorage.getItem("objVehicleDetails")||"{}").szVehicleNumber;
      if(carNo){
        getCarDetail(carNo);
      }
      else{
        setVehicleNotExist(true); 
      }
    }
    
  }, []);

  const getCarDetail = (carNo) => {
    searchCar(carNo)
      .then((res) => {
        if (res?.success !== false) {
          setCarDetail(res?.data?.data);
          setLoader(false);
          if (res?.data?.data || res?.data?.data === null) {
            setLoader(false);
            // setVehicleNotExist(true);
          }
        } else {
          setLoader(false);
          setVehicleNotExist(true);
        }
      })
      .catch((err) => {
        if (err?.success !== false) {
          console.log(err);
        } else {
          setLoader(false);
          setVehicleNotExist(true);
        }
      });
  };

  const vehicleDetails = () => {
    if (step) {
      getVehicleDetail(idVehicle,step,st).then((res) => {
        let data = res?.data?.data;
        st && setToken(st);
        getCarDetail(data?.szVehicleNumber);
        localStorage.setItem("idVehicleDetails", idVehicle);
        localStorage.setItem("objVehicleDetails", JSON.stringify(data));
      });
    } else {
        if (idVehicle) {
            getVehicleDetail(idVehicle).then((res) => {
                let data = res?.data?.data;
                getCarDetail(data?.szVehicleNumber);
                localStorage.setItem("idVehicleDetails", idVehicle);
                localStorage.setItem("objVehicleDetails", JSON.stringify(data));
            });
        }
    }
  };
  useEffect(() => {
      vehicleDetails();
  }, []);

  // vehicle No invalid
  const handleInvalidVehicle = () => {
    setVehicleNotExist(false);
    history.push(Path.home);
  };

  const carColor = carDetail?.colour;
  const manufacturer = carDetail?.make;
  // const DvlaModel = carDetail?.wheelplan;
  const fuel = carDetail?.fuelType;
  const modalYear = carDetail?.yearOfManufacture;
  const bodyStyle = carDetail?.wheelplan;
  const engineDescription = carDetail?.registrationNumber;
  return (
    <>
      {loader && <LoaderWrapper />}
      <div className="form-steps">
      <CommonBanner CarLogo={CarLogo} carDetail={carDetail}>
          <div className="icon">
              {carDetail?.make &&
                <img src={carGoURL + 'logos/' + carDetail?.make?.toLowerCase().replace(" ", '-') + '-logo.png'} alt="car logo " />
              }
          </div>
          <div className="car-detail" style={{paddingTop: '15px', paddingBottom: '2px'}}>
            <h2 className="car-model">{engineDescription}</h2>
            <div className="cg-row">
            <h1 className="car-name">
              {manufacturer}
              {/* {DvlaModel} */}
            </h1>
            <div className="carmodel">
            <span>{carDetail?.model}</span>
            </div>
            </div>
            <div className="detail">
              <span className="info">{modalYear}</span>
              <span className="info">{carColor}</span>
              {/* <span className="info">{bodyStyle}</span> */}
              <span className="info">{fuel}</span>
              {/* <span className="info">Change car</span> */}
            </div>
            {/* {carDetail?.guideprice?.getjsondata?
            <div className="car-valuation-price">
              <div className="columns">
                <ul className="price">
                  <li className="price-title">Trade Average</li>
                  <li className="grey">£{carDetail?.guideprice?.getjsondata?.TradeAverage}</li>
                </ul>
              </div>
            </div>
            :''} */}
          </div>
        </CommonBanner>
        <div className="form-steps-inner">
          <StepForms />
        </div>
      </div>

      {/* invalid vehicle */}
      <Popup
        onClick={handleInvalidVehicle}
        state={vehicleNotExist}
        heading="Invalid Vehicle"
        btnText="Filter"
      >
        <h3>Vehicle invalid, please enter a valid Vehicle</h3>
        <button className="btn primary" onClick={handleInvalidVehicle}>
          Ok
        </button>
      </Popup>
    </>
  );
};

export default CarDetail;
