import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation } from "react-router-dom";
import { updateVehicleDetails } from "../../../../Services/updateVehicleDetails";
import { ReactComponent as PlusIcon } from "./../../../../Assets/Icons/plusicon.svg";
import UploadShowImage from "./UploadShowImage";

function DropZoneArea({ title1, title2, multiple, urls, name,isMobile,openCamera }) {
  const location = useLocation();
  const search = location.search;
  const idVehicleStored = new URLSearchParams(search).get("id") || localStorage.getItem("idVehicleDetails");

  const [files, setFiles] = useState(multiple ? [] : null);
  const [fileNames, setFileNames] = useState(multiple ? [] : null);
  const [shouldUpdateDb, setShouldUpdateDb] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (multiple) {
        setFiles((pre) => [...pre, file]);
      } else {
        setFiles(file);
      }
    });
  }, []);

  useEffect(() => {
    if (multiple && urls) {
      setFiles((pre) => [...urls]);
      setFileNames(nms=>[...urls]);
    } else {
      urls && setFiles(urls);
      urls && setFileNames(urls);
    }
  }, [urls]);

  const handleUpload = ({ url, index }) => {
    if (url) {
      if(multiple){
        setFileNames(nms=>[...nms, url]);
      }else{
        setFileNames(url);
      }
      setShouldUpdateDb(Math.random());
    } else {
    }
  };

  useEffect(() => {
    if (name && shouldUpdateDb) {
      updateDb();
    }
  }, [files, shouldUpdateDb]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png,;capture=camera",
    multiple: multiple,
  });

  const calculateFileName = (indx, fileName)=>{
    if(fileName === null || fileName === undefined){
      return null;
    }    
    if(indx === undefined){      
      return `${idVehicleStored}_${name}`;
    }else{
      return `${idVehicleStored}_${name}_${indx}`;
    }
  }
  const updateDb = () => {
    const req = {};    
    req.idVehicleDetails = idVehicleStored;

    if (multiple) {
      req[name] = JSON.stringify(fileNames);
    } else {
      req[name] = fileNames;
    }

    updateFiles(req);
  };

  const updateFiles = (req) => {
    req.idVehicleDetails = +req.idVehicleDetails;
    updateVehicleDetails(req)
      .then((res) => {
        if (res.status === 200) {
          setShouldUpdateDb(false);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteFile = (index) => {
    if (!isNaN(index)) {
      let newFiles = [...files];
      newFiles.splice(index, 1);
      setFiles(() => [...newFiles]);

      let fn = [...fileNames];
      fn.splice(index, 1);
      setFileNames(() => [...fn]);      
    } else {
      setFiles(null);
      setFileNames(null);
    }
    setShouldUpdateDb(Math.random());
  };

  return (
    <>
      {title1 ? (
        <span className="imageUpload__image_input_label">{title1}</span>
      ) : null}
      {title2 ? (
        <span className="imageUpload__image_input_label">{title2}</span>
      ) : null}
      <div
        className={`photoInput-module_component__18191 photoInput-module_medium__34JbJ ${multiple ? 'multiple-photos' : ''}`}
        data-name="exterior_front_driver"
      >
        {multiple
          ? files &&
            files.map((file, index) => (
              <UploadShowImage
                newFileName={calculateFileName(index, file.name)}
                key={index}
                onDelete={deleteFile}
                index={index}
                onUpload={handleUpload}
                file={file}
              />
            ))
          : files && (
              <UploadShowImage
                newFileName={calculateFileName(undefined, files.name)}
                onDelete={deleteFile}
                onUpload={handleUpload}
                file={files}
              />
            )}
        <label>
          {isMobile?(
            <div>
              <span className="icon image_input_add" onClick={openCamera} >+</span>
           </div>
          ):(
             <div>
             <input
               {...getInputProps()}
               // placeholder={label}
            //    capture="environment"
             />
 
             <PlusIcon className="icon image_input_add" onClick={handleUpload} />
           </div>
          )}
         
        </label>
      </div>
    </>
  );
}

export default DropZoneArea;
