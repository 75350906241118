import {
  BackendApiInstance
} from "../config/Interceptor";
import Api from "./../config/Api";
export function sellerSignUp(payload) {
  return BackendApiInstance.post(`${Api.sellerSignUp}`, payload);
}


export function completeSellerSignup(payload) {
  return BackendApiInstance.post(Api.completeSellerSignUp, payload);
}
