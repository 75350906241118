import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as moment from 'moment';
import { ReactComponent as CommentIcon } from "../../Assets/Icons/comment.svg";
import LoaderWrapper from "../../Components/Common/Loader/Loader";
import Popup from "../../Components/Common/Popup/Popup";
import Path from "../../Constants/Routes";
//import CarList from "./../../Assets/Images/list-car.png";
import { s3URL } from "../../Constants/constants";
import CarList from "./../../Assets/Icons/carlist.svg";
import { acceptRejectBid } from "./../../Services/acceptRejectBids";
import "./SellerProfile.scss";
import ReactPaginate from "react-paginate";
import CurrencyFormat from "react-currency-format";

const BidListSub = ({ data, loader, bidListFun,PageClick,pageNo,countBid }) => {
  console.log("data bids ----------", data);
  const [loaderBid, setLoaderBid] = useState(false);
  const [popup, setPopup] = useState(false);
  const [message, setMessage] = useState("");

  const handleAcceptReject = (bidId, bidStatus) => {
    setLoaderBid(true);
    let payload = {
      idBid: bidId,
      szStatus: bidStatus,
    };
    acceptRejectBid(payload)
      .then((res) => {
        if (res?.status === false) {
          console.log("something went wrong");
          setLoaderBid(false);
        } else {
          console.log("data", res?.data);
          setLoaderBid(false);
          setMessage(res?.data?.message);
          bidListFun(data[0]?.idVehicle);
          handleAcceptRejectPopup();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoaderBid(false);
      });
  };

  const handleAcceptRejectPopup = () => {
    setPopup(!popup);
  };

  const handlePageClickBid = (dataNew)=>{
    dataNew.id = data[0]?.idVehicle;
    PageClick(dataNew)
  }

  return (
    <>
      {loader || loaderBid ? (
        <LoaderWrapper />
      ) : (
        <>
          <div className="bidlistsub">
            <div className="backtolist">
              {data?.szDealerName?
              <div className="filterSection">
                <select className="cg-select" id="AccountDateRangeFilter">
                  <option value="ALL">All</option>
                  <option value="TODAY">Today</option>
                  <option value="YESTERDAY">Yesterday</option>
                  <option value="LAST_7_DAYS">Last 7 days</option>
                  <option value="LAST_30_DAYS">Last 30 days</option>
                </select>
              </div>
              : ''}
              <Link className="btn primary" to={Path.currentAuctions}>
                Back to List
              </Link>
            </div>
            


            <ul>
              {data ? (
                data?.map((bid, index) => (
                  <li key={index}>
                    <div className="accountVehicleCardView">
                      <div className="vehiclesSection-lft">
                        <div className="photoArea">
                        <img
                          src={
                            bid?.szExteriorFrontDriverSide
                              ? s3URL + bid?.szExteriorFrontDriverSide
                              : CarList
                          }
                          alt="car logo"
                        />
                        </div>
                      </div>
                      <div className="vehiclesSection-rgt">
                        <div className="vrmArea">
                          <div className="vrmBadge-styles_badge">
                            <span>{bid?.szVehicleNumber}</span>
                          </div>
                        </div>
                        <div className="nameArea">
                          <span className="sellername">Bidder Name:</span>
                          <span>{bid?.szDealerName}</span>
                        </div>
                        <div className="nameArea">
                          <span className="sellername">Bid Date:</span>
                          <span>{moment(bid?.created_at).format('d/m/Y HH:mm')}</span>
                        </div>
                        <div className="viewaction-amount">
                          <div className="bidArea">
                            <span className="bidamount">Bid Amount:</span>
                            <span className="maxBid">
                             
                            <CurrencyFormat value={bid?.dBidAmount} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                   
                            </span>
                          </div>

                          {/* <div className="actionbtn">
                            {bid?.szStatus == "Won" ? (
                              <div className="bidArea">
                                <span className="bidamount">Status</span>
                                <span className="maxBid approved">
                                  Approved
                                </span>
                              </div>
                            ) : bid?.szStatus == "Reject" ? (
                              <div className="bidArea">
                                <span className="bidamount">Status</span>
                                <span className="maxBid reject">Rejected</span>
                              </div>
                            ) : (
                              <>
                                <span
                                  title="Approve"
                                  className="btn primary"
                                  onClick={() =>
                                    handleAcceptReject(bid?.id, "Won")
                                  }
                                >
                                  Approve
                                </span>
                                <span
                                  title="Reject"
                                  className="btn primary redcolor ml-auto"
                                  onClick={() =>
                                    handleAcceptReject(bid?.id, "Reject")
                                  }
                                >
                                  Reject
                                </span>
                              </>
                            )}
                            <Link
                              title="Message"
                              className="btn primary yellowcolor"
                              to={{
                                pathname: Path.customerMessage,
                                state: {
                                  dealerID: bid?.dealerId,
                                  vehicleID: bid?.idVehicle,
                                },
                              }}
                            >
                              <CommentIcon />
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </li>

                ))
              ) : (
                <>
                  <Link className="btn primary" to={Path.vehicleList}>
                    Back to List
                  </Link>
                  <h3 style={{ textAlign: "center" }}>No data found</h3>
                </>
              )}
            </ul>
                
          </div>
          {countBid>10 &&
            <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={countBid ? countBid / 10 : 0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClickBid}
              containerClassName={"pull-right pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={pageNo}
            />
            </div>
          }
          

          {/* popup */}
          <Popup
            onClick={handleAcceptRejectPopup}
            // continue={continueForm}
            state={popup}
            heading=""
            btnText="Filter"
            // continueText="Save later"
          >
            <p>{message}</p>
            <button className="btn primary" onClick={handleAcceptRejectPopup}>
              Ok
            </button>
          </Popup>
        </>
      )}
    </>
  );
};

export default BidListSub;
