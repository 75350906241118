import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import RadioCircle from "../../../Common/RadioCircle";
import TextArea from "../../../Common/TextArea/TextArea";
import { validate } from "./validate";

function Index(props) {
  const [state, setstate] = useState();
  const [bKerbedOrScuffed, setbKerbedOrScuffed] = useState(false);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="steps-form-details">
      <h3 className="heading">
        <span>Wheels & tyres</span>
      </h3>
      <div className="form-wrapper">
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">
                Have any of the alloys been kerbed or scuffed?
              </h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bKerbedOrScuffed"}
                  id="bKerbedOrScuffedYes"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                />
                <Field
                  name={"bKerbedOrScuffed"}
                  id="bKerbedOrScuffedNo"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  children="No"
                  value={0}
                />
              </div>
              {props.bKerbedOrScuffed === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szDetailsKerbedOrScuffed"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Describe the amount of kerbing and scuffing"
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">
                Are there any problems with the tyres?
              </h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bProblemsWithTyres"}
                  id="bProblemsWithTyresYes"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                />
                <Field
                  name={"bProblemsWithTyres"}
                  id="bProblemsWithTyresNo"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  children="No"
                  value={0}
                />
              </div>
              {props.bProblemsWithTyres === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szDetailsOfProblemsWithTyres"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Are there any problems with the tyres?"
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">
                Do you have the locking wheel nut?
              </h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bLockingWheelNut"}
                  id="bLockingWheelNutYes"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                />
                <Field
                  name={"bLockingWheelNut"}
                  id="bLockingWheelNutNo"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  children="No"
                  value={0}
                />
              </div>
              {props.bLockingWheelNut === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szDetailsLockingWheelNut"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Do you have the locking wheel nut?"
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-50">
            <div className="form-field step-3">
              <h3 className="field-label">Do you have the tool pack?</h3>
              <div className="d-flex radio-button-wrapper">
                <Field
                  name={"bToolPack"}
                  id="bToolPackYes"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  value={1}
                  children="Yes"
                />
                <Field
                  name={"bToolPack"}
                  id="bToolPackNo"
                  component={RadioCircle}
                  type="radio"
                  className="radio-button width-50"
                  children="No"
                  value={0}
                />
              </div>
              {props.bToolPack === "1" && (
                <div className="textarea-wrapper">
                  <Field
                    name={"szDetailsToolPack"}
                    component={TextArea}
                    className="text-area"
                    placeholder="Do you have the tool pack?"
                    onChange={() => console.log("hello")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Index = reduxForm({
  // a unique name for the form
  form: "step4",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(Index);

const selector = formValueSelector("step4");

export default connect((state) => {
  // can select values individually
  const bKerbedOrScuffed = selector(state, "bKerbedOrScuffed");
  const bProblemsWithTyres = selector(state, "bProblemsWithTyres");
  const szDetailsKerbedOrScuffed = selector(state, "szDetailsKerbedOrScuffed");
  const szDetailsOfProblemsWithTyres = selector(
    state,
    "szDetailsOfProblemsWithTyres"
  );
  const bLockingWheelNut = selector(state, "bLockingWheelNut");
  const bToolPack = selector(state, "bToolPack");
  const szDetailsLockingWheelNut = selector(state, "szDetailsLockingWheelNut");
  const szDetailsToolPack = selector(state, "szDetailsToolPack");

  return {
    bKerbedOrScuffed,
    bProblemsWithTyres,
    szDetailsKerbedOrScuffed,
    szDetailsOfProblemsWithTyres,
    bLockingWheelNut,
    bToolPack,
    szDetailsLockingWheelNut,
    szDetailsToolPack,
  };
})(Index);
