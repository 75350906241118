import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import FacebookIcon from '../../Assets/Icons/facebook.svg';
import InstagramIcon from '../../Assets/Icons/instagram.svg';
import TwitterIcon from '../../Assets/Icons/twitter.svg';
import { required, validate } from '../../Components/Auth/SellerSignup/validate';
import Button from '../../Components/Common/Button';
import CommonBanner from '../../Components/Common/CommonBanner/CommonBanner';
import Input from '../../Components/Common/Input';
import LoaderWrapper from '../../Components/Common/Loader/Loader';
import Popup from '../../Components/Common/Popup/Popup';
import SelectBox from '../../Components/Common/SelectBox/SelectBox';
import TextArea from '../../Components/Common/TextArea/TextArea';
import { contactUsOption } from '../../Constants/optionConstant';
import { contactUs } from '../../Services/contactUs';
import MailIcon from './../../Assets/Icons/mail.svg';
import MapMarker from './../../Assets/Icons/map-marker.svg';
import Phone from './../../Assets/Icons/phone.svg';
import Tooltip from 'react-simple-tooltip';
import InfoIcon from '../../Assets/Icons/info-icon.png';
import CarGoCont from '../../Assets/Images/CarGo-cont.png';
import './ContactUs.scss';
import ReactGA from 'react-ga';

let ContactUs = (props) => {
    let { slug } = useParams();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [contactUsPopup, setContactUsPopup] = useState(false);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
        });
        ReactGA.initialize('G-0XKQYZ8KWX');
        ReactGA.pageview(window.location.pathname);
    }, []);

    // @seller signup
    const contactUsFun = (values) => {
        validate(values);
        let payload = props.formValues;
        setLoader(true);
        contactUs(payload)
            .then((res) => {
                props.dispatch(reset('contactUs'));
                setLoader(false);
                setContactUsPopup(true);
                window.gtag('event', 'conversion', { send_to: 'AW-318878216/fFphCO7O4-oCEIjkhpgB' });
            })
            .catch((err) => {
                setLoader(false);
            });
    };

    const { handleSubmit, reset } = props;

    const setContactUsPopupFun = () => {
        setContactUsPopup(false);
    };

    return (
        <div className="contact-us">
            {loader && <LoaderWrapper />}
            {/* <CommonBanner>
        <div className="car-detail pl-0 d-flex align-items-center flex-column width-100">
          <h1 className="car-name">Contact Us</h1>
        </div>
      </CommonBanner> */}
            <div className="contactmain custom-scrollbar">
                <div className="container-contact">
                    <div className="contact-us-inner">
                        <div className="contact-title">
                            <h2 className="heading">Got a Question?</h2>
                            <p>Whether you have questions or you would just like to say hello, contact us.</p>
                        </div>
                        <div className="contactwrapper">
                            <div className="contact-lft">
                                <form onSubmit={handleSubmit(contactUsFun)} className="contact-field">
                                    <div className="form-field">
                                        <div className="label">
                                            <label>Full name</label>
                                            <Tooltip content="Full name">
                                                <img src={InfoIcon} className="field-info-icon" />
                                            </Tooltip>
                                        </div>
                                        <div className="input-control">
                                            <Field
                                                name="szFullName"
                                                type="text"
                                                component={Input}
                                                placeholder="Your full name"
                                                className="width-100"
                                                validate={[required]}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-field">
                                        <div className="label">
                                            <label>Email</label>
                                            <Tooltip content="Email">
                                                <img src={InfoIcon} className="field-info-icon" />
                                            </Tooltip>
                                        </div>
                                        <div className="input-control">
                                            <Field
                                                name="szEmail"
                                                type="text"
                                                component={Input}
                                                placeholder="Email"
                                                className="width-100"
                                                validate={[required]}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-field">
                                        <div className="input-control">
                                            <div className="label">
                                                <label>Phone number</label>
                                                <Tooltip content="Phone number">
                                                    <img src={InfoIcon} className="field-info-icon" />
                                                </Tooltip>
                                            </div>
                                            <div className="input-control">
                                                <Field
                                                    name="szPhoneNumber"
                                                    type="text"
                                                    component={Input}
                                                    placeholder="e.g. 07777 111222"
                                                    className="width-100"
                                                    validate={[required]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-field">
                                        <div className="label">
                                            <label>Organisation name</label>
                                            <Tooltip content="Organisation name">
                                                <img src={InfoIcon} className="field-info-icon" />
                                            </Tooltip>
                                        </div>
                                        <div className="input-control">
                                            <Field name="szOrganization" type="text" component={Input} placeholder="Organisation Name" className="width-100" />
                                        </div>
                                    </div>
                                    <div className="form-field">
                                        <div className="label">
                                            <label>Query</label>
                                            <Tooltip content="Organisation name">
                                                <img src={InfoIcon} className="field-info-icon" />
                                            </Tooltip>
                                        </div>
                                        <div className="input-control">
                                            <Field name="szType" type="number" component={SelectBox} className="width-100" options={contactUsOption} />
                                        </div>
                                    </div>
                                    <div className="form-field">
                                        <div className="label">
                                            <label>Message</label>
                                            <Tooltip content="Organisation name">
                                                <img src={InfoIcon} className="field-info-icon" />
                                            </Tooltip>
                                        </div>
                                        <div className="mass-box">
                                            <Field name="szDescription" type="number" component={TextArea} className="width-100" validate={[required]} />
                                        </div>
                                    </div>
                                    <div className="submit-mass">
                                        <Button className="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </form>
                            </div>
                            {/* invalid vehicle */}
                            <Popup onClick={setContactUsPopupFun} state={contactUsPopup} heading="Contact Us" btnText="Filter">
                                <h3>Thank you for getting in touch!</h3>
                                <button className="btn primary" onClick={setContactUsPopupFun}>
                                    Ok
                                </button>
                            </Popup>
                            <div className="contact-rgt">
                                <div className="cont-logo">
                                    <img src={CarGoCont} alt="logo" />
                                </div>
                                <div className="contact-info">
                                    <ul>
                                        <li>
                                            <div className="contact-group email">
                                                <img src={MailIcon} alt="email" />
                                                <a href="mailto:sell@letthecargo.co.uk">sell@letthecargo.co.uk</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-group phone">
                                                <img src={Phone} alt="phone" />
                                                <a href="tel:0113 518 6900">0113 518 6900</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-group add">
                                                <img src={MapMarker} alt="address" />
                                                <p>
                                                    Low Hall Business Park, <br></br>Horsforth, Leeds, LS18 4EG
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="social-media">
                  <ul>
                    <li>
                      <a title="Facebook" href="">
                        <img src={FacebookIcon} alt="Facebook" />
                      </a>
                    </li>
                    <li>
                      <a title="Twitter" href="">
                        <img src={TwitterIcon} alt="Twitter" />
                      </a>
                    </li>
                    <li>
                      <a title="Instagram" href="">
                        <img src={InstagramIcon} alt="Instagram" />
                      </a>
                    </li>
                  </ul>
                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ContactUs = reduxForm({
    // a unique name for the form
    form: 'contactUs',
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate
})(ContactUs);

export default connect((state) => {
    const formValues = state?.form['contactUs']?.values;
    return {
        formValues,
        state
    };
    // return state;
})(ContactUs);
