import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Route, Switch, useLocation } from "react-router-dom";
import UploadImagesMobile from "./Components/shared/forms/UploadImages/UploadImagesMobile";
import Path from "./Constants/Routes";
import Layout from "./Layout";
import Routes from "./Routes";

const App = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
    <Route path={`${Path.xqzewxghxd}/:szToken/:szVehicleKey`} component={UploadImagesMobile} />
    <Layout>
      <Routes />
    </Layout>
    </Switch>
    
  );
};

export default App;
